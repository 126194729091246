import { Button, Card, Checkbox, Col, Divider, Form, Image, Row, Space, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { MyInput, MySelect } from '../../../Forms'
const {Title} = Typography






const SettingsDetails = () => {
  const [ loading, setLoading ] = useState(false)
  const [form] = Form.useForm()

  return (
        <div>
            <div className='mb-3'>
                <Title level={5} className='m-0'>User</Title>
            </div>
            <Card className='shadow-d radius-12 card-cs py-2 px-2'>
              <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
              >
                  <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MySelect
                              label= {<Space>
                                    Time Zone
                                    <Tooltip title={`The selected time zone will be used to display timestamps in the system.`}>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                </Space>
                            }
                              name="timeZone" 
                              size={'large'}
                              required
                              message='Please choose time zone'
                              value={form.getFieldValue("timeZone") || ''}
                              options={[
                                  {
                                      key: 1,
                                      name: 'Asia/Saudi Arabia (GMT+03:00)'
                                  },
                              ]}
                          />
                      </Col>
                    <Col span={24}>
                        <Checkbox>
                            <Space>
                                Tax Inclusive Pricing
                                <Tooltip title={`Check if the prices of menu items and charges include the tax amount already. If you leave it unchecked, we'll add tax on top of the prices.`}>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>
                        </Checkbox>   
                    </Col>
                    <Col span={24}>
                        <Checkbox>
                            <Space>
                            Enable Localization
                                <Tooltip title='When this option is checked you will be able to add localized Names/Description for applicable entities.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>
                        </Checkbox>   
                    </Col>
                      <Divider />
                      <Space style={{justifyContent:"end"}} className="w-100">
                      <Button
                          onClick={()=>{}}
                          className='btncancel'
                      >
                          Reset
                      </Button>
                      <Button 
                          block
                          type="primary"
                          className='btnsave text-white brand-bg'
                          loading={loading}
                          onClick={()=>{
                              // DeleteProductApi();  
                          }}
                          
                      >
                          Save
                      </Button>
                  </Space>
                  </Row>
              </Form>

              </Card>
    </div>
  )
}

export {SettingsDetails}



  
    



    
