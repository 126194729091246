import React, { useState } from 'react';
import { Card, Form, Flex, Space, Typography, Button} from 'antd';
import ReactApexChart from 'react-apexcharts';
import { ModuleTopHeading } from '../../PageComponents';

const { Text } = Typography

const TopProductQuantityPieChart = () => {
  const [form] = Form.useForm();
  

  const chartData = {
    series: [20, 45, 20, 15],
    options: {
      chart: {
        type: 'pie', 
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      labels: ['Mint Margareta', 'Zinger Burger', 'Pink Tea', 'Moroccan Salad'],
      colors: ['#5830C5' , '#002C66', '#FB923C', '#FFD60A'],
      legend: {
        show: false,
      },
    },
  };
  

  const data = [
    {
        key:'blue',
        date:'Mint Margareta',
        amount:'SAR 10,000',
    },
    {
      key:'indego',
      date:'Moroccan Salad',
      amount:'SAR 5000',
    },
    {
        key:'yellow',
        date:'Zinger Burger',
        amount:'SAR 9000',
    },
    {
        key:'orange',
        date:'Pink Tea',
        amount:'SAR 7000',
    },
    
  ]

  return (
    <div>
      <Card className='shadow-d radius-12 card-cs'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <ModuleTopHeading name='Top Products by Quantity' />
          <Form form={form} layout="vertical">
            <Flex justify='end' gap={10}>
              <Button className='btncancel fs-13 pad-filter'>
                View Report    
              </Button>
            </Flex>
          </Form>
        </Flex>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="pie"
            width={'100%'}
            height={300}
          />
          {
            data?.map((data,index)=>
                <Flex justify='space-between' align='center' className='mb-1' key={index}>
                <Space>
                    <div className=
                      {`lagend-class 
                        ${
                          data?.key === 'blue' ? 'bg-voilet' : 
                          data?.key === 'yellow' ? 'bg-yellow' :
                          data?.key === 'orange' ? 'bg-orange' :
                          data?.key === 'indego' ? 'bg-blue-dark' : ''
                        }`
                      }></div>
                    <Text className='fs-13'>{data?.date}</Text>
                </Space>
                <Text className='fs-13'>{data?.amount}</Text>
                </Flex>
            )
        }
      </Card>
    </div>
  );
};

export { TopProductQuantityPieChart };
