import { Col, Row } from 'antd'
import React from 'react'
import { GeneralInfoTable, InvoicesTable, LicenseOverviewTable } from './LicenseInvoicesTable'

const AccountLicensesInvoices = () => {
  return (
    <Row gutter={[24, 24]} align={'middle'}>
            <Col span={24}>
              <GeneralInfoTable />
            </Col>
            <Col span={24}>
              <LicenseOverviewTable />
            </Col>
            <Col span={24}>
              <InvoicesTable />
            </Col>
        </Row>
  )
}

export {AccountLicensesInvoices}