import React, { useState } from 'react'
import { Card, Table, Typography } from 'antd'

const { Title } = Typography
const AssignDeviceTable = () => {
    const columns = [
        {
            title: 'Device Name',
            dataIndex: 'devicename',
            width: 200,
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
            width: 200,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 200,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 200,
        },
    ];
    const data = [
        {
            key: '1',
            devicename: 'Branch 1',
            reference:'B01',
            type:'Cashier',
            status: <Typography.Text className='text-inactive bg-light-red fs-12 badge-cs'>
                        Used
                    </Typography.Text>
        },
    ];

    return (
        <div>
            <div className='mb-3'>
                <Title level={5} className='m-0'>Assigned Devices</Title>
            </div>
            <Card className='shadow-d radius-12 card-cs'>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    showSorterTooltip={false}
                    rowHoverable={true}
                    pagination={false}
                />
            </Card>
        </div>
    )
}

export { AssignDeviceTable }
