import { CloseOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Flex, Form, Image, Modal, Row, Space, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { MyInput, MySelect } from '../../../Forms'

const {Text} = Typography
const CopyDeviceSettings = ({visible , onClose})=>{
  const [ loading, setLoading ] = useState(false)
  const [form] = Form.useForm()
  return (
    <div>
        <Modal 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
              <Space style={{justifyContent:"center"}} className="w-100">
              <Button
                  onClick={onClose}
                  className='btncancel'
              >
                  Cancel
              </Button>
              <Button  
                  block
                  type="primary"
                  className='btnsave text-white brand-bg'
                  loading={loading}
                  onClick={()=>{
                      // DeleteProductApi();  
                  }}
                  
              >
                  Apply
              </Button>
          </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{"Copy Device Settings"}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label="Devices to copy Settings to"
                              
                            name="copyDevice" 
                            size={'large'}
                            mode='multiple'
                            required
                            message='Please choose Default Order'
                            value={form.getFieldValue("copyDevice") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Cashier 1'
                                },
                                {
                                    key: 2,
                                    name: 'Cashier 2'
                                },
                                {
                                  key: 3,
                                  name: 'Display 1'
                                },
                            ]}
                        />
                    </Col> 
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {CopyDeviceSettings}