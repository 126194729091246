import React, { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Col, Dropdown, Flex, Form, Image, Row, Space, Table, Tooltip, Typography } from 'antd'
import { useNavigate } from 'react-router-dom';
import { salereportcategorydata } from '../../../../Data';
import CategoryReportGraph from './CategoryReportGraph';


const { Text } = Typography
const SalesReportByCategoryTable = ({order ,setCategoryFilterModal})=> {
  const [form] = Form.useForm();
  const [checkedList, setCheckedList] = useState([]);
  const [hideGraph, setHideGraph] = useState(true);
  
  const options = [
      'Category Name',
      'Category Reference', 
      'Gross Sales', 
      'Gross Sales %', 
      'Net Sales With Tax', 
      'Taxes', 
      'Gross Sales Without Tax', 
      'Net Sales', 
      'Net Sales %',
      'Order Count',
      'Average Order',
      'Net Quantity',
      'Cost',
      'Returned Amount',
      'Returned Quantity',
      'Profit',
  ];
  const [checkAll, setCheckAll] = useState(false);
  
  const onChange = (item, checked) => {
    const updatedList = checked
      ? [...checkedList, item]
      : checkedList.filter((value) => value !== item);
  
    setCheckedList(updatedList);
    setCheckAll(updatedList.length === options.length);
  };
  
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options : []);
    setCheckAll(e.target.checked);
  };
  const indeterminate = checkedList.length > 0 && checkedList.length < options.length;
  console.log(order)
  const columns = [
      { title: 'Category', dataIndex: 'categoryName' },
      { title: 'Category Reference', dataIndex: 'categoryreference'},

      { title: 'Branch Name', dataIndex: 'branchname', hidden: order != 'branch' },
      { title: 'Branch Reference', dataIndex: 'branchreference', hidden: order != 'branch' },
      { title: 'Order Type', dataIndex: 'orderType', hidden: order != 'orderType' },
      { title: 'Hours of Day', dataIndex: 'hoursofday', hidden: order != 'hourOfDay' },
      { title: 'Cashier', dataIndex: 'cashier', hidden: order != 'cashier' },
      { title: 'Day', dataIndex: 'day', hidden: order != 'day' },
      { title: 'Week', dataIndex: 'week', hidden: order != 'week' },
      { title: 'Month', dataIndex: 'month', hidden: order != 'month' },
      { title: 'Quarter', dataIndex: 'quarter', hidden: order != 'quarter' },
      { title: 'Year', dataIndex: 'year', hidden: order != 'year' },
      // { title: 'Modifier Options', dataIndex: 'modifierOption', hidden: order != 3 },
      // { title: 'Product Name', dataIndex: 'product', hidden: order != 5 },
      // { title: 'Product SKU', dataIndex: 'productsku', hidden: order != 5 },
      {
        title: <Space>
            <Text className='fw-500'>Gross Sales</Text>
            <Tooltip title='Products Price + Tax - Returned Products - Returned Tax'>
                <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
            </Tooltip>
        </Space>,
        dataIndex: 'grosssale',
        key:"grosssale",
        render: (grosssale) => <Text>SAR {grosssale}</Text>
    },
    {
        title: 'Gross Sales %',
        dataIndex: 'grossSaleper',
    },
    {
        title: <Space>
                <Text className='fw-500'>Net Sales With Tax</Text>
                <Tooltip title='Net sales + Tax'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
        dataIndex: 'netsaleWithTax',
        width:200,
        render: (netsaleWithTax) => <Text>SAR {netsaleWithTax}</Text>
    },
    {
        title: <Space>
                <Text className='fw-500'>Taxes</Text>
                <Tooltip title='Tax - returned tax'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
        dataIndex: 'taxes',
        render: (taxes) => <Text>SAR {taxes}</Text>,
    },
    {
        title: <Space>
                <Text className='fw-500'>Gross Sales Without Taxes</Text>
                <Tooltip title='Gross Sales - Taxes'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
        dataIndex: 'grossNoTax',
        render: (grossNoTax) => <Text>SAR {grossNoTax}</Text>,
        width: 250
    },
    {
        title: <Space>
                <Text className='fw-500'>Net Sales</Text>
                <Tooltip title='Gross sales - tax'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
        dataIndex: 'netsale',
        render: (netsale) => <Text>SAR {netsale}</Text>
    },
    {
        title: 'Net Sales %',
        dataIndex: 'netsaleper',
    },
    // {
    //     title: <Space>
    //             <Text className='fw-500'>Order Count</Text>
    //             <Tooltip title='Number of done orders'>
    //                 <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
    //             </Tooltip>
    //         </Space>,
    //     dataIndex: 'ordercount',
    //     hidden: order == "category" || order == "modifierOption" || order == "product",
    // },
    // {
    //     title: <Space>
    //             <Text className='fw-500'>Average Order</Text>
    //             <Tooltip title='Net Sales/ Order Count'>
    //                 <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
    //             </Tooltip>
    //         </Space>,
    //     dataIndex: 'averageorder',
    //     hidden: order == "category" || order == "modifierOption" || order == "product",
    //     render: (averageorder) => <Text>SAR {averageorder}</Text>,
    // },
    {
        title: <Space>
                <Text className='fw-500'>Net Quantity</Text>
                <Tooltip title='Total of done quantities - total of returned quantities'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
        dataIndex: 'netquantity',
    },
    {
        title: 'Cost',
        dataIndex: 'cost',
        render: (cost) => <Text>SAR {cost}</Text>
    },
    {
        title: <Space>
                <Text className='fw-500'>Returned Amount</Text>
                <Tooltip title='Returned products + Returned Taxes'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
        dataIndex: 'returnAmount',
        render: (returnAmount) => <Text>SAR {returnAmount}</Text>
    },
    {
        title: <Space>
                <Text className='fw-500'>Returned Quantity</Text>
                <Tooltip title='Returned quantities of returned products'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
        dataIndex: 'returnQuantity',
    },
    {
        title: <Space>
                <Text className='fw-500'>Profit</Text>
                <Tooltip title='Net Sales - Price'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
        dataIndex: 'profit',
        render: (profit) => <Text>SAR {profit}</Text>
    },         
  ].filter(column => !column.hidden)
  
  
  const dropdownContent = (
      <Card className='text-black radius-8 shadow-c card-cs'>
          <div>
              <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate}>Unselect All </Checkbox>
          </div>
          <Space direction='vertical' size={10} className='pt-3'>
              <Checkbox checked disabled>Category Name</Checkbox>
              <Checkbox
                  checked={checkedList.includes('Category Reference')}
                  onChange={(e) => onChange('Category Reference', e.target.checked)}
                  >
                  Category Reference
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Gross Sales')}
                  onChange={(e) => onChange('Gross Sales', e.target.checked)}
                  >
                  Gross Sales
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Gross Sales %')}
                  onChange={(e) => onChange('Gross Sales %', e.target.checked)}
                  >
                  Gross Sales %
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Net Sales With Tax')}
                  onChange={(e) => onChange('Net Sales With Tax', e.target.checked)}
                  >
                  Net Sales With Tax
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Taxes')}
                  onChange={(e) => onChange('Taxes', e.target.checked)}
                  >
                  Taxes
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Gross Sales Without Tax')}
                  onChange={(e) => onChange('Gross Sales Without Tax', e.target.checked)}
                  >
                  Gross Sales Without Tax
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Net Sales')}
                  onChange={(e) => onChange('Net Sales', e.target.checked)}
                  >
                  Net Sales
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Net Sales %')}
                  onChange={(e) => onChange('Net Sales %', e.target.checked)}
                  >
                  Net Sales %
              </Checkbox>
              {/* <Checkbox
                  checked={checkedList.includes('Order Count')}
                  onChange={(e) => onChange('Order Count', e.target.checked)}
                  >
                  Order Count
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Average Order')}
                  onChange={(e) => onChange('Average Order', e.target.checked)}
                  >
                  Average Order
              </Checkbox> */}
              <Checkbox
                  checked={checkedList.includes('Net Quantity')}
                  onChange={(e) => onChange('Net Quantity', e.target.checked)}
                  >
                  Net Quantity
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Cost')}
                  onChange={(e) => onChange('Cost', e.target.checked)}
                  >
                  Cost
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Returned Amount')}
                  onChange={(e) => onChange('Returned Amount', e.target.checked)}
                  >
                  Returned Amount
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Returned Quantity')}
                  onChange={(e) => onChange('Returned Quantity', e.target.checked)}
                  >
                  Returned Quantity
              </Checkbox>
              <Checkbox
                  checked={checkedList.includes('Profit')}
                  onChange={(e) => onChange('Profit', e.target.checked)}
                  >
                  Profit
              </Checkbox>
          </Space>
      </Card>
  );
  return (
    <div>
        {order == 'none' && (<Row gutter={[24,24]} className='mb-3'>
                          <Col span={24}>
                            <Flex justify='end' className='mb-3'>
                                <Button className='btnsave px-4 brand-bg text-white py-2 fs-14'
                                        icon={<img src='/assets/icons/graph-ic.png' width={18}/>}
                                        onClick={()=> setHideGraph(prev => !prev) }
                                        >
                                                {hideGraph ? "Hide Graph" : "Show Graph"}  
                                    </Button>
                            </Flex>
                          </Col>
                          {hideGraph && <Col span={24}>
                                            <CategoryReportGraph />
                                        </Col>}
                            </Row>)}
            <Card className='shadow-d radius-12 card-cs'>
              <Row gutter={[24,24]}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16} align={'middle'}>
                            <Col span={24}>
                                <Space style={{justifyContent:"end"}} className='w-100'>
                                    <Button className='btncancel pad-filter' onClick={()=>setCategoryFilterModal(true)}>
                                        <Space>
                                            <Image src='/assets/icons/filter-outline.png' preview={false} width={20} />
                                            <Text className='text-black'>Filters</Text>
                                        </Space>
                                    </Button>
                                    <Tooltip title="Column">
                                        <Dropdown
                                            overlay={dropdownContent}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter brand-bg border0'>
                                                <Image src='/assets/icons/plus-cr.png' preview={false} width={20} />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={salereportcategorydata} 
                        scroll={{x: 2400}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                        summary={(pageData) => {
                            let totalsale = 0;
                            let totalNetSaleWithTax = 0;
                            let totaltaxes = 0;
                            let totalgrossnotax = 0;
                            let totalnetsale = 0;
                            let totalnetquantity = 0;
                            let totalcost = 0;
                            let totalreturnamount = 0;
                            let totalreturnquantity = 0;
                            let totalprofit = 0;
                    
                            pageData.forEach(({ 
                                grosssale, netsaleWithTax, taxes, grossNoTax, 
                                netsale, netquantity, cost, returnAmount, returnQuantity, profit
                            }) => {
                              totalsale += grosssale;
                              totalNetSaleWithTax += netsaleWithTax;
                              totaltaxes += taxes;
                              totalgrossnotax += grossNoTax;
                              totalnetsale += netsale;
                              totalnetquantity += netquantity;
                              totalcost += cost;
                              totalreturnamount += returnAmount;
                              totalreturnquantity += +returnQuantity;
                              totalprofit += profit;
                            });
                            
                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell><Text className='fw-500'>Total</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    {order == "branch" && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == "branch" && <Table.Summary.Cell></Table.Summary.Cell>}
                             
                                    {order == "cashier" && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == "orderType" && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == "hourOfDay" && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == "day" && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == "week" && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == "month" && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == "quarter" && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == "year" && <Table.Summary.Cell></Table.Summary.Cell>}
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalsale}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalNetSaleWithTax}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totaltaxes}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalgrossnotax}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalnetsale}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>{totalnetquantity}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalcost}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalreturnamount}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>{totalreturnquantity}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalprofit}</Text></Table.Summary.Cell>
                                </Table.Summary.Row>
                            );
                          }}
                    />
                </Col>

              </Row>


            </Card>
       

        </div>
  )
}

export {SalesReportByCategoryTable}








   
