import React, { useState } from 'react'
import { Button, Card, Checkbox, Col, Dropdown, Form, Image, Row, Space, Table, Tooltip, Typography } from 'antd'
import { menuengineeringData } from '../../../../Data';

const { Text } = Typography
const MenuEngineeringTable = ({setFilterModal}) => {
    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState([]);

    const options = [
        'Sales', 
        'Quantity', 
        'Total Cost', 
        'Item Profit', 
        'Total Profit',  
        'Profit %',
        'Popularity',
        'Profit Category',
        'Popularity Category',
        'Class',
    ];
    const [checkAll, setCheckAll] = useState(false);
  
    const onChange = (item, checked) => {
      const updatedList = checked
        ? [...checkedList, item]
        : checkedList.filter((value) => value !== item);
  
      setCheckedList(updatedList);
      setCheckAll(updatedList.length === options.length);
    };
  
    const onCheckAllChange = (e) => {
      setCheckedList(e.target.checked ? options : []);
      setCheckAll(e.target.checked);
    };
    const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

    const columns = [
        {
            title: 'Product',
            dataIndex: 'product',
        },
        {
            title: <Space>
                <Text className='fw-500'>Sales</Text>
                <Tooltip title='Sum of product price - taxes - returns + discounts.'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'sales',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Quantity</Text>
                    <Tooltip title='Number of the sold products without the returns.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'quantity',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Total Cost</Text>
                    <Tooltip title='Cost of product * quantity.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'totalcost',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Item Profit</Text>
                    <Tooltip title='Product sales - cost.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'itemprofit',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Total Profit</Text>
                    <Tooltip title='Product profit * quantity.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'totalprofit',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Profit %</Text>
                    <Tooltip title='Product profit percentage from total profit.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'profitpercentage',
        }, 
        {
            title: <Space>
                    <Text className='fw-500'>Popularity</Text>
                    <Tooltip title='Quantity of the product / Sum of all products in this period.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'popularity',
        }, 
        {
            title: <Space>
                    <Text className='fw-500'>Profit Category</Text>
                    <Tooltip title='Low or High profitability.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'profitcategory',
        },      
        {
            title: <Space>
                    <Text className='fw-500'>Popularity Category</Text>
                    <Tooltip title='Low or High popularity.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'popularitycategory',
        },  
        {
            title: <Space>
                    <Text className='fw-500'>Class</Text>
                    <Tooltip title='A global standard of popularity and profitability. Star: High profit and High popularity. Workhorse: Low profit and High popularity. Challenge: High profit and Low popularity. Dog: Low profit and Low popularity.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'class',
        },    
    ]
 

    const dropdownContent = (
        <Card className='text-black radius-8 shadow-c card-cs overflow-on'>
            <div>
                <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate}>Select All </Checkbox>
            </div>
            <Space direction='vertical' size={10} className='pt-3'>
                <Checkbox checked disabled>Product</Checkbox>
                <Checkbox
                    checked={checkedList.includes('Sales')}
                    onChange={(e) => onChange('Sales', e.target.checked)}
                    >
                    Sales
                </Checkbox>       
                <Checkbox
                    checked={checkedList.includes('Quantity')}
                    onChange={(e) => onChange('Quantity', e.target.checked)}
                    >
                    Quantity
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Total Cost')}
                    onChange={(e) => onChange('Total Cost', e.target.checked)}
                    >
                    Total Cost
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Item Profit')}
                    onChange={(e) => onChange('Item Profit', e.target.checked)}
                    >
                    Item Profit
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Total Profit')}
                    onChange={(e) => onChange('Total Profit', e.target.checked)}
                    >
                    Total Profit
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Profit %')}
                    onChange={(e) => onChange('Profit %', e.target.checked)}
                    >
                    Profit %
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Popularity')}
                    onChange={(e) => onChange('Popularity', e.target.checked)}
                    >
                    Popularity
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Profit Category')}
                    onChange={(e) => onChange('Profit Category', e.target.checked)}
                    >
                    Profit Category
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Popularity Category')}
                    onChange={(e) => onChange('Popularity Category', e.target.checked)}
                    >
                    Popularity Category
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Class')}
                    onChange={(e) => onChange('Class', e.target.checked)}
                    >
                    Class
                </Checkbox>
            </Space>
        </Card>
    );

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16} align={'middle'}>
                            <Col span={24}>
                                <Space style={{justifyContent:"end"}} className='w-100'>
                                    <Button className='btncancel pad-filter' onClick={()=>setFilterModal(true)}>
                                        <Space>
                                            <Image src='/assets/icons/filter-outline.png' preview={false} width={20} />
                                            <Text className='text-black'>Filters</Text>
                                        </Space>
                                    </Button>
                                    <Tooltip title="Column">
                                        <Dropdown
                                            overlay={dropdownContent}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter brand-bg border0'>
                                                <Image src='/assets/icons/plus-cr.png' preview={false} width={20} />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={menuengineeringData} 
                        scroll={{x: 1500}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export { MenuEngineeringTable }
