import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import './index.css';
import { Layout, Menu, Image, Space, Form, Divider, Button } from 'antd';
import { CategoryPage, Dashboard, Login, ModifierOptionPage, ModifierPage, OrderPage, ProductsPage, UserPage, BranchPage, TaxPage, PaymentMethodPage, RolePermissionPage, DevicesPage, SalesReport, ReceiptPage, AccountPage, PaymentReport, TaxReportPage, ShiftReportPage, BusinessDayReportPage, TillsReportPage, MenuEngineeringReportPage, BranchTrendReportPage, ForgotPassword, FreeTrailForm } from '../pages';
import { Notifications, UserDropdown } from '../components/Header';
import { ModifierSorter, SearchInput, SingleViewBranch, SingleViewDevice, SortCategory, SortProduct, ViewOrders, ViewProducts, ViewSingleModifierOption, ViewSingleUser } from '../components';


const { Header, Sider, Content } = Layout;
const RouteF = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState('1');
  const [openKeys, setOpenKeys] = useState(['']);
  const [showbread, setShowBread] = useState(false);
  const [ contentmodifier, setContentModifier ] = useState(null)
  const [ deletedata, setDeleteData] = useState('hello')
  

  function getItem(label, key, icon, children) {
    return { key, icon, children, label };
  }

  useEffect(() => {
    let tab = location?.pathname?.split("/")[1];
    tab = tab === '' ? '1' :
          tab === 'order' ? '2' :
          tab === 'category' ? '3' :
          tab === 'sortercategory' ? '3' :
          tab === 'products' ? '4' :
          tab === 'modifier' ? '5' :
          tab === 'modifiersorter' ? '5' :
          tab === 'modifieroption' ? '6' :
          tab === 'salereport' ? '7' :
          tab === 'paymentreport' ? '8' :
          tab === 'taxreport' ? '9' :
          tab === 'businessreport' ? '10' :
          tab === 'shiftreport' ? '11' :
          tab === 'tillsreport' ? '12' :
          tab === 'menureport' ? '13' :
          tab === 'branchreport' ? '14' :
          tab === 'branches' ? '15' :
          tab === 'users' ? '16' :
          tab === 'rolepermission' ? '17' :
          tab === 'devices' ? '18' :
          tab === 'taxgroup' ? '19' :
          tab === 'paymentmethod' ? '20' :
          tab === 'receipt' ? '21' :
      '1';
    setCurrentTab(tab);
    
  }, [location]);

  useEffect(() => {
    let itemsArr = [
      getItem('Dashboard', '1', <img src="/assets/icons/dashboard.png" width={'15px'} alt="" />),
      getItem('Order', '2', <img src="/assets/icons/order.png" width={'15px'} alt="" />),
      getItem('Menu', 'sub3', <img src="/assets/icons/menu.png" width={'15px'} alt="" />, [
        getItem('Categories', '3'),
        getItem('Products', '4'),
        getItem('Modifiers', '5'),
        getItem('Modifiers Options', '6'),
      ]),
      getItem('Analytics', 'sub4', <img src="/assets/icons/analytics.png" width={'15px'} alt="" />, [
        getItem('Sales Report', '7'),
        getItem('Payment Report', '8'),
        getItem('Tax Report', '9'),
        getItem('Business Day Report', '10'),
        getItem('Shift Report', '11'),
        getItem('Tills Report', '12'),
        getItem('Menu Engineering Report', '13'),
        getItem('Branches Trend Report', '14'),
      ]),
      getItem('Setup Page', 'sub5', <img src="/assets/icons/setup.png" width={'15px'} alt="" />, [
        getItem('Branches', '15'),
        getItem('Users', '16'),
        getItem('Roles & Permissions', '17'),
        getItem('Devices', '18'),
        getItem('Taxes & Group Tax', '19'),
        getItem('Payment Method', '20'),
        getItem('Receipt', '21'),
      ])
    ];

    setItems([...itemsArr]);
  }, []);

  // useEffect(() => {
  //   const storedOpenKeys = localStorage.getItem('openKeys');
  //   if (!currentTab && storedOpenKeys) {
  //     setOpenKeys(JSON.parse(storedOpenKeys));
  //   }
  // }, []);

  const handleMenuClick = (e) => {
    const { key } = e;
    switch (key) {
      case '1':
        navigate("/", { replace: true });
        break;
      case '2':
        navigate("/order", { replace: true });
        break;
      case '3':
        navigate("/category", { replace: true });
        break;
      case '4':
        navigate("/products", { replace: true }, setShowBread(false));
        break;
      case '5':
        navigate("/modifier", { replace: true });
        break;
      case '6':
        navigate("/modifieroption", { replace: true });
        break;
      case '7':
        navigate("/salereport", { replace: true });
        break;
      case '8':
        navigate("/paymentreport", { replace: true });
        break;
      case '9':
        navigate("/taxreport", { replace: true });
        break;
      case '10':
        navigate("/businessreport", { replace: true });
        break;
      case '11':
        navigate("/shiftreport", { replace: true });
        break;
      case '12':
        navigate("/tillsreport", { replace: true });
        break;
      case '13':
        navigate("/menureport", { replace: true });
        break;
      case '14':
        navigate("/branchreport", { replace: true });
        break;
      case '15':
        navigate("/branches", { replace: true });
        break;
      case '16':
        navigate("/users", { replace: true });
        break;
      case '17':
        navigate("/rolepermission", { replace: true });
        break;
      case '18':
        navigate("/devices", { replace: true });
        break;
      case '19':
        navigate("/taxgroup", { replace: true });
        break;
      case '20':
        navigate("/paymentmethod", { replace: true });
        break;
      case '21':
        navigate("/receipt", { replace: true });
        break;
      default:
        break;
    }
  };

 
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
    // localStorage.setItem('openKeys', JSON.stringify(keys));
  };
  return (
    <Layout style={{ height: '100vh' }}>
      <Sider breakpoint="md"
        collapsedWidth="80"
        onBreakpoint={(broken) => {
          setCollapsed(broken)
        }} trigger={null} collapsible collapsed={collapsed} className={collapsed ? 'addclass' :''} style={{ height: '100vh', overflowY: 'auto',borderRight:'1px solid #E3E3E3'}}>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            style={{ width: collapsed ? "100%" : '100px' }}
            height={'auto'}
            src="/assets/images/logo.png"
            alt='Rental Admin Panel'
            preview={false}
          />
        </div>
        <Menu
          defaultSelectedKeys={['1']}
          selectedKeys={[currentTab]}
          mode="inline"
          theme="dark"
          onClick={handleMenuClick}
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          items={items}
          className='listitem'
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background header-mbl-cs"
          style={{
            padding: 0,
            display: 'flex',
            paddingLeft:0,
            justifyContent: 'center'
          }}
        >
          <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between',alignItems:'center',position:'relative',gap:5, }}>
            <Space className='w-100 mbl-space'>
              <div className='trigger' onClick={() => setCollapsed(!collapsed)}>
                <Image src='/assets/icons/collapse.png' 
                  width={'35px'} preview={false} 
                  style={{transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)'}}  
                />
              </div>
              <Form
                form={form}
                layout="vertical"
                className='w-100'
              >
                <SearchInput 
                  name='name'
                  placeholder='Search for Products / Payment Methods / Modifiers / Categories'
                  value={form.getFieldValue('name') || ''}
                  prefix={<img src='/assets/icons/search.png' width={18} />}
                  className='border-light-gray pad-x radius-8 fs-13 search-w'
                />
              </Form>
            </Space>
            <Space size={15}>
              <Notifications />
              <Button shape='circle' size='large'>
                <Image src='/assets/icons/language.png' width={24} preview={false} />
              </Button>
              <UserDropdown />
            </Space>
          </div>
        </Header>
        
        <Content
          className="scroll-bar"
          style={{
            margin: '24px 10px',
            marginTop: '0',
            padding: '0 15px',
            minHeight: 280,
            overflowY: 'auto',
          }}
        >
          <Divider />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpass" element={<ForgotPassword />} />
            <Route path="/freetrail" element={<FreeTrailForm />} />
            <Route path="/order" element={<OrderPage />} />
            <Route path="/order/vieworder/:orderId" element={<ViewOrders />} />
            <Route path="/category" element={<CategoryPage/>} />
            <Route path='/sortercategory'element={<SortCategory/>} />
            <Route path="/products" element={<ProductsPage {...{showbread, contentmodifier}} />} />
            <Route path='/products/viewproducts/:productId' element={<ViewProducts />}/>
            <Route path="/products/sortproductmodifiers" element={<SortProduct />} />
            <Route path="/modifier" element={<ModifierPage  {...{setShowBread, setContentModifier}} />} />
            <Route path='/modifiersorter' element={<ModifierSorter />} />
            <Route path='/modifieroption' element={<ModifierOptionPage deletedata={deletedata} setDeleteData={setDeleteData} />} />
            <Route path='/modifieroption/viewmodifieroption/:modOptionId' element={<ViewSingleModifierOption/>} />
            <Route path='/view' element={<ViewSingleModifierOption deletedata={deletedata} />} />
            <Route path='/users' element={<UserPage />} />
            <Route path='/users/singleview/:userId' element={<ViewSingleUser />} />
            <Route path='/branches' element={<BranchPage />} />
            <Route path='/branches/singleview/:branchId' element={<SingleViewBranch />} />
            <Route path='/taxgroup' element={<TaxPage />} />
            <Route path='/paymentmethod' element={<PaymentMethodPage/>} />
            <Route path='/rolepermission' element={<RolePermissionPage />} />
            <Route path='/devices' element={<DevicesPage />} />
            <Route path='/devices/singleview/:deviceId' element={<SingleViewDevice />} />
            <Route path='/account' element={<AccountPage />} />
            <Route path='/receipt' element={<ReceiptPage />} />
            <Route path='/salereport' element={<SalesReport />} />
            <Route path='/paymentreport' element={<PaymentReport />} />
            <Route path='/taxreport' element={<TaxReportPage/>} />
            <Route path='/shiftreport' element={<ShiftReportPage />} />
            <Route path='/businessreport' element={<BusinessDayReportPage />} />
            <Route path='/tillsreport' element={<TillsReportPage />} />
            <Route path='/menureport' element={<MenuEngineeringReportPage />} />
            <Route path='/branchreport' element={<BranchTrendReportPage />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default RouteF;
