import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { devicesData } from '../../../../Data'
import { Breadcrumb, Button, Card, Col, Flex, Image, Row, Space, Table, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { DeactivateDevices } from '../DeactivateDevices'
import { AddDevice, CopyDeviceSettings, EditDeviceSettings, ForceSyncData } from '../DevicesModals'

const {Text, Title} = Typography

const SingleViewDevice =( ) => {

  let { deviceId } = useParams()
    const navigate = useNavigate()
    const [ visible, setvisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deviceSetting , setDeviceSetting ] = useState(false)
    const [ copySetting , setCopySetting ] = useState(false)
    const [ forceSync , setForceSync ] = useState(false)

    const device = devicesData.find((mod)=>mod.key == deviceId)    

    const columns = [
        {
            title: 'Device',
            dataIndex: 'device',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            device: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Reference</Text>
                <Text>{device?.ref}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Device Type</Text>
                <Text>{device?.type}</Text>
            </Space>,
        },
        {
            key: '2',
            device: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Branch</Text>
                <Text>{device?.branch}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Model</Text>
                <Text>iPad12,1</Text>
            </Space>,
        },
        {
            key: '3',
            device: <Space direction='vertical' size={2}>
                <Text className='text-gray'>App Version</Text>
                <Text>5.0.99 (6607)</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>System Version</Text>
                <Text>iOS 15.6</Text>
            </Space>,
        },
        {
          key: '4',
          device: <Space direction='vertical' size={2}>
              <Text className='text-gray'>Last Sync</Text>
              <Text>October 19, 11:26pm</Text>
          </Space>,
          detail: <Space direction='vertical' size={2}>
              <Text className='text-gray'>Last Online</Text>
              <Text>October 22, 05:34pm</Text>
          </Space>,
        },
        {
          key: '4',
          device: <Space direction='vertical' size={2}>
              <Text className='text-gray'>Last Order</Text>
              <Text>October 22, 05:34pm</Text>
          </Space>,
        },
    ];

  return (
    <div>            
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Breadcrumb className='mb-3'>
                        <Breadcrumb.Item>Setup Page</Breadcrumb.Item>
                        <Breadcrumb.Item className='text-black' onClick={() => navigate("/devices")}>
                            Devices
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className='fw-500'>{device?.devicename}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col  lg={{span: 6}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <Space>
                        <Button className='border0 p-0 bg-transparent' onClick={()=>navigate('/devices')}>
                            <ArrowLeftOutlined />
                        </Button>
                        <Title level={5} className='m-0'>{device.devicename}</Title>
                        <Text className='text-inactive bg-light-red fs-12 fw-500 badge-cs'>
                            7140
                        </Text>
                    </Space>
                </Col>
                <Col lg={{span: 18}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <Space className='w-100 mbl-space right' wrap>
                        <Button className='btncancel px-2 py-1 mbl-btn' onClick={()=>{setForceSync(true)}}>
                            <Flex justify='space-between' className='w-100' align='center' gap={10}>
                                <Image src='/assets/icons/sync.png' preview={false} width={17} />
                                <Text className='text-black fs-13'>Force Sync Data</Text>
                            </Flex>
                        </Button>
                        <Button className='btncancel px-2 py-1 mbl-btn' onClick={()=>{setCopySetting(true)}}>
                            <Flex justify='space-between' className='w-100' gap={10}>
                                <Image src='/assets/icons/copy.png' preview={false} width={20} />
                                <Text className='text-black fs-13'>Copy Settings</Text>
                            </Flex>
                        </Button>
                        <Button className='btncancel px-2 py-1 mbl-btn' onClick={()=>{setDeviceSetting(true)}}>
                            <Flex justify='space-between' className='w-100' gap={10}>
                                <Image src='/assets/icons/setting.png' preview={false} width={18} />
                                <Text className='text-black fs-13'>Device Settings</Text>
                            </Flex>
                        </Button>
                        <Button className='btncancel px-2 py-1 mbl-btn' onClick={()=>{setvisible(true);setEditItem('hello')}}>
                            <Flex justify='space-between' className='w-100' gap={10}>
                                <Image src='/assets/icons/edit.png' preview={false} width={17} />
                                <Text className='text-black fs-13'>Edit  device</Text>
                            </Flex>
                        </Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Card className='shadow-d radius-12 card-cs'>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            showSorterTooltip={false}
                            rowHoverable={false}
                            showHeader={false}
                            pagination={false}
                        />
                    </Card>
                </Col>
                <Col span={24}>
                    <DeactivateDevices device={device.devicename} />
                </Col>
            </Row>

            <AddDevice 
            visible={visible}
            edititem={edititem}
            onClose={()=>{setvisible(false)}}
            />

            <EditDeviceSettings
            visible={deviceSetting}
            onClose={()=>setDeviceSetting(false)}
            />

            <CopyDeviceSettings
            visible={copySetting}
            onClose={()=>setCopySetting(false)}
            />
            <ForceSyncData
            visible={forceSync}
            onClose={()=>{setForceSync(false)}}
            />
        </div>
  )
}

export {SingleViewDevice}