import React, { useState } from 'react'
import { Breadcrumb, Button, Card, Col, Flex, Image, Row, Space, Table, Typography } from 'antd'
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { productTableData } from '../../../../Data';
import { AddEditProduct } from '../ProductModals';
import { ProductModifier } from '../ProductModifier';
import { ProductInBranch } from '../ProductInBranch';

const { Text, Title } = Typography
const ViewProducts = () => {
    const [editProduct , setEditProduct] = useState(false)

    let { productId } = useParams()
    const navigate = useNavigate()

    const product = productTableData.find((pro)=>pro.id == productId)

    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];

    const data = [
        {
            key: '1',
            user: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Product Name</Text>
                <Text>{product?.name}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Localized name</Text>
                <Text>عصير مانجو</Text>
            </Space>,
        },
        {
            key: '2',
            user: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Category Name</Text>
                <Text>{product?.category}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Product Barcode</Text>
                <Text>-</Text>
            </Space>,
        },
        {
            key: '3',
            user: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Tax Group</Text>
                <Text>{product?.taxgroup || "-"}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Price</Text>
                <Text>{product?.price || "-"}</Text>
            </Space>,
        },

        {
            key: '3',
            user: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Cost</Text>
                <Text>{product?.price || "-"}</Text>
            </Space>,
        },
    ];


      return (
        <div>
            <Breadcrumb className='mb-3'>
                <Breadcrumb.Item>Menu</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/products")}>
                    Products
                </Breadcrumb.Item>
                <Breadcrumb.Item>{product.name}</Breadcrumb.Item>
            </Breadcrumb>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Flex justify='space-between' align='center' gap={10} wrap className='w-100'>
                        <Space>
                            <Button className='border0 p-0 bg-transparent' onClick={()=>navigate('/products')}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Title level={5} className='m-0'>{product.name}</Title>
                            <Text className={
                                product.status === 'Active' ? 
                                'text-active bg-light-green fs-12 badge-cs':
                                'text-inactive bg-light-red fs-12 badge-cs'
                            }>
                                {product?.status}
                            </Text>
                        </Space>
                        <Flex gap={15} wrap>
                            {product.status == 'Active' ? (
                                <Button className='btncancel py-1 px-2'>
                                    <Space align='center'>
                                        <CloseOutlined/>
                                        <Text >Inactivate Product</Text>
                                    </Space>

                                </Button>
                            ) : (
                                <Button className='btncancel py-1 px-3'>  
                                <Space>
                                    <CloseOutlined/>
                                    <Text>Activate Product</Text>
                                </Space>

                                </Button>
                            )}
                            <Button className='btncancel py-1 px-2' onClick={()=>{setEditProduct(true)}} >
                                <Space>
                                    <Image src='/assets/icons/edit.png' preview={false} width={18} />
                                    <Text className='text-gray'>Edit Product</Text>
                                </Space>
                            </Button>
                        </Flex>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Card className='radius-12 card-cs shadow-d'>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            showSorterTooltip={false}
                            rowHoverable={false}
                            showHeader={false}
                            pagination={false}
                        />
                    </Card>
                </Col>
            </Row>
                
            <AddEditProduct 
                visible={editProduct}
                onClose={()=>setEditProduct(false)}
                edititem={product} /> 
                 <ProductModifier />
            <ProductInBranch status={product?.status} />
        </div>
    )

}

export { ViewProducts }

