import React, { useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Image, Row, Space, Tabs, Typography } from 'antd'
import { Addrole, AddUserBranch, AddUsers, AlertModal, CashierAccessTable, ConsoleAccessTable, DeleteduserTable, ModuleTopHeading, UserAllTable, UserFilter, UserImportFile, UserNotification } from '../../components'
import { DownOutlined } from '@ant-design/icons'

const { Text } = Typography
const UserPage = () => {

    const [order, setOrder] = useState(1)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ addrole, setAddRole ] = useState(false)
    const [ addbranch, setAddBranch ] = useState(false)
    const [ removertext, setRemoveText ] = useState(false)   
    const [ adduser, setAddUser ] = useState(false)
    const [ edititem, setEditItem ] = useState(false)
    const [ importmodal, setImportModal ] = useState(false)
    const [ filtermodal, setFilterModal ] = useState(false)
    const [ addnotification, setAddNotification ] = useState(false)
    const [ removenotify, setRemoveNotify ] = useState(false)

    const items = [
        { label:    
            <Button className='fs-13 border0 bg-transparent' 
                onClick={()=>setImportModal(true)}>Import Modifiers</Button>, 
                key: 0 
        },
        { label: <Button className='fs-13 border0 bg-transparent'>Export Modifiers</Button>, key: 1 },
    ];


    const onClick = ({ key }) => {
        // key = parseInt(key) + 1;
        setOrder(key);
        // filter(key);
    };

    const itemstab = [
        {
            key: '1',
            label: 'All',
            children: <UserAllTable {...{ 
                setDeleteItem, setEditItem,
                setAddUser, setRemoveText, setAddBranch,
                addrole, setAddRole, setAddNotification, setRemoveNotify
            }} />,
        },
        {
            key: '2',
            label: 'Console Access',
            children: <ConsoleAccessTable {...{ 
                setDeleteItem, setEditItem,
                setAddUser, setRemoveText, setAddBranch,
                addrole, setAddRole, setAddNotification, setRemoveNotify
            }} />,
        },
        {
            key: '3',
            label: 'Cashier Access',
            children: <CashierAccessTable {...{ 
                setDeleteItem, setEditItem,
                setAddUser, setRemoveText, setAddBranch,
                addrole, setAddRole, setAddNotification, setRemoveNotify
            }} />,
        },
        {
            key: '4',
            label: 'Deleted',
            children: <DeleteduserTable {...{ 
                setDeleteItem, setEditItem,
                setAddUser, setRemoveText, setAddBranch,
                addrole, setAddRole, setAddNotification, setRemoveNotify
            }} />,
        },
    ];
  return (
    <div>
        <Row gutter={[24, 24]} align={'middle'}>
            <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
                <ModuleTopHeading name='Users' onClick={()=>{setAddUser(true)}} />
            </Col>
            <Col lg={{span:12}} md={{span: 10}} sm={{span: 24}} xs={{span: 24}}>
                <Space size={10} className='w-100 right mbl-space' wrap>
                    <Button className='btncancel pad-filter bg-light-gray mbl-btn' onClick={()=> setFilterModal(true)}>
                        <Flex justify='space-between' className='w-100' gap={10}>
                            <Image src='/assets/icons/filter-outline.png' preview={false} width={18} />
                            <Text className='text-dark'>Filter</Text>
                        </Flex>
                    </Button>
                    <Dropdown
                        menu={{ items, onClick }}
                        trigger={['click']}
                        className='margin-top'
                    >
                        <Button className='btncancel pad-filter bg-light-gray mbl-btn'
                            icon={<img src='/assets/icons/export-filter.png' width={20}/>}
                        >
                            <Flex justify='space-between' className='w-100' gap={30}>
                                Export / Import
                                <DownOutlined />    
                            </Flex>  
                        </Button>
                    </Dropdown>
                </Space>
            </Col>
            <Col span={24}>
                <Card className='shadow-d radius-12 card-cs'>
                    <Tabs defaultActiveKey="1" items={itemstab}  />
                </Card>
            </Col>
        </Row>
        <AddUsers 
            visible={adduser}
            edititem={edititem}
            onClose={()=>{setAddUser(false);setEditItem(null)}}
        />

        <UserFilter 
            visible={filtermodal}
            onClose={()=>setFilterModal(false)}
        />

        <UserImportFile 
            visible={importmodal}
            onClose={()=>setImportModal(false)}
        />

        <AlertModal 
            visible={deleteitem}
            deleteitem={deleteitem}
            onClose={()=>{setDeleteItem(false)}}
        />
        <Addrole 
            visible={addrole}
            removertext={removertext}
            onClose={()=> { setAddRole(false);setRemoveText(false)}}
        />

        <AddUserBranch 
            visible={addbranch}
            removertext={removertext}
            onClose={()=>{setAddBranch(false);setRemoveText(false)}}
        />

        <UserNotification 
            visible={addnotification}
            removenotify={removenotify}
            onClose={()=>{setAddNotification(false);setRemoveNotify(false)}}
        />
    </div>
  )
}

export {UserPage}