import React, { useState } from 'react'
import { BranchTrenReportLineChart, BusinessDayFilterModal, CalendarDropBorder, MenuEngineeringModal, MenuEngineeringTable, ModuleTopHeading, SearchInput, ShiftFilterModal } from '../../components'
import { Button, Card, Checkbox, Col, Dropdown, Flex, Form, Image, Row, Space, Tooltip, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const { Text } = Typography
const BranchTrendReportPage = () => {

  const [ filtermodal, setFilterModal ] = useState(false)
  const [ order, setOrder ] = useState(0);
  const [ filtertime, setFilterTime ] = useState(0)
  const [form] = Form.useForm();
  const items = [
    { label: <Link className='fs-13'>Gross Sales</Link>, key: 0 },
    { label: <Link className='fs-13'>Net Sales With Tax</Link>, key: 1 },
    { label: <Link className='fs-13'>Taxes</Link>, key: 2 },
    { label: <Link className='fs-13'>Gross Sales Without Tax</Link>, key: 3 },
    { label: <Link className='fs-13'>Net Sales</Link>, key: 4 },
    { label: <Link className='fs-13'>Order Count</Link>, key: 5 },
    { label: <Link className='fs-13'>Average Order</Link>, key: 6 },
    { label: <Link className='fs-13'>Net Products Quantity</Link>, key: 7 },
    { label: <Link className='fs-13'>Cost</Link>, key: 8 },
    { label: <Link className='fs-13'>Return Amount</Link>, key: 9 },
    { label: <Link className='fs-13'>Return Quantity</Link>, key: 10 },
    { label: <Link className='fs-13'>Profit</Link>, key: 11 },
  ];

  const filterbytime = [
    { label: <Link className='fs-13'>Hour of Day</Link>, key: 0 },
    { label: <Link className='fs-13'>Daily</Link>, key: 1 },
    { label: <Link className='fs-13'>Day of Week</Link>, key: 2 },
    { label: <Link className='fs-13'>Weekly</Link>, key: 3 },
    { label: <Link className='fs-13'>Monthly</Link>, key: 4 },
    { label: <Link className='fs-13'>Quarterly</Link>, key: 5 },
    { label: <Link className='fs-13'>Yearly</Link>, key: 6 },
  ];


const presets = [
    {
        label: 'Today',
        value: dayjs().add(0, 'd'),
    },
    {
        label: 'Yesterday',
        value: dayjs().add(-1, 'd'),
    },
    {
        label: 'This Week',
        value: dayjs().add(7, 'd'),
    },
    {
        label: 'Last Week',
        value: dayjs().add(-7, 'd'),
    },
    {
        label: 'This Month',
        value: dayjs().add(1, 'month'),
    },
    {
        label: 'Last Month',
        value: dayjs().add(-1, 'month'),
    },
]

const dropdownContent = (
    <Card className='radius shadow-c card-cs'>
        <Form form={form} layout="vertical">
            <SearchInput 
                name='search'
                value={form.getFieldValue('search') || ''}
                placeholder='Search...'
            />
        </Form>
        <Space direction='vertical' size={10} className='pt-3'>
            <Text>All Branches</Text>
            <Checkbox>Branch 1 (B01)</Checkbox>
        </Space>
    </Card>
);

const onClick = ({key})=> {
    setOrder(key)
}

const filterFun = ({key}) => {
    setFilterTime(key)
}

return (
    <div>
        <Row gutter={[24,24]}>
            <Col lg={{span:6}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                <ModuleTopHeading name='Branches Trend Reports'/>
            </Col>
          <Col lg={{span:18}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
            <Space size={10} className='w-100 right mbl-space' wrap>
                <Dropdown
                    menu={{ items, onClick }}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'>
                        <Flex justify='space-between' className='w-100' gap={30}>
                            {
                                order == 0 ? 'Gross Sales':
                                order == 1 ? 'Net Sales With Tax':
                                order == 2 ? 'Taxes':
                                order == 3 ? 'Gross Sales Without Tax':
                                order == 4 ? 'Net Sales':
                                order == 5 ? 'Order Count':
                                order == 6 ? 'Average Order':
                                order == 7 ? 'Net Products Quantity':
                                order == 8 ? 'Cost':
                                order == 10 ? 'Return Amount':
                                order == 11 ? 'Return Quantity':
                                order == 12 ? 'Return ': null
                            }
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <Dropdown
                    menu={{ items:filterbytime, onClick:filterFun }}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'>
                        <Flex justify='space-between' className='w-100' gap={30}>
                            {
                                filtertime == 0 ? 'Hour of Day':
                                filtertime == 1 ? 'Daily':
                                filtertime == 2 ? 'Day of Week':
                                filtertime == 3 ? 'Weekly':
                                filtertime == 4 ? 'Monthly':
                                filtertime == 5 ? 'Quarterly':
                                filtertime == 6 ? 'Yearly': null
                            }
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <Dropdown
                    overlay={dropdownContent}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                        icon={<img src='/assets/icons/branch-c.png' width={18}/>}
                    >
                        <Flex justify='space-between' className='w-100' gap={30}>
                            Branches 
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <CalendarDropBorder presets={presets} />
                <Button className='btncancel px-3' onClick={()=>setFilterModal(true)}>
                    <Image src='/assets/icons/filter-outline.png' preview={false} width={20} />
                </Button>
            </Space>
          </Col>
          <Col span={24}>
            <BranchTrenReportLineChart />
          </Col>
        </Row>

      <BusinessDayFilterModal 
        visible={filtermodal}
        onClose={()=>setFilterModal(false)}
      />
    </div>
  )
}

export {BranchTrendReportPage}