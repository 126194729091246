import React, { useState } from 'react';
import { Card, Form, Flex, Button, Space, Typography, Image } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { ModuleTopHeading } from '../../PageComponents';

const { Text } = Typography

const OrderTypeLineChart = () => {
  const [order, setOrder] = useState(0);
  const [form] = Form.useForm();
  const chartData = {
    series: [
      {
        name: 'Dine-in Orders',
        data: [0, 13, 17, 10, 15, 16, 25,14,17],
      },
      {
        name: 'Pick-up Orders',
        data: [0, 25, 5, 20, 24, 22, 18,25,22],
      },
      {
        name: 'Drive-thru Orders',
        data: [0, 6, 15, 19, 16, 25, 22,12,22],
      },
    ],
    options: {
      chart: {
        type: 'line',
        toolbar:{
          show: false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [
          '12 am',
          '03 am',
          '06 am',
          '09 am',
          '12 pm',
          '03 pm',
          '06 pm',
          '09 pm',
          '11 pm',
        ],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      colors: ['#2BE3B7','#F56C89','#7B57E0'],
      legend: {
        markers:{
            shape: "circle"
        }
      },
    },
  };

  return (
    <div>
      <Card className='shadow-d radius-12 card-cs'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <ModuleTopHeading name='Order Types' />
          <Form form={form} layout="vertical">
            <Flex justify='end' gap={10}>
              <Button className='btncancel fs-13 pad-filter'>
                View Report    
              </Button>
            </Flex>
          </Form>
        </Flex>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={200}
        />
      </Card>
    </div>
  );
};

export { OrderTypeLineChart };
