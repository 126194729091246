export const paymentmethodData = [
    {
        title:'Payment .M1',
        method:'Cash',
        status:'Active'
    },
    {
        title:'Payment .M2',
        method:'Card',
        status:'Inactive'
    },
    {
        title:'Payment .M3',
        method:'Custome',
        status:'Active'
    },
    {
        title:'Payment .M4',
        method:'card',
        status:'Deleted'
    },
    {
        title:'Payment .M5',
        method:'Cash',
        status:'Inactive'
    },
    {
        title:'Payment .M6',
        method:'Custom',
        status:'Active'
    },
    {
        title:'Payment .M7',
        method:'Cash',
        status:'Deleted'
    },
]