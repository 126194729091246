import { Col, Row, Typography } from 'antd'
import React from 'react'
import { ContactsDetails, GeneralDetails, SettingsDetails } from './BussinessDetailsInput'
const {Title} = Typography

const AccountBusinessDetails = ()=> {
  return (
    <Row gutter={[24, 24]} align={'middle'}>
            <Col span={24}>
              <GeneralDetails />
            </Col>
            <Col span={24}>
              <ContactsDetails />
            </Col>
            <Col span={24}>
              <SettingsDetails />
            </Col>
        </Row>
  )
}

export {AccountBusinessDetails}