import React, { useState } from 'react'
import { Card, Col, Flex, Row, Typography } from 'antd'
import { ModuleTopHeading } from '../../../PageComponents'

const { Text, Title } = Typography
const GeneralTaxcard = ({setAddTax,setEditItem}) => {

    const data = [
        {
            name:'Tax 1',
            per:'15%',
        },
        {
            name:'Tax 2',
            per:'15%',
        },
        {
            name:'Tax 3',
            per:'15%',
        },
    ]

  return (
    <div>
        <Row gutter={[24, 24]} align={'middle'}>
            <Col span={24}>
                <ModuleTopHeading name='Taxes' onClick={()=>{setAddTax(true)}} />
            </Col>
            {data.map((data, index) => (
              <Col xl={{span: 4}} lg={{span:6}} md={{span:6}} sm={{span:12}} xs={{span: 24}}>
                <Card
                  key={index}
                  align="center"
                  onClick={() => {setAddTax(true);setEditItem(data?.name)}}
                  className="cursor shadow-d radius-12 card-cs"
                  vertical justify="center" gap={5}
                >
                  <Title level={5} className='m-0'>{data?.name}</Title>
                  <Text className="fs-14 fw-400">
                    {data.per}
                  </Text>
                </Card>
              </Col>
            ))}
        </Row>
    </div>
  )
}

export {GeneralTaxcard}