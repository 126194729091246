import React, { useState } from 'react';
import { Card, Form, Flex, Button, Typography, Col, Row, Space, Tooltip, Image, Grid } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { ModuleTopHeading } from '../../PageComponents';

const { Title, Text } = Typography
const { useBreakpoint } = Grid


const BranchTrenReportLineChart = () => {
  const [form] = Form.useForm();
  const screens = useBreakpoint();
const chartData = {
  series: [
    {
      name: '11 April - 2024',
      data: [90, 100, 110, 250, 200, 150, 350, 170, 200, 370 ],
    },
  ],
  options: {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius:5,
        columnWidth: '50%',
        
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: [
        '12 am',
        '03 am',
        '06 am',
        '09 am',
        '12 pm',
        '03 pm',
        '06 pm',
        '09 pm',
        '11 pm',
        '03 pm',
      ],
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 400,
      tickAmount: 5,
      labels: {
        show:false
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    colors: ['#2BE3B7'],
    legend:{
      markers:{
        shape: "circle"
      }
    }   
  },
};


  return (
    <div>
        <Card className='shadow-d radius-12 card-cs'>
            <Row gutter={[16,16]} align={'middle'}>
                <Col span={24}>
                    <Title level={5} className='m-0 fw-500'>Gross Sales - Hour of Day</Title>
                </Col>
                <Col lg={{span: 12}} md={{span:12}} sm={{span: 24}} xs={{span: 24}}>
                    <Flex gap={20} wrap>
                        <Space direction='vertical' size={2}>
                            <Text>Branch 1</Text>
                            <Title level={4} className='m-0 fw-500'>SAR 804.5</Title>
                        </Space>
                        <Space direction='vertical' size={2}>
                          <Space>
                              <Text>Compare</Text>
                              <Tooltip title='The percentage of change compare to the same hour of the previous week.'>
                                  <Image src='/assets/icons/cir-i-gray.png' width={14} preview={false} />
                              </Tooltip>
                          </Space>
                          <Title level={4} className='m-0 fw-500'>-65.24%</Title>
                        </Space>
                    </Flex>
                </Col>
                <Col lg={{span: 12}} md={{span:12}} sm={{span: 24}} xs={{span: 24}}>
                    <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="line"
                        height={200}
                    />
                </Col>
            </Row>
        </Card>
    </div>
  );
};

export { BranchTrenReportLineChart };
