export const salereportbranchData = [
    {
        branchname:'Branch 1',
        branchreference:'B01',
        grosssale:2387,
        grossSaleper:'100%',
        netsaleWithTax:238,
        taxes: 310,
        grossNoTax: 2000,
        netsale:1200,
        netsaleper:'100%',
        ordercount: 70,
        averageorder: 25,
        netquantity: 150,
        cost: 240,
        returnAmount: 100,
        returnQuantity: '3',
        profit: 9000,

        categoryName:'Drinks',
        categoryreference:'1',
        modifierOption:'Cold',
        orderType:'Pick Up',
        product:'Grilled Fish',
        productreference:'Grilled Fish',
        cashier:'Omer',
        hoursofday:'2',
        day:'2024-10-02',
        week:'2024-09-30  -  2024-10-06',
        month:'2024-10',
        quarter:'2024-04',
        year:'2024',
    },
    {
        branchname:'Branch 1',
        branchreference:'B01',
        grosssale:2387,
        grossSaleper:'100%',
        netsaleWithTax:238,
        taxes: 400,
        grossNoTax:3000,
        netsale: 500,
        netsaleper:'100%',
        ordercount: 100,
        averageorder: 30,
        netquantity: 210,
        cost: 280,
        returnAmount: 80,
        returnQuantity: '2',
        profit: 10000,

        categoryName:'Drinks',
        categoryreference:'1',
        modifierOption:'Cold',
        orderType:'Pick Up',
        product:'Grilled Fish',
        productreference:'Grilled Fish',
        cashier:'Omer',
        hoursofday:'2',
        day:'2024-10-02',
        week:'2024-09-30  -  2024-10-06',
        month:'2024-10',
        quarter:'2024-04',
        year:'2024',
    },
]

export const salereportcashierdata = [
    {
        branchname:'Branch 1',
        branchreference:'B01',
        grosssale:2387,
        grossSaleper:'100%',
        netsaleWithTax:238,
        taxes: 310,
        grossNoTax: 2000,
        netsale:1200,
        netsaleper:'100%',
        ordercount: 70,
        averageorder: 25,
        netquantity: 150,
        cost: 240,
        returnAmount: 100,
        returnQuantity: '3',
        profit: 9000,

        categoryName:'Drinks',
        categoryreference:4,
        modifierOption:'Cold',
        orderType:'Pick Up',
        product:'Grilled Fish',
        productsku:"SK-0045",
        cashier:'Omer',
        hoursofday:'2',
        day:'2024-10-02',
        week:'2024-09-30  -  2024-10-06',
        month:'2024-10',
        quarter:'2024-04',
        year:'2024',
    }
]


export const salereportcategorydata = [
    {
        branchname:'Branch 1',
        branchreference:'B01',
        grosssale:2387,
        grossSaleper:'100%',
        netsaleWithTax:238,
        taxes: 310,
        grossNoTax: 2000,
        netsale:1200,
        netsaleper:'100%',
        ordercount: 70,
        averageorder: 25,
        netquantity: 150,
        cost: 240,
        returnAmount: 100,
        returnQuantity: '3',
        profit: 9000,

        categoryName:'Drinks',
        categoryreference:4,
        modifierOption:'Cold',
        orderType:'Pick Up',
        product:'Grilled Fish',
        productsku:"SK-0045",
        cashier:'Omer',
        hoursofday:'2',
        day:'2024-10-02',
        week:'2024-09-30  -  2024-10-06',
        month:'2024-10',
        quarter:'2024-04',
        year:'2024',
    }
]

export const salereportmodifierdata = [
    {
        branchname:'Branch 1',
        branchreference:'B01',
        grosssale:2387,
        grossSaleper:'100%',
        netsaleWithTax:238,
        taxes: 310,
        grossNoTax: 2000,
        netsale:1200,
        netsaleper:'100%',
        ordercount: 70,
        averageorder: 25,
        netquantity: 150,
        cost: 240,
        returnAmount: 100,
        returnQuantity: '3',
        profit: 9000,

        categoryName:'Drinks',
        categoryreference:4,
        modifierOption:'Cold',
        modifierOptionsku: "SK-0195",
        orderType:'Pick Up',
        product:'Grilled Fish',
        productsku:"SK-0045",
        cashier:'Omer',
        hoursofday:'2',
        day:'2024-10-02',
        week:'2024-09-30  -  2024-10-06',
        month:'2024-10',
        quarter:'2024-04',
        year:'2024',
    }
]

export const salereportordertypedata = [
    {
        branchname:'Branch 1',
        branchreference:'B01',
        grosssale:2387,
        grossSaleper:'100%',
        netsaleWithTax:238,
        taxes: 310,
        grossNoTax: 2000,
        netsale:1200,
        netsaleper:'100%',
        ordercount: 70,
        averageorder: 25,
        netquantity: 150,
        cost: 240,
        returnAmount: 100,
        returnQuantity: '3',
        profit: 9000,

        categoryName:'Drinks',
        categoryreference:4,
        modifierOption:'Cold',
        modifierOptionsku: "SK-0195",
        orderType:'Pick Up',
        product:'Grilled Fish',
        productsku:"SK-0045",
        cashier:'Omer',
        hoursofday:'2',
        day:'2024-10-02',
        week:'2024-09-30  -  2024-10-06',
        month:'2024-10',
        quarter:'2024-04',
        year:'2024',
    }
]

