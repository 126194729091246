const businessType = [
    {
        name: 'Quick Service',
        id : 1
    },
    {
        name: 'Fine Dining',
        id : 2
    },
    {
        name: 'Coffee Shop',
        id : 3
    },
    {
        name: 'Pizzeria',
        id : 4
    },
    {
        name: 'Bakery',
        id : 5
    },
    {
        name: 'Caterer',
        id : 6
    },
    {
        name: 'Food Truck',
        id : 7
    },
    {
        name: 'Grocery',
        id : 8
    },
    {
        name: 'Retail',
        id : 9
    },
    {
        name: 'Other',
        id : 10
    },
]

const cityName = [
    { id: 1, name: 'Riyadh' },
    { id: 2, name: 'Jeddah' },
    { id: 3, name: 'Makkah' },
    { id: 4, name: 'Madinah' },
    { id: 5, name: 'Dammam' },
    { id: 6, name: 'Taif' },
    { id: 7, name: 'Khobar' },
    { id: 8, name: 'Buraydah' },
    { id: 9, name: 'Dhahran' },
    { id: 10, name: 'Tabuk' },
    { id: 11, name: 'Sharurah' },
    { id: 12, name: 'Qatif' },
    { id: 13, name: 'Khamis Mushait' },
    { id: 14, name: 'Hail' },
    { id: 15, name: 'Al-Ahsa' },
    { id: 16, name: 'Najran' },
    { id: 17, name: 'Jazan' },
    { id: 18, name: 'Abha' },
    { id: 19, name: 'Yanbu' },
    { id: 20, name: 'Arar' },
    { id: 21, name: 'Sakaka' },
    { id: 22, name: 'Al-Baha' },
    { id: 23, name: 'Al-Kharj' },
    { id: 24, name: 'Hafar Al-Batin' },
    { id: 25, name: 'Jubail' },
    { id: 26, name: 'Al-Qunfudhah' },
    { id: 27, name: "Al-Majma'ah" },
    { id: 28, name: 'Wadi ad-Dawasir' },
    { id: 29, name: 'Al-Diriyah' },
    { id: 30, name: 'Unaizah' },
    { id: 31, name: 'Ar Rass' },
    { id: 32, name: 'Rabigh' },
    { id: 33, name: 'Al-Laith' },
    { id: 34, name: 'Duba' },
    { id: 35, name: 'Badr' },
    { id: 36, name: 'Al-Ula' },
    { id: 37, name: 'Rafha' },
    { id: 38, name: 'Turaif' },
    { id: 39, name: 'Al-Namas' },
    { id: 40, name: 'Baljurashi' },
    { id: 41, name: 'Al-Mithnab' },
    { id: 42, name: 'Shaqra' },
    { id: 43, name: 'Umluj' },
    { id: 44, name: "Al-Bad'" },
    { id: 45, name: 'Al-Wajh' },
    { id: 46, name: 'Thadiq' },
    { id: 47, name: 'Al-Bukayriyah' },
    { id: 48, name: 'Bisha' },
    { id: 49, name: 'Al-Aflaj' },
    { id: 50, name: 'Thuwal' },
    { id: 51, name: 'Al-Qurayyat' },
    { id: 52, name: 'Al-Jumum' },
    { id: 53, name: 'Ahad Rafidah' },
    { id: 54, name: 'Dumat Al-Jandal' },
    { id: 55, name: 'Ranyah' },
    { id: 56, name: 'Tayma' },
    { id: 57, name: 'Al-Kamil' },
    { id: 58, name: 'Samtah' },
    { id: 59, name: 'Al-Hanakia' },
    { id: 60, name: 'Farasan' },
    { id: 61, name: 'Al-Dawadmi' },
    { id: 62, name: 'Al-Khafji' },
    { id: 63, name: 'Al-Aqiq' },
    { id: 64, name: 'Muhayil Asir' },
    { id: 65, name: 'Al-Mandaq' },
    { id: 66, name: 'Thar' },
    { id: 67, name: 'Al-Nabhaniyah' },
    { id: 68, name: 'Al-Rayn' },
    { id: 69, name: 'Hawtat Bani Tamim' },
    { id: 70, name: 'Al-Jafr' },
    { id: 71, name: 'Bish' },
    { id: 72, name: 'Habuna' },
    { id: 73, name: 'Al-Khurma' },
    { id: 74, name: 'Al-Mahd' },
    { id: 75, name: 'Turubah' },
    { id: 76, name: 'Al-Khasirah' },
    { id: 77, name: 'Al-Mukhwah' },
    { id: 78, name: "Al-Quway'iyah" },
    { id: 79, name: 'Sabya' },
    { id: 80, name: 'Al-Dhahr' },
    { id: 81, name: 'As Sulayyil' },
    { id: 82, name: 'Tharban' },
    { id: 83, name: 'Al-Jouf' },
    { id: 84, name: 'Al-Haridhah' },
    { id: 85, name: 'Al-Awjam' },
    { id: 86, name: 'Abu Arish' },
    { id: 87, name: 'Al-Ahsa (rural areas)' },
    { id: 88, name: 'Al-Ghazalah' },
    { id: 89, name: "Al-'Uyayna" },
    { id: 90, name: 'Al-Dilam' },
    { id: 91, name: 'Al-Aridah' },
    { id: 92, name: 'Hautat Sudair' },
    { id: 93, name: 'Al-Shamli' },
    { id: 94, name: 'Thadiq' },
    { id: 95, name: 'Al-Muzahmiyya' },
    { id: 96, name: 'Al-Jamash' },
    { id: 97, name: 'Al-Hareeq' },
    { id: 98, name: 'Qaisumah' },
    { id: 99, name: 'Al-Madhaya' },
    { id: 100, name: 'Al-Rass' }
];


export {businessType,cityName}