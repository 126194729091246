import React, { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Col, Dropdown, Form, Image, Row, Space, Table, Tooltip, Typography } from 'antd'
import { businessDayData } from '../../../../Data';

const { Text } = Typography
const BusinessDayTable = ({setFilterModal}) => {
    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState([]);

    const options = [
        'Branch Name', 
        'Branch Localized', 
        'Branch Reference', 
        'Opened At', 
        'Closed At', 
        'Opened By', 
        'Closed By', 
    ];
    const [checkAll, setCheckAll] = useState(false);
  
    const onChange = (item, checked) => {
      const updatedList = checked
        ? [...checkedList, item]
        : checkedList.filter((value) => value !== item);
  
      setCheckedList(updatedList);
      setCheckAll(updatedList.length === options.length);
    };
  
    const onCheckAllChange = (e) => {
      setCheckedList(e.target.checked ? options : []);
      setCheckAll(e.target.checked);
    };
    const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

    const columns = [
        {
            title: <Space>
                <Text className='fw-500'>Business Date</Text>
                <Tooltip title='The business date'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'businessdata',
        },
        {
            title: <Space>
                <Text className='fw-500'>Opened At</Text>
                <Tooltip title='The time of the first clock in the day'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'openedat',
        },
        {
            title: <Space>
                <Text className='fw-500'>Close At</Text>
                <Tooltip title='The time of the last clock out the day'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'closeat',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Branch Name</Text>
                    <Tooltip title='The name of the branch.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'branch',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Branch Localized</Text>
                    <Tooltip title='The branch localized name in the second language of your own choice.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'branchlocalized',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Branch Reference</Text>
                    <Tooltip title='The reference of the branch.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'branchreference',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Opened By</Text>
                    <Tooltip title='The first user to clock in.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'openedby',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Closed By</Text>
                    <Tooltip title='The last user to clock out.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'closedby',
        },      
    ]
 
   
    const dropdownContent = (
        <Card className='text-black radius-8 shadow-c card-cs'>
            <div>
                <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate}>Select All </Checkbox>
            </div>
            <Space direction='vertical' size={10} className='pt-3'>
                <Checkbox checked disabled>Business Date</Checkbox>
                <Checkbox
                    checked={checkedList.includes('Branch Name')}
                    onChange={(e) => onChange('Branch Name', e.target.checked)}
                    >
                    Branch Name
                </Checkbox>                
                <Checkbox
                    checked={checkedList.includes('Branch Localized')}
                    onChange={(e) => onChange('Branch Localized', e.target.checked)}
                    >
                    Branch Localized 
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Branch Reference')}
                    onChange={(e) => onChange('Branch Reference', e.target.checked)}
                    >
                    Branch Reference
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Opened At')}
                    onChange={(e) => onChange('Opened At', e.target.checked)}
                    >
                    Opened At
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Closed At')}
                    onChange={(e) => onChange('Closed At', e.target.checked)}
                    >
                    Closed At
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Opened By')}
                    onChange={(e) => onChange('Opened By', e.target.checked)}
                    >
                    Opened By
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Closed By')}
                    onChange={(e) => onChange('Closed By', e.target.checked)}
                    >
                    Closed By
                </Checkbox>
            </Space>
        </Card>
    );

   
    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16} align={'middle'}>
                            <Col span={24}>
                                <Space style={{justifyContent:"end"}} className='w-100'>
                                    <Button className='btncancel pad-filter' onClick={()=>setFilterModal(true)}>
                                        <Space>
                                            <Image src='/assets/icons/filter-outline.png' preview={false} width={20} />
                                            <Text className='text-black'>Filters</Text>
                                        </Space>
                                    </Button>
                                    <Tooltip title="Column">
                                        <Dropdown
                                            overlay={dropdownContent}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter brand-bg border0'>
                                                <Image src='/assets/icons/plus-cr.png' preview={false} width={20} />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={businessDayData} 
                        scroll={{x: 1200}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export { BusinessDayTable }
