import React, { useState } from 'react'
import { Button, Dropdown, Space, Table, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'

const { Text } = Typography

const ModifierTableAll = ({setShowBread, setEditItem, setVisible, setDeleteItem, setContentModifier, setAddProduct}) => {

    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);

    const columns = [
        {
            title: 'Modifier Name',
            dataIndex: 'name',
            sorter: (a,b) => a.name - b.name,
        },
        {
            title: 'Options',
            dataIndex: 'option',
            render: (option, record) => <Text className='text-brand cursor' 
            onClick={()=>{
                setShowBread(true);
                setContentModifier(record?.name)
                navigate(option === 'Options (0)' ? '/modifier' : '/modifieroption')}}>
                    {option}
            </Text>
        },
        {
            title: 'Linked Products',
            dataIndex: 'linkproduct',
            render: (linkproduct,record) => <Text className='text-brand cursor' 
            onClick={()=>{
                setShowBread(true);
                setContentModifier(record?.name)
                navigate(linkproduct === 'Products (0)'  ? '/modifier' : '/products')}} >
                    {linkproduct}
            </Text>
        },
        {
            title: 'Created',
            dataIndex: 'created',
        },
        // {
        //     title: 'Action',
        //     fixed: 'right',
        //     align:'center',
        //     className:'action',
        //     width:130,
        //     render: (_, row) => (
        //         <Space>
        //             <Dropdown
        //                 menu={{ items: [
        //                     { label: <Link onClick={()=>{setVisible(true);setEditItem(row)}} className='fs-13'>Edit</Link>, key: 0 },
        //                     { label: <Link onClick={()=> setAddProduct(true)} className='fs-13'>Add to Products</Link>, key: 1 },
        //                     { label: <Link onClick={()=>{setDeleteItem({name: row?.name})}} className='fs-13'>Delete</Link>, key: 2 }
        //                 ] }}
        //                 trigger={['click']}
        //                 className='margin-top border-circle'
        //             >
        //                 <img src='/assets/icons/3dots.png' width={18} />
        //             </Dropdown>
        //         </Space>
        //     ),
        // },
    ];

    const data = [
        {
            key: '1',
            name:'Size',
            option:'Options (5)',
            linkproduct:'Products (5)',
            created:'12:03 11/02/2024'
        },
        {
            key: '2',
            name:'Coffee',
            option:'Options (10)',
            linkproduct:'Products (10)',
            created:'11:03 12/02/2024'
        },
        {
            key: '3',
            name:'Milk',
            option:'Options (2)',
            linkproduct:'Products (2)',
            created:'01:03 11/02/2024'
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
    };

   
  return (
    <div>
        <Table 
            size='large'
            columns={columns} 
            dataSource={data} 
            scroll={{x: 1000}}
            className='table-dark pagination'
            showSorterTooltip={false}
            rowHoverable={true}
            // onRow={(record) => ({
            //     onClick: (event) => {
            //         const isActionColumn = event.target.closest('.action');
            //         if (!isActionColumn && record.id) {
            //             navigate(`/products/viewproducts/${record.id}`);
            //         }
            //     },
            // })}
            onRow={(record) => {
                return {
                    onClick:() => { setVisible(true);setEditItem(record?.name)} // Handle row click event
                }
            }}
            rowSelection={rowSelection}
            title={ selectedRows.length > 0 ? (record) => (
                <div>{selectedRows.length} Selected  
                    <Dropdown
                        menu={{ items: [
                            { label: <Link className='fs-13 action-column' onClick={()=> setAddProduct(true)}>Add to Products</Link>, key: 0 },
                            { 
                                label: <Link 
                                    onClick={() => { 
                                        const selectedRowNames = selectedRows?.map(row => row?.name);
                                        setDeleteItem({ name: ' ' + selectedRowNames + ' ' });
                                    }} 
                                    className='fs-13 action-column'
                                >
                                    Delete
                                </Link>, 
                                key: 6 
                            },
                        ] }}
                        trigger={['click',]}
                        className='margin-top'
                    >
                        <Button className='btncancel px-2 py-1 bg-light-gray mx-2'>
                            <Space>
                                <Text className='text-black fs-13'>Actions</Text>
                                <DownOutlined /> 
                            </Space>
                        </Button>
                    </Dropdown>
                </div> 
            ) : null}
            pagination={{
                hideOnSinglePage: true,
                total: 12,
                // pageSize: pagination?.pageSize,
                // defaultPageSize: pagination?.pageSize,
                // current: pagination?.pageNo,
                // size: "default",
                // pageSizeOptions: ['10', '20', '50', '100'],
                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
            }}
        />
    </div>
  )
}

export {ModifierTableAll}