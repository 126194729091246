import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Image, Tooltip, Input, Checkbox } from 'antd'
import { MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const AddDevice = ({visible, onClose , edititem }) => {
  const [ loading, setLoading ] = useState(false)
  const [ randomvalue, setRandomValue ] = useState(null)
  const [form] = Form.useForm()
  
  const GenerateValue = () => {
      setRandomValue(
          Math.floor(Math.random()*90000) + 10000
      )
  }
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        {edititem ? "Update" : "Save"}
                    </Button>
                </Space>
            
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{edititem? 'Edit User' : 'Add User'}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Device Name
                                <Tooltip title='The name of the device'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='devicename'
                            required
                            message='Please enter device name'
                            value={form.getFieldValue('devicename') || ''}
                        />
                    </Col>
                    {!edititem &&<Col span={24}>
                        <MySelect 
                            label={<Space>
                                Device Type
                                <Tooltip title='Choose the type of license you need'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name="devicetype" 
                            required
                            message='Please choose device type'
                            value={form.getFieldValue("devicetype") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Cashier'
                                },
                                {
                                    key: 2,
                                    name: 'Display'
                                },
                            ]}
                        />
                    </Col>}
                    <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Space className='w-100 space-cs'>
                            <MyInput 
                                label={<Space>
                                    Reference
                                    <Tooltip title='Reference number or ID of the device'>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                </Space>}
                                size='large'
                                type='number'
                                value={randomvalue}
                                className='w-100'
                            />
                            <Button 
                                className='pad-x fs-13 border-gray text-black h-40 margintop-5' 
                                onClick={GenerateValue}
                            >
                                Generate
                            </Button>
                        </Space>
                    </Col>
                    {!edititem &&<Col span={24}>
                        <MySelect 
                            label={<Space>
                                Branch
                                <Tooltip title='The branch where this device will be used'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name="branch" 
                            required
                            message='Please choose branch'
                            value={form.getFieldValue("branch") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Branch 1'
                                },
                                {
                                    key: 2,
                                    name: 'Branch 2'
                                },
                            ]}
                        />
                    </Col>}
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddDevice}
