import React from 'react'
import { ModuleTopHeading, ReceiptSetting } from '../../components'
import { Col, Row} from 'antd'


const ReceiptPage = ()=> {

  return (
    <div>
        <Row gutter={[24, 24]} align={'middle'}>
            <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
                <ModuleTopHeading name='Receipt' />
            </Col>
            <Col span={24}>
                <ReceiptSetting />
            </Col>
        </Row>
    </div>
  )

}

export {ReceiptPage}