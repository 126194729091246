export const shiftData = [
    {
        username:'Omar',
        branchname:'Branch 1',
        branchreference:'B01',
        clockedin:'October 02, 04:37am',
        clockedout:'October 03, 12:00am',
        openedby:'-',
        closedby:'-',
        shiftduration:'19:22',
    },
    {
        username:'Omar',
        branchname:'Branch 1',
        branchreference:'B01',
        clockedin:'October 23, 12:00am',
        clockedout:'October 24, 12:00am',
        openedby:'-',
        closedby:'-',
        shiftduration:'23:59',
    },
]