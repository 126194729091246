import React, { useState } from 'react'
import { Button, Dropdown, Flex, Space, Table, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { userbranchData } from '../../../../Data'
import { DownOutlined } from '@ant-design/icons'

const { Text } = Typography

const BranchAllTable = ({ setPaymentModal, setEditItem }) => {

    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);

    const columns = [
        {
            title: 'Branch Name',
            dataIndex: 'branchName',
            sorter: (a,b) => a.branchName - b.branchName,
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
        },
        {
            title: 'Tax Group',
            dataIndex: 'taxgroup',
        },
        {
            title: 'Created',
            dataIndex: 'created',
            width: 200,
        },
    ];


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
    };
   
  return (
    <div>
        
        <Table 
            size='large'
            columns={columns} 
            dataSource={userbranchData} 
            scroll={{x: 1200}}
            className='table-dark pagination'
            showSorterTooltip={false}
            rowSelection={rowSelection}
            rowHoverable={true}
            // showHeader={selectedRows.length === 0}
            onRow={(record) => ({
                onClick: (event) => {
                    const isActionColumn = event.target.closest('.action-column');
                    if (!isActionColumn && record.key) {
                        navigate(`/branches/singleview/${record.key}`);
                        setEditItem(record?.id);
                    }
                },
            })}
            title={ selectedRows.length > 0 ? (record) => (
                <div>{selectedRows.length} Selected  
                    <Dropdown
                        menu={{ items: [
                            { label: <Link onClick={()=>{ setPaymentModal(true) }} className='fs-13 action-column'>Inactivate Payment Methods</Link>, key: 0 },
                        ] }}
                        trigger={['click',]}
                        className='margin-top'
                    >
                        <Button className='btncancel px-2 py-1 bg-light-gray mx-2'>
                            <Space>
                                <Text className='text-black fs-13'>Actions</Text>
                                <DownOutlined /> 
                            </Space>
                        </Button>
                    </Dropdown>
                </div> 
            ) : null}
            pagination={{
                hideOnSinglePage: true,
                total: 12,
                // pageSize: pagination?.pageSize,
                // defaultPageSize: pagination?.pageSize,
                // current: pagination?.pageNo,
                // size: "default",
                // pageSizeOptions: ['10', '20', '50', '100'],
                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
            }}
        />
    </div>
  )
}

export {BranchAllTable}