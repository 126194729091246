import React, { useState } from 'react'
import { Button, Card, Checkbox, Col, Dropdown, Form, Image, Row, Space, Table, Tooltip, Typography } from 'antd'
import { shiftData } from '../../../../Data';

const { Text } = Typography
const ShiftsTable = ({setFilterModal}) => {
    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState([]);

    const options = [
        'Branch Name', 
        'Branch Reference', 
        'Clocked In At', 
        'Clocked Out At', 
        'Shift Duration',  
    ];
    const [checkAll, setCheckAll] = useState(false);
  
    const onChange = (item, checked) => {
      const updatedList = checked
        ? [...checkedList, item]
        : checkedList.filter((value) => value !== item);
  
      setCheckedList(updatedList);
      setCheckAll(updatedList.length === options.length);
    };
  
    const onCheckAllChange = (e) => {
      setCheckedList(e.target.checked ? options : []);
      setCheckAll(e.target.checked);
    };
    const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

    const columns = [
        {
            title: <Space>
                <Text className='fw-500'>User Name</Text>
                <Tooltip title='The name of a user in a given business day.'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'username',
        },
        {
            title: <Space>
                <Text className='fw-500'>Branch Name</Text>
                <Tooltip title='The name of the branch.'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'branchname',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Branch Reference</Text>
                    <Tooltip title='The reference of the branch.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'branchreference',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Clocked In At</Text>
                    <Tooltip title='Clocked In time'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'clockedin',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Clocked Out At</Text>
                    <Tooltip title='Clocked Out time'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'clockedout',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Shift Duration</Text>
                    <Tooltip title='The duration of the shift.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'shiftduration',
        },          
    ]
 

    const dropdownContent = (
        <Card className='text-black radius-8 shadow-c card-cs'>
            <div>
                <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate}>Select All </Checkbox>
            </div>
            <Space direction='vertical' size={10} className='pt-3'>
                <Checkbox checked disabled>User</Checkbox>
                <Checkbox
                    checked={checkedList.includes('Branch Name')}
                    onChange={(e) => onChange('Branch Name', e.target.checked)}
                    >
                    Branch Name
                </Checkbox>       
                <Checkbox
                    checked={checkedList.includes('Branch Reference')}
                    onChange={(e) => onChange('Branch Reference', e.target.checked)}
                    >
                    Branch Reference
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Clocked In At')}
                    onChange={(e) => onChange('Clocked In At', e.target.checked)}
                    >
                    Clocked In At
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Clocked Out At')}
                    onChange={(e) => onChange('Clocked Out At', e.target.checked)}
                    >
                    Clocked Out At
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Shift Duration')}
                    onChange={(e) => onChange('Shift Duration', e.target.checked)}
                    >
                    Shift Duration
                </Checkbox>
            </Space>
        </Card>
    );

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16} align={'middle'}>
                            <Col span={24}>
                                <Space style={{justifyContent:"end"}} className='w-100'>
                                    <Button className='btncancel pad-filter' onClick={()=>setFilterModal(true)}>
                                        <Space>
                                            <Image src='/assets/icons/filter-outline.png' preview={false} width={20} />
                                            <Text className='text-black'>Filters</Text>
                                        </Space>
                                    </Button>
                                    <Tooltip title="Column">
                                        <Dropdown
                                            overlay={dropdownContent}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter brand-bg border0'>
                                                <Image src='/assets/icons/plus-cr.png' preview={false} width={20} />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={shiftData} 
                        scroll={{x: 1400}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export { ShiftsTable }
