import React, { useState } from 'react'
import { Card, Col, Flex, Row, Typography } from 'antd'
import { ModuleTopHeading } from '../../../PageComponents'

const { Text, Title } = Typography
const DeletedTaxCards = ({setRestoreModal}) => {

    const data = [
        {
            name:'Tax 1',
            per:'15%',
        },
        {
            name:'Tax 2',
            per:'15%',
        },
        {
            name:'Tax 3',
            per:'15%',
        },
    ]

  return (
    <div>
        <Row gutter={[24, 24]} align={'middle'}>
            <Col span={24}>
                <ModuleTopHeading name='Taxes' />
            </Col>
            {data.map((data, index) => (
              <Col xl={{span: 4}} lg={{span:6}} md={{span:12}} sm={{span:24}} xs={{span: 24}}>
                <Card
                  key={index}
                  onClick={() =>{setRestoreModal(true)}}
                  className="cursor shadow-d radius-12 card-cs"
                  vertical justify="center" gap={5}
                >
                    
                    <Title level={5} className='m-0'>{data?.name}</Title>
                    <Flex justify='space-between' align='center
                    '>
                        <Text className="fs-14 fw-400">
                            {data.per}
                        </Text>
                        <Text className='text-inactive bg-light-red fs-12 badge-cs'>
                            Delete
                        </Text>
                    </Flex>
                </Card>
              </Col>
            ))}
        </Row>
    </div>
  )
}

export {DeletedTaxCards}