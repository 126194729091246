import { Button, Card, Col, Image, Row, Space, Tabs, Typography } from 'antd'
import React, { useState } from 'react'
import {  AddDevice, DevicesAllTable, DevicesCashierTable, DevicesDisplayTable, DevicesFilter, ForceSyncData, ModuleTopHeading } from '../../components'

const { Text } =Typography

const DevicesPage =()=> {
  const [addDevice, setAddDevice] = useState(false)
  const [ filterModal , setFilterModal ] = useState(false)
  const [ forceSync , setForceSync ] = useState(false)


  const devicetab = [
    {
        key: '1',
        label: 'All',
        children: <DevicesAllTable {...{ 
          setForceSync
        }} />,
    },
    {
        key: '2',
        label: 'Cashier',
        children: <DevicesCashierTable {...{ 
          setForceSync
        }} />,
    },
    {
        key: '3',
        label: 'Display',
        children: <DevicesDisplayTable {...{ 
          setForceSync
        }} />,
    },
  ];

  return (
    <div>
      <Row gutter={[24, 24]} align={'middle'}>
        <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
            <ModuleTopHeading name='Devices' onClick={()=>{setAddDevice(true)}} />
        </Col>
        <Col lg={{span:12}} md={{span: 10}} sm={{span: 24}} xs={{span: 24}}>
            <Space size={10} className='w-100 right'>
                <Button className='btncancel pad-filter' onClick={()=>{setFilterModal(true)}}>
                    <Space>
                        <Image src='/assets/icons/filter-outline.png' preview={false} width={18} />
                        <Text className='text-black'>Filter</Text>
                    </Space>
                </Button>
            </Space>
        </Col>
        <Col span={24}>
            <Card className='shadow-d radius-12 card-cs'>
                <Tabs defaultActiveKey="1" items={devicetab}  />
            </Card>
        </Col>
      </Row>
      <AddDevice 
        visible={addDevice}
        edititem={''}
        onClose={()=>setAddDevice(false)}
      />
      <DevicesFilter 
        visible={filterModal}
        onClose={()=>setFilterModal(false)} 
      />
      <ForceSyncData
        visible={forceSync}
        onClose={()=>{setForceSync(false)}}
        />
    </div>
  )
}

export { DevicesPage }