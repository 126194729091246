import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Radio, Tooltip, Image } from 'antd'
import { MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const AddUserBranch = ({visible,onClose,editbranch,removertext,removecheck}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    const [value, setValue] = useState(null);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>
                    {  
                        removertext ? 'Remove Branches' :
                        editbranch ? 'Edit Branches' : 'Add Branches'
                    }
                </Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row>
                    {
                        !removecheck &&
                        <Col span={24}>
                            <Radio.Group onChange={onChange} value={value} className='mb-3'>
                                <Space direction='vertical'>
                                    <Radio value={1}>
                                        <Space>
                                            Automatically apply on all existing and new branches
                                            <Tooltip title='when selected, it will apply to any branch currently in the business or any newly added branches'>
                                                <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                            </Tooltip>
                                        </Space>
                                    </Radio>
                                    <Radio value={2}>Branches</Radio>
                                </Space>
                            </Radio.Group>
                        </Col>
                    }
                    <Col span={24}>
                        <MySelect
                            label= 'Branches'
                            name="branch" 
                            mode='multiple'
                            size={'large'}
                            value={form.getFieldValue("branch") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Branch 1 (B01)'
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddUserBranch}