import React, { useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Image, Row, Space, Tabs, Typography } from 'antd'
import { AddBranchuser, Addrole, AddUserBranch, AddUsers, AlertModal, BranchAllTable, FilterBranch, InactivePaymentBranch, ModuleTopHeading, UserFilter, UserImportFile, UserNotification } from '../../components'
import { DownOutlined } from '@ant-design/icons'

const { Text } = Typography
const BranchPage = () => {

    const [ deleteitem, setDeleteItem ] = useState(false)   
    const [ adduserbranch, setAddUserBranch ] = useState(false)
    const [ edititem, setEditItem ] = useState(false)
    const [ filtermodal, setFilterModal ] = useState(false)
    const [ paymentmodal,setPaymentModal ] = useState(false)

    const itemstab = [
        {
            key: '1',
            label: 'All',
            children: <BranchAllTable {...{ 
                setPaymentModal,setEditItem
            }} />,
        },
    ];
  return (
    <div>
        <Row gutter={[24, 24]} align={'middle'}>
            <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
                <ModuleTopHeading name='Branches' onClick={()=>{setAddUserBranch(true)}} />
            </Col>
            <Col lg={{span:12}} md={{span: 10}} sm={{span: 24}} xs={{span: 24}}>
                <Space size={10} className='w-100 right'>
                    <Button className='btncancel pad-filter bg-light-gray' onClick={()=> setFilterModal(true)}>
                        <Space>
                            <Image src='/assets/icons/filter-outline.png' preview={false} width={18} />
                            <Text className='text-dark'>Filter</Text>
                        </Space>
                    </Button>
                </Space>
            </Col>
            <Col span={24}>
                <Card className='shadow-d radius-12 card-cs'>
                    <Tabs defaultActiveKey="1" items={itemstab}  />
                </Card>
            </Col>
        </Row>
        <AddBranchuser 
            visible={adduserbranch}
            edititem={edititem}
            onClose={()=>{setAddUserBranch(false);setEditItem(null)}}
        />

        <FilterBranch 
            visible={filtermodal}
            onClose={()=>setFilterModal(false)}
        />

        <InactivePaymentBranch 
            visible={paymentmodal}
            onClose={()=>setPaymentModal(false)}
        />

        <AlertModal 
            visible={deleteitem}
            deleteitem={deleteitem}
            onClose={()=>{setDeleteItem(false)}}
        />
    </div>
  )
}

export {BranchPage}