import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Image, Tooltip } from 'antd'
import { MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const AddModifierOption = ({visible,onClose,edititem, setDeleteItem}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [ randomValue , setRandomValue ] = useState(false)

    const [form] = Form.useForm()

    const GenerateValue = () => {
        setRandomValue(
            Math.floor(Math.random()*90000) + 10000
        )
    }

    const deleteHandle = () => {
        setDeleteItem({name: edititem})
        onClose()
    }
  return (
    <div>
        <Modal 
            width={600} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space className={edititem ? "space-between-align" : "center" }>
                    <Space>
                    {edititem && (<Button  
                            block
                            type="primary"
                            className='btnsave text-white bg-red px-2'
                            loading={loading}
                            onClick={deleteHandle}
                            
                        >
                            Delete option
                        </Button>)}
                    </Space>
                  <Space className='space-between-align'>
      
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        { edititem ? "Update" : "Save" }
                    </Button>
                  </Space>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{edititem? 'Edit Modifier Options' : 'Add Modifier Options'}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Modifier Option Name
                                <Tooltip title='The name of the modifier option'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='optionname'
                            required
                            message='Please enter modifier option name'
                            value={form.getFieldValue('optionname') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MySelect 
                            label={<Space>
                                Modifier Name
                                <Tooltip title='Choose the modifier of this option'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name="name" 
                            required
                            message='Please choose modifier name'
                            value={form.getFieldValue("name") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Size'
                                },
                                {
                                    key: 2,
                                    name: 'Cheese'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Space className='w-100 space-cs'>
                            <MyInput 
                                label={<Space>
                                    SKU
                                    <Tooltip title='Stock Keeping Unit is a code of numbers or letters identify the modifier option'>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                </Space>}
                                size='large'
                                type='number'
                                value={randomValue}
                                className='w-100'
                            />
                            <Button 
                                className='pad-x fs-13 border-gray text-black h-40 margintop-5' 
                                onClick={GenerateValue}
                            >
                                Generate
                            </Button>
                        </Space>
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Price
                                <Tooltip title='Set modifier option price'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='price'
                            type='number'
                            required
                            message='Please enter price'
                            value={form.getFieldValue('price') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Cost
                                <Tooltip title='Set modifier option cost'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='cost'
                            type='number'
                            required
                            message='Please enter cost'
                            value={form.getFieldValue('cost') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect 
                            label={<Space>
                                Tax Group
                                <Tooltip title='Choose the tax group applied to this option'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='taxgroup'
                            required
                            message='Please enter tax group'
                            value={form.getFieldValue("taxgroup") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: '30%'
                                },
                                {
                                    key: 2,
                                    name: '40%'
                                },
                                {
                                    key: 3,
                                    name: '50%'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Name Localized
                                <Tooltip title='Enter the localized name in the second language of your own choice'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='localized'
                            required
                            message='Please enter name localized'
                            value={form.getFieldValue('localized') || ''}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddModifierOption}