import React, { useState } from 'react'
import { BusinessDayFilterModal, BusinessDayTable, CalendarDropBorder, ModuleTopHeading, SearchInput } from '../../components'
import { Button, Card, Checkbox, Col, Dropdown, Flex, Form, Row, Space, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';

const { Text } = Typography
const BusinessDayReportPage = () => {

  const [ filtermodal, setFilterModal ] = useState(false)
  const [ order, setOrder ] = useState(null);
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#" className='fs-13'>To CSV</a>, key: 0 },
    { label: <a href="#" className='fs-13'>To Excel</a>, key: 1 },
  ];


const presets = [
    {
        label: 'Today',
        value: dayjs().add(0, 'd'),
    },
    {
        label: 'Yesterday',
        value: dayjs().add(-1, 'd'),
    },
    {
        label: 'This Week',
        value: dayjs().add(7, 'd'),
    },
    {
        label: 'Last Week',
        value: dayjs().add(-7, 'd'),
    },
    {
        label: 'This Month',
        value: dayjs().add(1, 'month'),
    },
    {
        label: 'Last Month',
        value: dayjs().add(-1, 'month'),
    },
]

const dropdownContent = (
    <Card className='radius shadow-c card-cs'>
        <Form form={form} layout="vertical">
            <SearchInput 
                name='search'
                value={form.getFieldValue('search') || ''}
                placeholder='Search...'
            />
        </Form>
        <Space direction='vertical' size={10} className='pt-3'>
            <Text>All Branches</Text>
            <Checkbox>Branch 1 (B01)</Checkbox>
        </Space>
    </Card>
);

return (
    <div>
        <Row gutter={[24,24]}>
            <Col lg={{span:8}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                <ModuleTopHeading name='Business Days'/>
            </Col>
          <Col lg={{span:16}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
            <Space size={10} className='w-100 right mbl-space' wrap>
                <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                        icon={<img src='/assets/icons/export-filter.png' width={18}/>}
                    >
                        <Flex justify='space-between' className='w-100' gap={30}>
                            Export
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <Dropdown
                    overlay={dropdownContent}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                        icon={<img src='/assets/icons/branch-c.png' width={18}/>}
                    >
                        <Flex justify='space-between' className='w-100' gap={30}>
                            Branches 
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <CalendarDropBorder presets={presets} />
            </Space>
          </Col>
          <Col span={24}>
            <Card className='shadow-d radius-12 card-cs'>
                <BusinessDayTable {...{filtermodal, setFilterModal, order}} />
            </Card>
          </Col>
        </Row>

      <BusinessDayFilterModal 
        visible={filtermodal}
        onClose={()=>setFilterModal(false)}
      />
    </div>
  )
}

export {BusinessDayReportPage}