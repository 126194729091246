import React, { useState } from 'react'
import { Col, Row, Typography } from 'antd'
import { CardComponent, FilterModal, HourlySaleBarChart, ModuleTopHeading, NetPaymentLineChart, NetSaleLineChart, OrderAnalysisLine, OrderTypeLineChart, OrderTypeStackChart, ReturnAmountLineChart, StaffSessionChart, TabsComponent, TopBranchesNetSalePieChart, TopPaymentPieChart, TopProductNetSalePieChart, TopProductQuantityPieChart } from '../../components'
const {Title} = Typography

const Dashboard = () => {

  const [ filtermodal, setFilterModal ] = useState(false)
  
  return (
    <div>
      <Row gutter={[16,16]}>
        <Col span={24} className='mb-3'>
          <Title level={4} className='m-0 fw-500'>Welcome Zayed!</Title>
        </Col>
        <Col span={24}>
          <TabsComponent {...{setFilterModal}} />
        </Col>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
          <OrderAnalysisLine />
        </Col>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
          <NetSaleLineChart />
        </Col>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
          <NetPaymentLineChart />
        </Col>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
          <ReturnAmountLineChart />
        </Col>
        <Col span={24}>
          <OrderTypeLineChart />
        </Col>
        {/* <Col span={24}>
          <OrderTypeStackChart />
        </Col> */}
        <Col span={24}>
          <HourlySaleBarChart />
        </Col>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
          <TopProductNetSalePieChart />
        </Col>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
          <TopProductQuantityPieChart />
        </Col>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
          <TopPaymentPieChart />
        </Col>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
          <TopBranchesNetSalePieChart />
        </Col>
      </Row>
      <FilterModal 
        visible={filtermodal}
        onClose={()=>setFilterModal(false)}
      />
    </div>
  )
}

export {Dashboard}