import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Flex,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ActionButton } from "../../../PageComponents";
import { useNavigate } from "react-router-dom";
import { ModifierSorterPop } from "../ModifierModals";

const DraggableCol = ({ className, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props["data-node-key"],
    });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
  };
  return (
    <Col
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="cursor shadow-c px-3 py-3 radius-8 sorttexthover center"
    >
      {props.children}
    </Col>
  );
};

const { Text } = Typography;
const ModifierSorter = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sortermodal, setSorterModal] = useState(false);

  const [items, setItems] = useState([
    {
      key: "1",
      label: "Size",
    },
    {
      key: "2",
      label: "Milk",
    },
    {
      key: "3",
      label: "Coffee",
    },
  ]);
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setItems((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <div>
      <Flex vertical gap={10}>
        <Breadcrumb>
          <Breadcrumb.Item>Menu</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/modifier")}>
            Modifiers
          </Breadcrumb.Item>
          <Breadcrumb.Item>Sort Modifiers</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="radius-12 shadow-c">
          <Flex align="center" gap={6} className="mb-2">
            <ActionButton
              title="Edit"
              shape="circle"
              icon={<ArrowLeftOutlined />}
              className=" bg-transparent border0"
              onClick={() => navigate("/modifier")}
            />
            <Text>Sort Modifiers</Text>
          </Flex>
          <Flex className="py-3">
            <DndContext
              sensors={[sensor]}
              onDragEnd={onDragEnd}
              collisionDetection={closestCenter}
            >
              <SortableContext
                items={items.map((i) => i.key)}
                strategy={horizontalListSortingStrategy}
              >
                <Flex gap={15} wrap>
                  {items.map((item) => (
                    <DraggableCol
                      key={item.key}
                      data-node-key={item.key}
                      style={{
                        gap: "4px",
                        flexDirection: "column",
                        width: "120px",
                        height: "55px",
                        border: "1px solid #F5F5F5",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Button className="border0 p-0 bg-transparent " 
                        onClick={() => setSorterModal(true)}>
                        <Typography.Text className="fs-14">
                          {item.label}
                        </Typography.Text>
                      </Button>
                    </DraggableCol>
                  ))}
                </Flex>
              </SortableContext>
            </DndContext>
          </Flex>
        </Card>
      </Flex>
      <ModifierSorterPop
        visible={sortermodal}
        onClose={() => setSorterModal(false)}
      />
    </div>
  );
};

export { ModifierSorter };
