import React, { useState } from 'react'
import { Button, Card, Checkbox, Col, Dropdown, Form, Image, Row, Space, Table, Tooltip, Typography } from 'antd'
import { SearchInput } from '../../Forms';
import { Link, useNavigate } from 'react-router-dom';
import { orderData } from '../../../Data';
import { DownOutlined } from '@ant-design/icons';

const { Text } = Typography
const OrderTable = ({setFilterModal}) => {
    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const navigate = useNavigate()
    const options = [
        'Reference', 
        'Call Number', 
        'Branch', 
        'Cashier', 
        'Total', 
        'Payment Methods', 
        'Payment Status', 
        'Business Date',
        'Opened At'
    ];
    const [checkAll, setCheckAll] = useState(false);
  
    const onChange = (item, checked) => {
      const updatedList = checked
        ? [...checkedList, item]
        : checkedList.filter((value) => value !== item);
  
      setCheckedList(updatedList);
      setCheckAll(updatedList.length === options.length);
    };
  
    const onCheckAllChange = (e) => {
      setCheckedList(e.target.checked ? options : []);
      setCheckAll(e.target.checked);
    };
    const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

    const columns = [
        {
            title: 'Reference Number',
            dataIndex: 'refNo',
            sorter: (a,b) => a.refNo - b.refNo,
            width: 200,
        },
        {
            title: 'Call Number',
            dataIndex: 'callNo',
            sorter: (a,b) => a.callNo - b.callNo,
            width: 150,
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
        },
        {
            title: 'Cashier',
            dataIndex: 'cashier',
        },
        {
            title: 'Total',
            dataIndex: 'total',
        },
        {
            title: 'Payment Methods',
            dataIndex: 'paymentmethod',
            width: 160,
        },
        {
            title: 'Payment Status',
            dataIndex: 'status',
            width:200,
            render: (status) => {
                if (status === 'Paid') {
                    return <Text className='text-active bg-light-green fs-12 badge-cs'>Paid</Text>;
                } else if (status === 'Refund') {
                    return <Text className='text-brand bg-light-blue fs-12 badge-cs'>Refund</Text>;
                } else if (status === 'Partial Refund') {
                    return <Text className='text-inactive bg-light-red fs-12 badge-cs' cls>Partial Refund</Text>;
                } else if (status === 'Hold') {
                    return <Text className='text-inactive bg-light-red fs-12 badge-cs'>Hold</Text>;
                } else {
                    return <></>;
                }
            },
        },
        {
            title: 'Business Date',
            dataIndex: 'businessdate',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            width: 200
        },
        
    ];
 
    //   column dropdown
    const dropdownContent = (
        <Card className='text-black radius-8 shadow-c card-cs'>
            <div>
                <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate}>Select All</Checkbox>
            </div>
            <Space direction='vertical' size={10} className='pt-3 px-3'>
                <Checkbox checked disabled>Reference</Checkbox>
                <Checkbox
                    checked={checkedList.includes('Call Number')}
                    onChange={(e) => onChange('Call Number', e.target.checked)}
                    >
                    Call Number
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Branch')}
                    onChange={(e) => onChange('Branch', e.target.checked)}
                    >
                    Branch
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Cashier')}
                    onChange={(e) => onChange('Cashier', e.target.checked)}
                    >
                    Cashier
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Total')}
                    onChange={(e) => onChange('Total', e.target.checked)}
                    >
                    Total
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Payment Methods')}
                    onChange={(e) => onChange('Payment Methods', e.target.checked)}
                    >
                    Payment Methods
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Payment Status')}
                    onChange={(e) => onChange('Payment Status', e.target.checked)}
                    >
                    Payment Status
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Business Date')}
                    onChange={(e) => onChange('Business Date', e.target.checked)}
                    >
                    Business Date
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Opened At')}
                    onChange={(e) => onChange('Opened At', e.target.checked)}
                    >
                    Opened At
                </Checkbox>
            </Space>
        </Card>
    );

    
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
    };
   
    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={[16,16]} align={'middle'}>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <SearchInput 
                                    name='name'
                                    placeholder='Search by reference number'
                                    value={form.getFieldValue('name') || ''}
                                    prefix={<img src='/assets/icons/search.png' width={15} />}
                                    className='border-light-gray pad-x radius-8 fs-13 search-w'
                                />
                            </Col>
                            <Col lg={16} md={12} sm={24} xs={24}>
                                <Space style={{justifyContent:"end"}} className='w-100' wrap>
                                    <Tooltip title="Refresh">
                                        <Button className='btncancel pad-filter'>
                                            <Image src='/assets/icons/refresh.png' preview={false} width={20} />
                                        </Button>
                                    </Tooltip>
                                    <Button className='btncancel pad-filter' onClick={()=>setFilterModal(true)}>
                                        <Space>
                                            <Image src='/assets/icons/filter-outline.png' preview={false} width={20} />
                                            <Text className='text-black'>Filters</Text>
                                        </Space>
                                    </Button>
                                    <Tooltip title="Column">
                                        <Dropdown
                                            overlay={dropdownContent}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter brand-bg border0'>
                                                <Image src='/assets/icons/plus-cr.png' preview={false} width={20} />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={orderData} 
                        scroll={{x: 1600}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        onRow={(record) => ({
                            onClick: (event) => {
                                const isActionColumn = event.target.closest('.action');
                                if (!isActionColumn && record.key) {
                                    navigate(`/order/vieworder/${record.key}`);
                                }
                            },
                        })}
                        rowSelection={rowSelection}
                        title={ selectedRows.length > 0 ? (record) => (
                            <div>{selectedRows.length} Selected  
                                <Dropdown
                                    menu={{ items: [
                                        { label: <Link onClick={()=>{ }} className='fs-13 action-column'>Print</Link>, key: 0 },
                                    ] }}
                                    trigger={['click',]}
                                    className='margin-top'
                                >
                                    <Button className='btncancel px-2 py-1 bg-light-gray mx-2'>
                                        <Space>
                                            <Text className='text-black fs-13'>Actions</Text>
                                            <DownOutlined /> 
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </div> 
                        ) : null}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export { OrderTable }
