import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Divider } from 'antd'
import { apiCalls } from '../../../shared/Apis';
import { MyDatepicker, MyInput, MySelect } from '../../Forms';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Text } = Typography

const FilterOrderModal = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    // const DeleteProductApi = async() => {
    //     setLoading(true)
    //     try{
    //         const result = await apiCalls.vendorApi.DeleteProductApi(deletevendor?.id)
    //         setLoading(false)
    //         onClose();
    //         getVendorApplications();
    //     } catch (error){
    //         console.error("Error deleting data",error)
    //     }
    // }
  return (
    <div>
        <Modal 
            width={700} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Filter</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            type='number'
                            label='Call Number'
                            size='large'
                            name='callNo'
                            required
                            message='Please enter call number'
                            value={form.getFieldValue('callNo') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyDatepicker 
                            datePicker
                            presets={[
                                {
                                    label: 'Today',
                                    value: dayjs().add(0, 'd'),
                                },
                                {
                                    label: 'Yesterday',
                                    value: dayjs().add(-1, 'd'),
                                },
                                {
                                    label: 'This Week',
                                    value: dayjs().add(7, 'd'),
                                },
                                {
                                    label: 'Last Week',
                                    value: dayjs().add(-7, 'd'),
                                },
                                {
                                    label: 'This Month',
                                    value: dayjs().add(1, 'month'),
                                },
                                {
                                    label: 'Last Month',
                                    value: dayjs().add(-1, 'month'),
                                },
                              ]}
                            label='Business Date'
                            size='large'
                            name='businessdate'
                            required
                            message='Please enter business date'
                            value={form.getFieldValue('businessdate') || ''}
                            className='border-gray fs-13'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Branch Name'
                            name="branch" 
                            showSearch
                            size={'large'}
                            value={form.getFieldValue("branch") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Branch 01 (B01)'
                                },
                                {
                                    key: 2,
                                    name: 'Branch 02'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Order Type'
                            name="orderType" 
                            size={'large'}
                            value={form.getFieldValue("orderType") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Dine-in Order'
                                },
                                {
                                    key: 2,
                                    name: 'Pickup Order'
                                },
                                {
                                    key: 3,
                                    name: 'Drive-thru Order'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Payment Status'
                            name="paymentstatus" 
                            size={'large'}
                            value={form.getFieldValue("paymentstatus") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Paid'
                                },
                                {
                                    key: 2,
                                    name: 'Refund'
                                },
                                {
                                    key: 3,
                                    name: 'Partial Refund'
                                },
                                {
                                    key: 4,
                                    name: 'Hold'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Cashier'
                            name="cashier" 
                            showSearch
                            size={'large'}
                            value={form.getFieldValue("cashier") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Ali Khan'
                                },
                                {
                                    key: 2,
                                    name: 'Abbas Ali'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Device'
                            name="device" 
                            showSearch
                            size={'large'}
                            value={form.getFieldValue("device") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Cashier 01'
                                },
                                {
                                    key: 2,
                                    name: 'Cashier 02'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Payment Method'
                            name="paymentMethod" 
                            showSearch
                            size={'large'}
                            value={form.getFieldValue("paymentMethod") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Cash'
                                },
                                {
                                    key: 2,
                                    name: 'Card'
                                },
                                {
                                    key: 3,
                                    name: 'Custom'
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {FilterOrderModal}