import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Image, Upload, message, Tooltip } from 'antd'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography
const { Dragger } = Upload;

const AddEditProduct = ({visible,onClose,edititem}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [ randomValue , setRandomValue ] = useState(false)

    const [form] = Form.useForm()
    
    const GenerateValue = () => {
        setRandomValue(
            Math.floor(Math.random()*90000) + 10000
        )
    }
    

    const props = {
        name: 'image',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };
  return (
    <div>
        <Modal 
            width={520} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space className={edititem ? "space-between-align" : "center" }>
                    <Space>
                    {edititem && (<Button  
                            block
                            type="primary"
                            className='btnsave text-white bg-red px-2'
                            loading={loading}
                            onClick={()=>{
                                // DeleteProductApi();  
                            }}
                            
                        >
                            Delete Product
                        </Button>)}
                    </Space>
                  <Space className='space-between-align'>
      
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        { edititem ? "Update" : "Save" }
                    </Button>
                  </Space>
                </Space>
            }
        >
            <Flex align='center' justify='space-between'  className='header-modal'>
                <Text strong>{edititem ? 'Edit Product' : 'Add Product'}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'

            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name='image' >
                            <div className='width-drag'>
                                <Dragger {...props} className="dragcs">
                                    <p className="ant-upload-drag-icon mb-0">
                                        <Image src='/assets/icons/plus.png' width={'30px'} preview={false} />
                                    </p>
                                    <Typography.Text className="fs-14 fw-500">Upload Image</Typography.Text>
                                </Dragger>
                            </div>
                            {/* {
                                editproperty &&
                                <Space direction='vertical' size={10}>
                                    {
                                        geteditdata?.medical_report?.map((data,index)=>
                                            <div className='viewDownload space-between-align' key={index}>
                                                <Typography.Text className='gray-text fs-12'>
                                                    {
                                                        data?.name
                                                    }
                                                </Typography.Text>
                                                <ActionButton
                                                    title='Delete'
                                                    icon={<DeleteOutlined className='danger-color' />}
                                                    onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                    type='ghost'
                                                />
                                            </div>
                                        )
                                    }
                                </Space>
                                
                            } */}
                        </Form.Item>
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Product Name
                                <Tooltip title='Enter Product Name'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='productName'
                            required
                            message='Please enter product name'
                            value={form.getFieldValue('productName') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Name Localized
                                <Tooltip title='Enter Name Localized'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='nameLocal'
                            required
                            message='Please enter Name Localized'
                            value={form.getFieldValue('nameLocal') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MySelect
                            label={<Space>
                              Category Name
                              <Tooltip title='Enter Category Name'>
                                  <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                              </Tooltip>
                          </Space>}
                            name="categoryName" 
                            showSearch
                            size={'large'}
                            value={form.getFieldValue("categoryName") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'SeaFood'
                                },
                                {
                                    key: 2,
                                    name: 'Italian Food'
                                },
                                {
                                  key: 3,
                                  name: 'Drink'
                              },
                            ]}
                        />
                    </Col>
                    {edititem && (<Col span={24}>
                        <MyInput 
                            label={<Space>
                                Product Description
                                <Tooltip title='Enter the Product Description.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='productDesc'
                            required
                            message='Please enter Product Description'
                            value={form.getFieldValue('productDesc') || ''}
                        />
                    </Col>)}
                    {edititem && (<Col span={24}>
                        <MyInput 
                            label={<Space>
                                Description Localized
                                <Tooltip title='Enter Description Localized'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='descLocal'
                            required
                            message='Please enter Description Localized'
                            value={form.getFieldValue('descLocal') || ''}
                        />
                    </Col>)}
                    <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Space className='w-100 space-cs'>
                            <MyInput 
                                label={<Space>
                                    SKU
                                    <Tooltip title='Enter SKU'>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                </Space>}
                                size='large'
                                type='number'
                                value={randomValue}
                                className='w-100'
                            />
                            <Button 
                                className='pad-x fs-13 border-gray text-black h-40 margintop-5' 
                                onClick={GenerateValue}
                            >
                                Generate
                            </Button>
                        </Space>
                    </Col>
                    
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Price
                                <Tooltip title='Enter Price'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            type="number"
                            size='large'
                            name='price'
                            required
                            message='Please enter price'
                            value={form.getFieldValue('price') || ''}
                        />
                    </Col>
                    
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Cost
                                <Tooltip title='Enter Cost'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            type="number"
                            size='large'
                            name='cost'
                            required
                            message='Please enter cost'
                            value={form.getFieldValue('cost') || ''}
                        />
                    </Col>
                    <Col span={12}>
                        <MySelect
                            label={<Space>
                              Tax Group
                              <Tooltip title='Enter Tax Group'>
                                  <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                              </Tooltip>
                          </Space>}
                            name="taxGroup" 
                            showSearch
                            size={'large'}
                            value={form.getFieldValue("taxGroup") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: "50%"
                                },
                                {
                                  key: 1,
                                  name: "70%"
                              },
                              {
                                key: 1,
                                name: "100%"
                            },
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <MyInput 
                            label={<Space>
                                Barcode
                                <Tooltip title='Enter Barcode'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            type="number"
                            size='large'
                            name='barcode'
                            required
                            message='Please enter Barcode'
                            value={form.getFieldValue('barcode') || ''}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddEditProduct}