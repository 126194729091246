import React from "react";
import { Breadcrumb, Button, Card, Col, Flex, Form, Image, Row, Space, Table, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { orderData } from "../../../Data";
import { OrderProductTable } from "../OrderProductTable";
import { OrderPaymentTable } from "../OrderPaymentTable";
import { MyInput } from "../../Forms/MyInput";

const { Text, Title } = Typography;
const ViewOrders = () => {
  let { orderId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm()
  const order = orderData.find((pro) => pro.key === orderId);

  const orderColumns = [
    {
      dataIndex: "order",
      width: 150,
    },
    {
      dataIndex: "detail",
      width: 150,
    },
  ];

  const data = [
    {
        key: '1',
        order: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Business Date</Text>
            <Text>{order?.businessdate}</Text>
        </Space>,
        detail: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Call Number</Text>
            <Text>{order?.callNo}</Text>
        </Space>,
    },
    {
        key: '2',
        order: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Time</Text>
            <Text>{order.time}</Text>
        </Space>,
        detail: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Type</Text>
            <Text>Dine in</Text>
        </Space>,
    },
    {
        key: '3',
        order: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Cashier</Text>
            <Text>{order?.cashier}</Text>
        </Space>,
        detail: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Branch Name</Text>
            <Text>{order?.branch}</Text>
        </Space>,
    },
];

const priceData = [
  {
      key: '1',
      order: <Space direction='vertical' size={2}>
          <Text className='text-gray'>Sub Total</Text>
          <Text>SAR 100</Text>
      </Space>,
      detail: <Space direction='vertical' size={2}>
          <Text className='text-gray'>Total Taxes</Text>
          <Text>SAR 10</Text>
      </Space>,
  },
  {
      key: '2',
      order: <Space direction='vertical' size={2}>
          <Text className='text-gray'>Final Amount</Text>
          <Text>SAR 110</Text>
      </Space>,
  },
  
];
 
  return (
    <div className="mb-3">
          <Row gutter={[24, 24]}>
            <Col span={24}>
            <Breadcrumb separator=">" className='mb-3 fs-14' >
                <Breadcrumb.Item className='text-black' onClick={() => navigate("/order")}>
                    Orders
                </Breadcrumb.Item>
                <Breadcrumb.Item className='fw-500'>Order {order?.refNo}</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
            <Col span={24} className="space-between-align">
              <Flex justify="space-between" gap={20} wrap className="w-100">
                <Space wrap>
                  <Button
                    className="border0 p-0 bg-transparent"
                    onClick={() => navigate("/order")}
                  >
                    <ArrowLeftOutlined />
                  </Button>
                          <Title level={5} className='m-0'>Order ({order.refNo})</Title>
                  <Text
                    className={
                      order.status === "Paid" ? "text-active bg-light-green fs-12 badge-cs" :
                      order.status === "Refund" ? "text-brand bg-light-blue fs-12 badge-cs" :
                      order.status === "Partial Refund" ? "text-inactive bg-light-red fs-12 badge-cs" :
                      order.status === "Hold" ? "text-inactive bg-light-red fs-12 badge-cs" :
                      "text-brand bg-secondary-color fs-12 badge-cs" 
                    } 
                  >
                    {order?.status}
                  </Text>
                </Space>
                <Space>
                  <Button className='btncancel px-3 py-1' onClick={()=>{}}>
                      <Space>
                          <Image src='/assets/icons/print.png' preview={false} width={16} />
                          <Text className='text-black fs-13'>Print</Text>
                      </Space>
                  </Button>
                </Space>
              </Flex>
            </Col>

            <Col span={24}>
              <Card className="shadow-d radius-12 card-cs">
                  <Table
                    size="large"
                    columns={orderColumns}
                    dataSource={data}
                    className="table-dark pagination"
                    scroll={{x: 600}}
                    showSorterTooltip={false}
                    pagination={false}
                    rowHoverable={false}
                    showHeader={false}
                  />
                  <Form
                    form={form}
                    layout="vertical"
                  >
                    <Row className="pt-3">
                      <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput 
                          textArea
                          label='Note'
                          size='large'
                          name='note'
                          value={form.getFieldValue('note') || ''}
                        />
                      </Col>
                    </Row>
                  </Form>
              </Card>
            </Col>
            <Col span={24}>
              <Card className="shadow-d radius-12 card-cs">
                  <Table
                    size="large"
                    columns={orderColumns}
                    dataSource={priceData}
                    className="table-dark pagination"
                    showSorterTooltip={false}
                    pagination={false}
                    rowHoverable={false}
                    showHeader={false}
                  />
                </Card>
            </Col>
            <Col span={24}>
              <OrderProductTable order={order} />
            </Col>
            {order.status !=="Hold" && <Col span={24}>
              <OrderPaymentTable order={order} />
            </Col>}
          </Row>
    </div>
  );
};

export { ViewOrders };
