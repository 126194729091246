import { Button, Card, Checkbox, Col, Divider, Form, Image, message, Row, Space, Tooltip, Typography } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import React, { useState } from 'react'
import { MyInput, MySelect } from '../../../Forms'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ReceiptSetting = () =>{

  const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    const modules = {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Header options from h1 to h6
          ['bold', 'italic', 'underline', 'strike'], // Text formatting options
          [{ 'list': 'ordered' }, { 'list': 'bullet' }], // List options
          ['link', 'image', 'video'], // Media options
          ['clean'], // Remove formatting button
          [{ 'color': [] }, { 'background': [] }], // Color options
          ['undo', 'redo'], // Undo and redo options
        ],
      };

    const props = {
        name: 'image',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <div>
      <Card className='shadow-d radius-12 card-cs'>
      <Form
        form={form}
        layout="vertical"
        initialValues={true}
        // onFinish={onFinish}
        requiredMark={false}
        className='content-modal'
      >
          <Row gutter={16}>
              <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item name='image' >
                      <div className='receipt-drag'>
                          <Dragger {...props} className="dragcs">
                              <p className="ant-upload-drag-icon mb-0">
                                  <Image src='/assets/icons/plus.png' width={'41.6px'} preview={false} />
                              </p>
                              <Typography.Text className="fs-14 fw-500">Upload Image</Typography.Text>
                          </Dragger>
                      </div>
                  </Form.Item>
              </Col>
              <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                  <MySelect
                      label={<Space>
                        Print Language
                        <Tooltip title='Select a language or multiple languages for the receipt printer.'>
                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                        </Tooltip>
                      </Space>}
                      name="print" 
                      size={'large'}
                      mode='multiple'
                      required
                      message='Please choose print Language'
                      value={form.getFieldValue("print") || ''}
                      options={[
                          {
                              key: 1,
                              name: 'Main'
                          },
                          {
                              key: 2,
                              name: 'Localized'
                          },
                          {
                            key: 3,
                            name: 'Main + Localized'
                          },
                          
                      ]}
                  />
              </Col>
              <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                  <MySelect
                      label={<Space>
                        Main Language
                        <Tooltip title='Select the main language to be used when printing receipt.'>
                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                        </Tooltip>
                      </Space>}
                      name="main" 
                      size={'large'}
                      mode='multiple'
                      required
                      message='Please choose main Language'
                      value={form.getFieldValue("main") || ''}
                      options={[
                          {
                              key: 1,
                              name: 'Arabic'
                          },
                          {
                              key: 2,
                              name: 'English'
                          },
                      ]}
                  />
              </Col>
              <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                  <MySelect
                      label={<Space>
                        Localized Language
                        <Tooltip title='Select the localized language to be used when printing receipt.'>
                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                        </Tooltip>
                      </Space>}
                      name="local" 
                      size={'large'}
                      mode='multiple'
                      required
                      message='Please choose localized Language'
                      value={form.getFieldValue("local") || ''}
                      options={[
                          {
                              key: 1,
                              name: 'Arabic'
                          },
                          {
                              key: 2,
                              name: 'English'
                          },
                      ]}
                  />
              </Col>
              <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                  <MyInput 
                      label={<Space>
                        Invoice Title
                        <Tooltip title='You can enter text here to be printed as title in invoice'>
                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                        </Tooltip>
                      </Space>}
                      name='invoice'
                      size='large'
                      required
                      message='Please choose Invoice Title'
                      value={form.getFieldValue('invoice') || ''}
                  />
              </Col>
              <Col span={24}>
                  <Form.Item 
                    label={<Space>
                    Receipt Header
                    <Tooltip title='You can  enter text here to show at the header of the receipts.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                    </Space>}
                    name='receiptheader' 
                    value={form.getFieldValue('receiptheader') || ''}
                   >
                    <ReactQuill  theme="snow" modules={modules} className="custom-quill" />
                  </Form.Item>
              </Col>
              <Col span={24}>
                  <Form.Item 
                    label={<Space>
                    Receipt Footer
                    <Tooltip title='You can enter text here to show at the footer of the receipts.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                    </Space>}
                    name='receiptfooter' 
                    value={form.getFieldValue('receiptfooter') || ''}
                   >
                    <ReactQuill  theme="snow" modules={modules} className="custom-quill" />
                  </Form.Item>
              </Col>
              <Col span={24}>
                  <Checkbox>
                      <Space>
                      Show Order Number
                          <Tooltip title='Show or hide order number from the receipt'>
                              <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                          </Tooltip>
                      </Space>
                  </Checkbox>   
              </Col>
              <Col span={24}>
                  <Checkbox>
                      <Space>
                      Show Sub Total
                          <Tooltip title={`check this to show the receipt's sub-total (before taxes) with the final price.`}>
                              <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                          </Tooltip>
                      </Space>
                  </Checkbox>   
              </Col>
              <Col span={24}>
                  <Checkbox>
                      <Space>
                      Show Cashier Name
                          <Tooltip title='Check this to show the order creator (cashier) name in the receipt.'>
                              <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                          </Tooltip>
                      </Space>
                  </Checkbox>   
              </Col>
              <Divider />
              <Space style={{justifyContent:"end"}} className="w-100">
              <Button
                  onClick={()=>{}}
                  className='btncancel'
              >
                  Cancel
              </Button>
              <Button  
                  block
                  type="primary"
                  className='btnsave text-white brand-bg'
                  loading={loading}
                  onClick={()=>{
                      // DeleteProductApi();  
                  }}
                  
              >
                  Save
              </Button>
          </Space>
          </Row>
      </Form>

      </Card>
    </div>
  )
}

export {ReceiptSetting}






