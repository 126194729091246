import { Button, Card, Col, Divider, Form, Image, Row, Space, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { MyInput, MySelect } from '../../Forms'
import { ModuleTopHeading } from '../../PageComponents'
const {Title} = Typography

const AccountMyProfile = () => {
  const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
  return (
    <div>
        <Row gutter={[24, 24]} align={'middle'}>
            <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
                <Title level={5} className='m-0'>My Profile</Title>
            </Col>
            <Col span={24}>
            <Card className='shadow-d radius-12 card-cs py-2 px-2 h-100'>
              <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
              >
                  <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label="Name"
                              name='name'
                              size='large'
                              required
                              value={form.getFieldValue('name') || ''}
                          />
                      </Col>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label="Phone Number"
                              name='number'
                              size='large'
                              type="number"
                              required
                              value={form.getFieldValue('number') || ''}
                          />
                      </Col>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label="Email Address"
                              name='email'
                              size='large'
                              type="email"
                              required
                            
                              value={form.getFieldValue('email') || ''}
                          />
                      </Col>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MySelect
                              label="Language"
                              name="language" 
                              size={'large'}
                              mode='multiple'
                              required
                              message='Please choose Language'
                              value={form.getFieldValue("language") || ''}
                              options={[
                                  {
                                      key: 1,
                                      name: 'Arabic'
                                  },
                                  {
                                      key: 2,
                                      name: 'English'
                                  },
                              ]}
                          />
                      </Col>
              
                      
                      <Divider />
                      <Space style={{justifyContent:"end"}} className="w-100">
                      <Button
                          onClick={()=>{}}
                          className='btncancel'
                      >
                          Reset
                      </Button>
                      <Button 
                          block
                          type="primary"
                          className='btnsave text-white brand-bg'
                          loading={loading}
                          onClick={()=>{
                              // DeleteProductApi();  
                          }}
                          
                      >
                          Save
                      </Button>
                  </Space>
                  </Row>
              </Form>

              </Card>
            </Col>
        </Row>
    </div>
  )
}

export {AccountMyProfile}