import React, { useCallback, useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Flex, Image, Row, Space, Switch, Table, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { userData } from '../../../../Data';
import { AddUsers, UserNotification, UserPasswordChange } from '../UserModals';
import { UserRoleTable } from '../UserRoleTable';
import { UserBranchTable } from '../UserBranchTable';
// import { AddModifierOption, InactiveBranch } from '../ModifierOptionModals';

const { Text } = Typography
const ViewSingleUser = () => {
    let { userId } = useParams()
    const navigate = useNavigate()
    const [ visible, setvisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ changepassword, setChangePassword ] = useState(false)
    const [ notification, setNotification ] = useState(false)
    const user = userData.find((mod)=>mod.key == userId)    

    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            user: <Space direction='vertical' size={2}>
                <Text className='text-gray'>User Name</Text>
                <Text>{user?.username}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Email Address</Text>
                <Text>{user?.email}</Text>
            </Space>,
        },
        {
            key: '2',
            user: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Phone Number</Text>
                <Text>+966 678 543 624</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Language</Text>
                <Text>Arabic</Text>
            </Space>,
        },
        {
            key: '3',
            user: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Employee Number</Text>
                <Text>87435</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Display Localized Name</Text>
                <Text>Yes</Text>
            </Space>,
        },
        {
            key: '3',
            user: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Last Console Login</Text>
                <Text>October 22, 10:38am</Text>
            </Space>
        },
    ];

    
  

    return (
        <div>
            <Breadcrumb className='mb-3'>
                <Breadcrumb.Item>Setup Page</Breadcrumb.Item>
                <Breadcrumb.Item className='text-black' onClick={() => navigate("/users")}>
                    Users
                </Breadcrumb.Item>
                <Breadcrumb.Item className='fw-500'>{user?.username}</Breadcrumb.Item>
            </Breadcrumb>
            <Card className='shadow-d radius-12 border0 mb-3'>
                <Row gutter={[24, 24]}>
                    <Col lg={{span: 6}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Space>
                            <Button className='border0 p-0 bg-transparent' onClick={()=>navigate('/users')}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Text className='fw-500'>{user.username}</Text>
                        </Space>
                    </Col>
                    <Col lg={{span: 18}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Space className='mbl-space w-100 right' wrap>
                            <Button className='btncancel px-3 bg-light-gray mbl-btn' onClick={()=>{setNotification(true)}}>
                                <Flex justify='space-between' className='w-100' gap={10}>
                                    <Image src='/assets/icons/notify.png' preview={false} width={18} />
                                    <Text className='text-black fs-13'>Notifications</Text>
                                </Flex>
                            </Button>
                            <Button className='btncancel px-3 bg-light-gray mbl-btn' onClick={()=>{setChangePassword(true)}}>
                                <Flex justify='space-between' className='w-100' gap={10}>
                                    <Image src='/assets/icons/pass-ic.png' preview={false} width={18} />
                                    <Text className='text-black fs-13'>Change Password</Text>
                                </Flex>
                            </Button>
                            <Button className='btncancel px-3 bg-light-gray mbl-btn' onClick={()=>{setvisible(true);setEditItem('hello')}}>
                                <Flex justify='space-between' className='w-100' gap={10}>
                                    <Image src='/assets/icons/edit.png' preview={false} width={18} />
                                    <Text className='text-black fs-13'>Edit  User</Text>
                                </Flex>
                            </Button>
                        </Space>
                    </Col>  
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            showSorterTooltip={false}
                            rowHoverable={false}
                            showHeader={false}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </Card>
            <UserRoleTable />
            <UserBranchTable />
            <AddUsers 
                visible={visible}
                edititem={edititem}
                onClose={()=>{setvisible(false);setEditItem(null)}}
            />
            <UserPasswordChange 
                visible={changepassword}
                onClose={()=>setChangePassword(false)}
            />
            <UserNotification 
                visible={notification}
                onClose={()=>setNotification(false)}
            />
        </div>
    )
}

export { ViewSingleUser }
