import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography, Col, Row } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { DownOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput, MySelect, MyselectFix } from '../../Forms';

const { Title } = Typography

const HourlySaleBarChart = () => {
  const [form] = Form.useForm();

const chartData = {
  series: [
    {
      name: '11 April - 2024',
      data: [90, 65, 100, 110, 250, 200, 150, 350, 170, 200, 370 ],
    },
    {
      name: '10 April - 2024',
      data: [130, 225, 122, 308, 150, 140, 300,180, 170, 200, 230 ],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius:5,
        columnWidth: '50%',
        
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: [
        '12 am',
        '03 am',
        '06 am',
        '09 am',
        '12 pm',
        '03 pm',
        '06 pm',
        '09 pm',
        '11 pm',
        '03 pm',
      ],
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 400,
      tickAmount: 5,
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    colors: ['#0086FF','#F56C89'],
    legend:{
      markers:{
        shape: "circle"
      }
    }   
  },
};


  return (
    <div>
      <Card className='shadow-d radius-12 card-cs'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <ModuleTopHeading name='Hourly Sales' />
          <Form form={form} layout="vertical">
            <Flex justify='end' gap={10}>
              <Button className='btncancel fs-13 pad-filter'>
                View Report    
              </Button>
            </Flex>
          </Form>
        </Flex>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={300}
        />
      </Card>
    </div>
  );
};

export { HourlySaleBarChart };
