import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Tooltip, Image, Checkbox } from 'antd'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const UserNotification = ({visible,onClose,removenotify}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    const [checkedList, setCheckedList] = useState([]);
    const options = [
        'adjustment', 
        'production', 
        'transfer order', 
        'received', 
    ];
    const [checkAll, setCheckAll] = useState(false);
  
    const onChange = (item, checked) => {
      const updatedList = checked
        ? [...checkedList, item]
        : checkedList.filter((value) => value !== item);
  
      setCheckedList(updatedList);
      setCheckAll(updatedList.length === options.length);
    };
  
    const onCheckAllChange = (e) => {
      setCheckedList(e.target.checked ? options : []);
      setCheckAll(e.target.checked);
    };
    const indeterminate = checkedList.length > 0 && checkedList.length < options.length;
    
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Save
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>
                    {
                        removenotify ? 'Edit Notifications' : 'Add Notifications'
                    }
                </Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <div>
                            <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate}>Select All</Checkbox>
                        </div>
                        <Space direction='vertical' size={10} className='pt-3 px-3'>
                            <Checkbox
                                checked={checkedList.includes('adjustment')}
                                onChange={(e) => onChange('adjustment', e.target.checked)}
                                >
                                User submits a cost adjustment transaction.
                            </Checkbox>
                            <Checkbox
                                checked={checkedList.includes('production')}
                                onChange={(e) => onChange('production', e.target.checked)}
                                >
                                User submits a production transaction.
                            </Checkbox>
                            <Checkbox
                                checked={checkedList.includes('transfer order')}
                                onChange={(e) => onChange('transfer order', e.target.checked)}
                                >
                                User submits a transfer order for review.
                            </Checkbox>
                            <Checkbox
                                checked={checkedList.includes('received')}
                                onChange={(e) => onChange('received', e.target.checked)}
                                >
                                There is a transfer transaction waiting to be received
                            </Checkbox>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {UserNotification}