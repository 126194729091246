import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Divider, Image, Tooltip, Card } from 'antd'
// import { apiCalls } from '../../../shared/Apis';
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../../PageComponents/ModuleTopHeading';
import Dragger from 'antd/es/upload/Dragger';

const { Text } = Typography

const ModifierImport = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    

    const data = [
        {
            title:'REQUIRED COLUMNS',
            listitem:[
                {
                    title:'Modifier ID',
                    desc:'The unique identifier for the modifier, you can get it by exporting the modifiers list'
                },
                {
                    title:'Modifier Name',
                    desc:'The name of the modifier'
                },
                {
                    title:'Modifier Reference',
                    desc:'The reference key for the modifier'
                },
                {
                    title:'Modifier Localized Name',
                    desc:'The localized name for the modifier'
                },
            ]
        },
    ]

  return (
    <div>
        <Modal 
            width={1000} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={null}
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Upload a file</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <div className='content-modal'>
                <Row gutter={[16, 16]}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Flex vertical gap={30}>
                            <Space className='brand-light-bg px-3 py-3 radius-8'>
                                <Image src='/assets/icons/cir-i-blue.png' width={20} preview={false} />
                                <Text className='fs-13'>Make sure your file includes the following required columns</Text>
                            </Space>
                            <div>
                            {
                                data?.map((requireditem,index)=>
                                    <>
                                        <Flex vertical gap={10} key={index}>
                                            <Text strong>{requireditem?.title}</Text>
                                            {
                                                requireditem?.listitem?.map((item,i)=>
                                                    <Flex justify='space-between' align='center' key={i}>
                                                        <Text>{item?.title}</Text>
                                                        <Tooltip title={item?.desc}>
                                                            <Image src='/assets/icons/cir-i-gray.png' width={20} preview={false} />
                                                        </Tooltip>
                                                    </Flex>
                                                )
                                            }
                                        </Flex>
                                    </>
                                )
                            }
                            </div>
                            <Button  
                                block
                                type="primary"
                                className='btnsave pad text-white brand-bg'
                                loading={loading}
                                onClick={()=>{
                                    // DeleteProductApi();  
                                }}
                                
                            >
                                Download the template
                            </Button>
                        </Flex>
                    </Col> 
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Card className='h-100 brand-light-bg card-h border0 radius-8'>
                            <Dragger className='drager'>
                                <Flex vertical  gap={5} align='center' justify='center' className=' h-100 position-relation'>
                                    <Image src='/assets/icons/import-icons.png' width={85} preview={false} />
                                    <Image src='/assets/icons/plus-color-cr.png' preview={false} width={24} className='overlap-img' />
                                    <Text strong>Upload a CSV or Excel file to begin import</Text>
                                </Flex>
                            </Dragger>
                        </Card>
                    </Col>       
                </Row>
            </div>
        </Modal>
    </div>
  )
}

export {ModifierImport}