import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Modal, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Tabs } from "antd";

const DraggableCol = ({ className, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props["data-node-key"],
    });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move", 
  };
  return (
    <Col
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="center shadow-c radius-12"
    >
      {props.children}
    </Col>
  );
};

const { Text } = Typography;
const PaymentMethodSorter = ({ visible, onClose }) => {
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([
    {
        key: "1",
        label: "Payment .M1",
    },
    {
        key: "2",
        label: "Payment .M2",
    },
    {
        key: "3",
        label: "Payment .M3",
    },
    {
        key: "4",
        label: "Payment .M4",
    },
  ]);
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setItems((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <Modal
      width={600}
      title={null}
      className="shadow-c modal-cs"
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      closeIcon={false}
      centered
      footer={
        <Space
          style={{ justifyContent: "center"}}
          className="w-100"
        >
          <Button onClick={onClose} className="btncancel">
            Cancel
          </Button>
          <Button
            block
            type="primary"
            className="btnsave text-white brand-bg"
            loading={loading}
            onClick={() => {
              // DeleteProductApi();
            }}
          >
            Apply
          </Button>
        </Space>
      }
    >
      <Flex align="center" justify="space-between" className="header-modal">
        <Text strong>Sort Modifiers</Text>
        <Button className="bg-transparent border0 p-0 " onClick={onClose}>
          <CloseOutlined className="text-gray" />
        </Button>
      </Flex>
      <Flex className="content-modal">
        <DndContext
          sensors={[sensor]}
          onDragEnd={onDragEnd}
          collisionDetection={closestCenter}
        >
          <SortableContext
            items={items.map((i) => i.key)}
            strategy={horizontalListSortingStrategy}
          >
            <Flex gap={15} wrap>
                {items.map((item) => (
                  <DraggableCol
                    key={item.key}
                    data-node-key={item.key}
                    style={{
                      gap: "4px",
                      flexDirection: "column",
                      width: "120px",
                      height: "65px",
                      border: "1px solid #F5F5F5",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Button className="border0 p-0 bg-transparent ">
                      <Typography.Text className="fs-14">
                        {item.label}
                      </Typography.Text>
                    </Button>
                  </DraggableCol>
                ))}
              </Flex>
          </SortableContext>
        </DndContext>
      </Flex>
    </Modal>
  );
};

export { PaymentMethodSorter };
