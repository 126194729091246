import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row, Space, Typography } from 'antd'

const { Text, Title } = Typography
const CardsSingleView = () => {
    const [form] = Form.useForm();

    const data = [
        {
            title:'Modifiers',
            btntext:'Add Modifier',
            desc:'Add modifiers to this product and configure the minimum and maximum number of allowed modifiers, and more.'
        },
        {
            title:'Custom Price',
            btntext:'Select Branch',
            desc:'Set a different price for this product in the branches you select here.'
        },
        {
            title:'Inactive in Branches',
            btntext:'Select Branch',
            desc:'Deactivate this product in the branches you select here to hide it from the menu.'
        },
    ]

    return (
        <div className='my-3'>
            {
                data?.map((carddata,index)=>
                    <Card className='shadow-c radius-12 border0 cardlg mb-3' key={index}>
                        <Flex vertical gap={20}>
                            <Space style={{justifyContent:'space-between'}} className='w-100'>
                                <Title level={5} className='m-0'>{carddata?.title}</Title>
                                <Button 
                                    type="primary"
                                    className='btnsave text-white brand-bg'>
                                    {carddata?.btntext}
                                </Button>
                            </Space>
                            <Text className='text-gray fs-13 text-center'>
                                {
                                    carddata?.desc
                                }
                            </Text>
                        </Flex>
                    </Card>
                )
            }
        </div>
    )
}

export { CardsSingleView }

