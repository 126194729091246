import React, { useState } from 'react'
import { Button, Dropdown, Space, Table, Typography } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { userData } from '../../../../Data'
import { DownOutlined } from '@ant-design/icons'

const { Text } = Typography

const DeleteduserTable = ({ setAddNotification, setRemoveNotify, setEditItem, setAddRole, setAddBranch, setRemoveText  }) => {

    const deletedatas = userData.filter((del) => del.id === 'delete')
    const [selectedRows, setSelectedRows] = useState([]);
    const navigate = useNavigate()
    const columns = [
        {
            title: 'User Name',
            dataIndex: 'username',
            sorter: (a,b) => a.username - b.username,
            render: (username) => <Space>{username} <Typography.Text className='text-inactive bg-light-red fs-12 badge-cs'>
                    Deleted
            </Typography.Text></Space>,
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
        },
        {
            title: 'Console Access',
            dataIndex: 'console',
            width:200,
            render: (console) => {
                return (
                    console == 'Active' ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            },
        },
        {
            title: 'Cashier Access',
            dataIndex: 'cashier',
            width:200,
            render: (cashier) => {
                return (
                    cashier == 'Active' ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            },
        },
        {
            title: 'Role',
            dataIndex: 'role',
        },
    ];
   
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
      };

  return (
    <div>
        
        <Table 
            size='large'
            columns={columns} 
            dataSource={deletedatas} 
            scroll={{x: 1200}}
            className='table-dark pagination'
            showSorterTooltip={false}
            rowSelection={rowSelection}
            rowHoverable={true}
            onRow={(record) => ({
                onClick: (event) => {
                    const isActionColumn = event.target.closest('.action-column');
                    if (!isActionColumn && record.key) {
                        navigate(`/users/singleview/${record.key}`);
                        setEditItem(record?.id);
                    }
                },
            })}
            title={ selectedRows.length > 0 ? (record) => (
                <div>{selectedRows.length} Selected  
                    <Dropdown
                        menu={{ items: [
                            { label: <Link onClick={()=>{ setAddRole(true) }} className='fs-13 action-column'>Add Roles</Link>, key: 0 },
                            { label: <Link onClick={()=>{ setAddRole(true); setRemoveText(true) }} className='fs-13 action-column'>Remove Roles</Link>, key: 1 },
                            { label: <Link onClick={()=>{ setAddBranch(true) }} className='fs-13 action-column'>Add Branches</Link>, key: 2 },
                            { label: <Link onClick={()=>{ setAddBranch(true); setRemoveText(true) }} className='fs-13 action-column'>Remove Branches</Link>, key: 3 },
                            { label: <Link onClick={()=>{ setAddNotification(true) }} className='fs-13 action-column'>Add Notifications</Link>, key: 4 },
                            { label: <Link onClick={()=>{ setAddNotification(true);setRemoveNotify(true) }} className='fs-13 action-column'>Remove Notifications</Link>, key: 5 },
                            { label: <Link onClick={()=>{ }} className='fs-13 action-column'>Restore</Link>, key: 6 }
                        ] }}
                        trigger={['click',]}
                        className='margin-top'
                    >
                        <Button className='btncancel px-2 py-1 bg-light-gray mx-2'>
                            <Space>
                                <Text className='text-black fs-13'>Actions</Text>
                                <DownOutlined /> 
                            </Space>
                        </Button>
                    </Dropdown>
                </div> 
            ) : null}
            pagination={{
                hideOnSinglePage: true,
                total: 12,
                // pageSize: pagination?.pageSize,
                // defaultPageSize: pagination?.pageSize,
                // current: pagination?.pageNo,
                // size: "default",
                // pageSizeOptions: ['10', '20', '50', '100'],
                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
            }}
        />
    </div>
  )
}

export {DeleteduserTable}