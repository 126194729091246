import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Divider } from 'antd'
import { apiCalls } from '../../../shared/Apis';
import { MySelect } from '../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const FilterModal = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    // const DeleteProductApi = async() => {
    //     setLoading(true)
    //     try{
    //         const result = await apiCalls.vendorApi.DeleteProductApi(deletevendor?.id)
    //         setLoading(false)
    //         onClose();
    //         getVendorApplications();
    //     } catch (error){
    //         console.error("Error deleting data",error)
    //     }
    // }
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Filter</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row>
                    <Col span={24}>
                        <MySelect
                            label= 'Branch Name'
                            name="branch" 
                            mode='multiple'
                            size={'large'}
                            value={form.getFieldValue("branch") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Branch 01'
                                },
                                {
                                    key: 2,
                                    name: 'Branch 02'
                                },
                                {
                                    key: 3,
                                    name: 'Branch 01 (B01)'
                                },
                                {
                                    key: 4,
                                    name: 'Branch 03'
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {FilterModal}