import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Image, Tooltip, Input, Checkbox } from 'antd'
import { MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const DevicesFilter = ({visible,onClose})=> {
  const [ loading, setLoading ] = useState(false)
  const [ randomvalue, setRandomValue ] = useState(null)
  const [form] = Form.useForm()
  
  const GenerateValue = () => {
      setRandomValue(
          Math.floor(Math.random()*90000) + 10000
      )
  }
  return (

    <div>
        <Modal 
            width={600} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Filter</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label="Device Name" 
                            size='large'
                            name='devicename'
                            required
                            message='Please enter Device name'
                            value={form.getFieldValue('devicename') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label="Reference" 
                            size='large'
                            name='ref'
                            required
                            message='Please enter Reference'
                            value={form.getFieldValue('ref') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label="Code" 
                            size='large'
                            name='code'
                            required
                            message='Please enter Code'
                            value={form.getFieldValue('code') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Type'
                            name="type" 
                            size={'large'}
                            mode='multiple'
                            required
                            message='Please choose type'
                            value={form.getFieldValue("type") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Cashier'
                                },
                                {
                                    key: 2,
                                    name: 'Display'
                                },
                            ]}
                        />
                    </Col> 
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Branch'
                            name="branch" 
                            size={'large'}
                            mode='multiple'
                            required
                            message='Please enter branch'
                            value={form.getFieldValue("branch") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Branch 1 (B01)'
                                },
                                {
                                    key: 2,
                                    name: 'Branch 2 (B02)'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'In Use'
                            name="inuse" 
                            size={'large'}
                            required
                            message='Please Choose Yes/No'
                            value={form.getFieldValue("inuse") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Yes'
                                },
                                {
                                    key: 2,
                                    name: 'No'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Online'
                            name="online" 
                            size={'large'}
                            required
                            message='Please Choose Yes/No'
                            value={form.getFieldValue("online") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Yes'
                                },
                                {
                                    key: 2,
                                    name: 'No'
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>

  )
}

export {DevicesFilter}




    
  
