import React, { useCallback, useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Flex, Image, Row, Space, Switch, Table, Typography } from 'antd'
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { modifieroptionallData } from '../../../../Data';
import { AddModifierOption, InactiveBranch } from '../ModifierOptionModals';
import InactiveBranchTable from './InactiveBranchTable';
import { AlertModal } from '../../../Modals';

const { Text } = Typography
const ViewSingleModifierOption = ({deletedata}) => {
    let { modOptionId } = useParams()
    const navigate = useNavigate()
    const [ modifieroptionvisible, setModifierOptionVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ branchmodal, setBranchModal ] = useState(false)
    const [ editbranch, setEditBranch ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const modifier = modifieroptionallData.find((mod)=>mod.key == modOptionId)
    const [ statustext, setStatusText ] = useState(modifier.status)

    // const onChange = (checked) => {
    //     setStatusText(checked ? 'Active' : 'Inactive')
    // }
    console.log(deletedata)
    const columns = [
        {
            title: 'Modifier Option',
            dataIndex: 'modifieroption',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];
   

    const data = [
        {
            key: '1',
            modifieroption: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Modifier Option Name</Text>
                <Text>{modifier?.optionname}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Modifier name</Text>
                <Text>{modifier?.name}</Text>
            </Space>,
        },
        {
            key: '2',
            modifieroption: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Category Name</Text>
                <Text>صغير</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>SKU</Text>
                <Text>{modifier.sku}</Text>
            </Space>,
        },
        {
            key: '3',
            modifieroption: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Tax Group</Text>
                <Text>{modifier?.taxgroup || "-"}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Price</Text>
                <Text>{modifier?.price || "-"}</Text>
            </Space>,
        },
        {
            key: '3',
            modifieroption: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Cost</Text>
                <Text>{modifier?.price || "-"}</Text>
            </Space>,
        },
    ];
    
  

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Breadcrumb className='mb-3'>
                        <Breadcrumb.Item>Menu</Breadcrumb.Item>
                        <Breadcrumb.Item className='text-black' onClick={() => navigate("/modifieroption")}>
                            Modifiers Options
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className='fw-500'>{modifier?.optionname}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col lg={{span:6}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <Space>
                        <Button className='border0 p-0 bg-transparent' onClick={()=>navigate('/modifieroption')}>
                            <ArrowLeftOutlined />
                        </Button>
                        <Text>{modifier.optionname}</Text>
                        <Text className={
                            deletedata === true ? 
                                'text-inactive bg-light-red fs-12 badge-cs':
                                modifier.status === 'Active' ? 
                                'text-active bg-light-green fs-12 badge-cs':
                                'text-inactive bg-light-red fs-12 badge-cs'
                        }>
                            {
                                deletedata === true ? 'Deleted' : modifier?.status
                            }
                        </Text>
                    </Space>
                </Col>
                <Col lg={{span:18}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <Space className='mbl-space w-100 right' wrap>
                        {/* <Space>
                            <Switch size='small' checked={statustext === 'Active'} onChange={onChange} />
                            <Text className={
                                statustext === 'Active' ? 
                                'text-active fs-12':
                                'text-inactive fs-12'
                            }>
                                {statustext}
                            </Text>
                        </Space> */}
                        <Button className='btncancel px-2 py-1 mbl-btn' onClick={()=>{}}>
                            <Flex justify='space-between' className='w-100' gap={10}>
                                <CloseOutlined/>
                                <Text className='text-black fs-13'>Inactive Modifier Option</Text>
                            </Flex>
                        </Button>
                        <Button className='btncancel px-2 py-1 mbl-btn' onClick={()=>{setModifierOptionVisible(true);setEditItem('hello')}}>
                            <Flex justify='space-between' className='w-100' gap={10}>
                                <Image src='/assets/icons/edit.png' preview={false} width={18} />
                                <Text className='text-black fs-13'>Edit Modifier Option</Text>
                            </Flex>
                        </Button>
                    </Space>
                </Col>
                <Col span={24}>
                <Card className='shadow-d radius-12 card-cs'>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        pagination={false}
                        showHeader={false}
                    />
                </Card>
                </Col>
                <Col span={24}>
                    <InactiveBranchTable {...{branchmodal,setBranchModal,editbranch, setEditBranch}} />
                </Col>
            </Row>
            <AddModifierOption 
                visible={modifieroptionvisible}
                edititem={edititem}
                setDeleteItem={setDeleteItem}
                onClose={()=>{setModifierOptionVisible(false);setEditItem(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                onClose={()=>{setDeleteItem(false)}}
            />
            <InactiveBranch 
                visible={branchmodal}
                editbranch={editbranch}
                onClose={()=>{setBranchModal(false);setEditBranch(null)}}
            />
        </div>
    )
}

export { ViewSingleModifierOption }
