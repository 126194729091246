import { Button, Card, Col, Flex, Image, Row, Space, Table, Typography } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ModifierPorducts, ProductActionModal, UpdateProductModifier } from '../ProductModals';

const { Text, Title } = Typography

const ProductModifier= ()=> {

  const [modifier, setModifier] = useState(false)
  const [copyModifier, setCopyModifier] = useState(false)
  const [updateModifier, setUpdateModifier] = useState(false)
  const navigate = useNavigate();

  const options =[
    { id: 1, name: 'size' },
    { id: 2, name: 'Option 2' },
    { id: 3, name: 'Option 3' }
  ]

  const modifierColumns = [
    {
        title: 'Modifier name',
        dataIndex: 'modifier',
        
    },
    {
      title: 'Minimum Options',
      dataIndex: 'miniOptions',
    },
    {
      title: 'Maximum Options',
      dataIndex: 'maxOptions',
    },
   
  ];
  
  const modifierData = [
    {
        key: '1',
        modifier:"Milk",
        miniOptions: 1,
        maxOptions: 1,
   
    },
    {
      key: '1',
      modifier:"Cheese",
      miniOptions: 1,
      maxOptions: 1,
  },
  ];

  return (
    <div className='my-3'>
        <Row gutter={[16,16]}>
        <Col span={24}>
            <Flex justify='space-between' align='center' wrap className='w-100' gap={10}>
              <Title level={5} className='m-0'>Modifiers</Title>
              <Space  wrap>
                <Button className='btncancel px-2 py-1 mbl-btn' onClick={()=> navigate('/products/sortproductmodifiers') } >
                    <Space>
                        <Image src='/assets/icons/sort.png' preview={false} width={18} />
                        <Text className='text-gray'>Sort Modifiers</Text>
                    </Space>
                </Button>
                <Button className='btncancel px-2 py-1 mbl-btn' onClick={()=>{setModifier(true); setCopyModifier(true)}} >
                    <Space>
                        <Image src='/assets/icons/copy.png' preview={false} width={18} />
                        <Text className='text-gray'>Copy Modifiers</Text>
                    </Space>
                </Button>
                <Button className='btncancel px-2 py-1 mbl-btn' onClick={()=>{setModifier(true)}} >
                    <Space>
                        <Image src='/assets/icons/edit.png' preview={false} width={18} />
                        <Text className='text-gray'>Add Modifiers</Text>
                    </Space>
                </Button>
              </Space>
            </Flex>
        </Col>
        <Col span={24}>
          <Card className='shadow-d radius-12 card-cs mb-3' >
                  <Table 
                    size='large'
                    columns={modifierColumns} 
                    dataSource={modifierData} 
                    className='table-dark pagination'
                    showSorterTooltip={false}
                    rowHoverable={true}
                    pagination={false}
                    scroll={{
                      x: 1000
                    }}
                    onRow={(record) => ({
                      onClick: () => {
                        // Modal ko open karne ka function call
                        setUpdateModifier(true) // Assuming openModal is the function to trigger modal
                      }
                    })}
                  />

          </Card>
        </Col>
      </Row>
         <ModifierPorducts 
         visible={modifier}
         copyModifier={copyModifier}
         onClose={()=>{setModifier(false); setCopyModifier(null)}}
         />
        <UpdateProductModifier
          visible={updateModifier}
          onClose={()=>setUpdateModifier(false)}
          
        /> 
    </div>
  )
}

export {ProductModifier}