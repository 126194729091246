import { useState } from "react"
import { Form, Image, Button, Space, Row, Col, Typography, Flex, Checkbox } from "antd"
import {  MyInput, MySelect } from '../../components'
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"
import "../Login/index.css"
import { Link } from "react-router-dom"
import { businessType } from "../../shared"

const {Title, Text}= Typography

const FreeTrailForm = () => {

    // const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    // const { loading } = useSelector(state => state?.login)
    // const [forgotPassword, setForgotPassword]= useState(false)

    // const login = () => {
    //     if(forgotPassword)
    //         appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
    //     else
    //         appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))

    // }

    return (
        <Flex className="login-form-covers" justify="center" align="center">
            <div className="login-form mb-5">
                <div className="center login-form-left-side">
                    <Image 
                        src="/assets/images/white-logo.png" 
                        preview={false} 
                        alt="tag-dev-logo"
                    />
                    <div>
                        <Text strong className="py-3 text-white">
                            Version 0.0 Beta Release
                        </Text>
                    </div>
                </div>
                <div 
                    style={{alignItems: 'center', display:'flex'}}
                    className="login-form-right-side px-3 py-4"
                >
                    <Space 
                        direction='vertical' 
                        className='justify-center w-100'
                        size={10}
                    >
                        <Image 
                            src="/assets/images/logo-cr.png" 
                            width={'70px'} 
                            preview={false} 
                            alt="tag-dev-logo"
                        />
                        <Space direction="vertical">
                            <Title level={3} className="my-0">
                                Try TagDev free for 14 days!
                            </Title>
                        </Space>
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            style={{width: '100%'}}
                            // onFinish={login}
                            requiredMark={false}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <MyInput
                                        autoFocus
                                        name="fullName"
                                        label="Full Name"
                                        required
                                        size={'large'}
                                        message='Enter full name'
                                        placeholder='Enter full name'
                                        value={form.getFieldValue("fullName") || ''}
                                    />
                                </Col>
                                <Col span={12}>
                                    <MyInput
                                        name="email"
                                        label="Email Address"
                                        required
                                        size={'large'}
                                        message='Enter email address'
                                        placeholder='Enter email address'
                                        value={form.getFieldValue("email") || ''}
                                    />
                                </Col>
                                <Col span={12}>
                                    <MyInput
                                        name="password"
                                        type='password'
                                        label="Password"
                                        required
                                        size={'large'}
                                        message='Enter password'
                                        placeholder='Enter password'
                                        value={form.getFieldValue("password") || ''}
                                    />
                                </Col>
                                <Col span={12}>
                                    <MyInput
                                        name="businessName"
                                        label="Business Name"
                                        required
                                        size={'large'}
                                        message='Enter business name'
                                        placeholder='Enter business name'
                                        value={form.getFieldValue("businessName") || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <MySelect
                                        label='Business Type'
                                        name="businessType" 
                                        size={'large'}
                                        placeholder='Choose business type'
                                        required
                                        message='Choose business type'
                                        value={form.getFieldValue("businessType") || ''}
                                        options={businessType}
                                    />
                                </Col>
                                <Col span={24}>
                                    <MySelect
                                        label='Business Location'
                                        name="businessLocation" 
                                        size={'large'}
                                        placeholder='Choose business location'
                                        required
                                        message='Choose business location'
                                        value={form.getFieldValue("businessLocation") || ''}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Tabook'
                                            },
                                            {
                                                key: 2,
                                                name: 'Riyadh'
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Checkbox className="mb-3">Accept Terms & Conditions</Checkbox>
                                </Col>
                                <Col span={24} className="center">
                                    <Button 
                                        htmlType="submit" 
                                        size="large"
                                        block
                                        // loading={loading}
                                        className="fs-14 brand-bg text-white"
                                        >
                                        Try 14 Days Free Trial
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    <Flex justify="center" gap={3} className="py-1">
                                        <Text className="fs-13 text-gray">Already have an account?</Text>  
                                        <Link to={'/login'} className="fs-13 text-brand">
                                            Login
                                        </Link>
                                    </Flex>
                                </Col>
                            </Row>
                        </Form>
                    </Space>
                </div>
            </div>
        </Flex>
    )
}
export {FreeTrailForm}