import { Card, Space, Table, Typography } from 'antd'
import React from 'react'

const {Title ,Text} = Typography
const GeneralInfoTable =()=> {
  const columns = [
    {
        title: 'general',
        dataIndex: 'general',
    },
    {
        title: 'detail',
        dataIndex: 'detail',
    },
  ];
  const data = [
    {
        key: '1',
        general: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Account Package Type</Text>
            <Text>Starter</Text>
        </Space>,
        detail: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Account Creation Date</Text>
            <Text>September 07, 06:00am</Text>
        </Space>,
    },
    {
        key: '2',
        general: <Space direction='vertical' size={2}>
            <Text className='text-gray'>Account Creation Email</Text>
            <Text>ym_@hotmail.com</Text>
        </Space>,
        
    },
  ];
  return (
    <div>
      <div className='mb-3'>
          <Title level={5} className='m-0'>General Information</Title>
      </div>
      <Card className='shadow-d radius-12 card-cs'>
          <Table 
              size='large'
              columns={columns} 
              dataSource={data} 
              scroll={{x: 1000}}
              className='table-dark pagination'
              showSorterTooltip={false}
              rowHoverable={false}
              pagination={false}
              showHeader={false}
          />
      </Card>
    </div>
  )
}

export {GeneralInfoTable}