import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Tooltip, Image } from 'antd'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const AddEditTaxGroup = ({visible,onClose,edititem,setDeleteItem}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    const deleteHandle = () => {
        setDeleteItem({name: edititem})
        onClose()
    }
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Flex justify='space-between'>
                    {
                        edititem && 
                        <Button  
                            type="primary"
                            className='btnsave text-white bg-red'
                            onClick={deleteHandle}
                        >
                            Delete Tax
                        </Button>
                    }
                    <Space style={{justifyContent: edititem ? 'end' : 'center'}} className="w-100">
                        <Button
                            onClick={onClose}
                            className='btncancel'
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            className='btnsave text-white brand-bg'
                            loading={loading}
                            onClick={()=>{
                                // DeleteProductApi();  
                            }}
                            
                        >
                            {
                                edititem? 'Update' : 'Save'
                            }
                        </Button>
                    </Space>
                </Flex>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{edititem? 'Edit Tax Group':'Add Tax Group'}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Tax Group Name <span className='text-inactive'>*</span>
                                <Tooltip title='The name of the tax group'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            name='taxgroup'
                            size='large'
                            required
                            message='Please enter tax group name'
                            value={form.getFieldValue('taxgroup') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label= {<Space>
                                Name Localized
                                <Tooltip title='Enter the localized name of tax group in the second language of your own choice'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            name='namelocalize'
                            size='large'
                            value={form.getFieldValue('namelocalize') || ''}
                        />
                    </Col>                  
                    <Col span={24}>
                        <MySelect
                            label= {<Space>
                                Taxes
                                <Tooltip title='Choose the tax rates that belong to this group. A  group can have multiple tax rates & you will link branches and products to this group'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            name="taxes" 
                            mode='multiple'
                            size={'large'}
                            value={form.getFieldValue("taxes") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Tax 1'
                                },
                                {
                                    key: 2,
                                    name: 'Tax 2'
                                },
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label= {<Space>
                                Reference
                                <Tooltip title='A reference number or a code for reporting. The reference may only contains lowercase, numbers, dashes & underscores.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            name='ref'
                            size='large'
                            value={form.getFieldValue('ref') || ''}
                        />
                    </Col> 
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddEditTaxGroup}