import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { DownOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput } from '../../Forms';

const { Title } = Typography

const OrderTypeStackChart = () => {
  const [form] = Form.useForm();

  const chartData = {
    series: [{
      name: 'Pick-up Orders',
      data: [44, 55, 41, 67, 22, 43, 21,16,10,20,40,48,29,35,205,49,55,70]
    }, {
      name: 'Dine-in Orders',
      data: [13, 23, 20, 8, 13, 27, 33, 40, 55, 63,39, 43,49,150, 50,60,45,37]
    }, {
      name: 'Drive-thru Orders',
      data: [11, 17, 15, 15, 21, 14, 15, 25,35,45,55,65,75,43,300,48,17,40]
    }],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar:{
          show:false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius:5,
        }
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [
          '12 am',
          '01 am',
          '02 am',
          '03 am',
          '04 am',
          '05 am',
          '06 am',
          '07 am',
          '08 am',
          '09 am',
          '10 am',
          '12 pm',
          '01 pm',
          '02 pm',
          '03 pm',
          '04 pm',
          '05 pm',
          '06 pm',
        ],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 400,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      responsive: [{
        breakpoint: 480
      }],
      colors: ['#F56C89','#2BE3B7','#7B57E0'], 
      legend:{
        markers:{
          shape: "circle"
        }
      }     
    },
  };

  return (
    <div>
      <Card className='shadow-d radius-12 card-cs'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <ModuleTopHeading name='Order Types (compare)' />
          <Form form={form} layout="vertical">
            <Flex justify='end' gap={10}>
              <Button className='btnsave bg-secondary-color fs-13 pad-filter border0'>
                View Report    
              </Button>
            </Flex>
          </Form>
        </Flex>
        <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={300}
        />
      </Card>
    </div>
  );
};

export { OrderTypeStackChart };
