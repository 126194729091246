import React, { useState } from 'react'
import { Button, Card, Checkbox, Col, Dropdown, Form, Image, Row, Space, Table, Tooltip, Typography } from 'antd'
import { tillData } from '../../../../Data';

const { Text } = Typography
const TillsTable = ({setFilterModal}) => {
    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState([]);

    const options = [
        'Branch Name', 
        'Branch Reference', 
        'User', 
        'Opened At', 
        'Opening Amount',  
        'Net Cash Payment',
        'Total Pay-ins',
        'Total Pay-outs',
        'Total Cash Drops',
        'Closing Amount',
        'Expected Amount',
        'Variance',
        'Manual Drawer Opens',
        'Closed At'
    ];
    const [checkAll, setCheckAll] = useState(false);
  
    const onChange = (item, checked) => {
      const updatedList = checked
        ? [...checkedList, item]
        : checkedList.filter((value) => value !== item);
  
      setCheckedList(updatedList);
      setCheckAll(updatedList.length === options.length);
    };
  
    const onCheckAllChange = (e) => {
      setCheckedList(e.target.checked ? options : []);
      setCheckAll(e.target.checked);
    };
    const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

    const columns = [
        {
            title: <Space>
                <Text className='fw-500'>Business Date</Text>
                <Tooltip title='The business date when the till was opened/closed.'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'businessdate',
        },
        {
            title: <Space>
                <Text className='fw-500'>Branch Name</Text>
                <Tooltip title='The name of the branch.'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'branch',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Branch Reference</Text>
                    <Tooltip title='The reference of the branch.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'branchreference',
        },
        {
            title: <Space>
                    <Text className='fw-500'>User</Text>
                    <Tooltip title='The user who opened the till.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'user',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Opened At</Text>
                    <Tooltip title='The time when the till was opened.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'openedat',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Opening Amount</Text>
                    <Tooltip title='The amount entered by the user when opening.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'openingamount',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Net Cash Payment</Text>
                    <Tooltip title='Cash payments - Cash returns.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'netcashpayment',
        }, 
        {
            title: <Space>
                    <Text className='fw-500'>Total Pay-ins</Text>
                    <Tooltip title='Total of the pay-ins made by the user in this till.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'totalpayins',
        }, 
        {
            title: <Space>
                    <Text className='fw-500'>Total Pay-out</Text>
                    <Tooltip title='Total of the pay-out made by the user in this till.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'totalpayout',
        },      
        {
            title: <Space>
                    <Text className='fw-500'>Total Cash Drops</Text>
                    <Tooltip title='Total of the cash drop made by the user in this till.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'totalcashdrop',
        },  
        {
            title: <Space>
                    <Text className='fw-500'>Closing Amount</Text>
                    <Tooltip title='The amount entered by the user when closing.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'closingamount',
        },  
        {
            title: <Space>
                    <Text className='fw-500'>Expected Amount</Text>
                    <Tooltip title='The closing amount calculated by the system.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'expectedamount',
        },  
        {
            title: <Space>
                    <Text className='fw-500'>Variance</Text>
                    <Tooltip title='The difference between closing and expected amounts.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'variance',
            render: (variance) => {
                if(variance < 0){
                    return <Text className='text-inactive'>SAR {variance}</Text>
                } else{
                    return  <Text>SAR {variance}</Text>
                }
            }
        },  
        {
            title: <Space>
                    <Text className='fw-500'>Manual Drawer Opens</Text>
                    <Tooltip title='The number of times the drawer was opened manually.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'manualdraweropen',
        },  
        {
            title: <Space>
                    <Text className='fw-500'>Closed At</Text>
                    <Tooltip title='The actual time when the till was closed.'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'shiftduration',
        },  
    ]
 

    const dropdownContent = (
        <Card className='text-black radius-8 shadow-c card-cs overflow-on'>
            <div>
                <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate}>Select All </Checkbox>
            </div>
            <Space direction='vertical' size={10} className='pt-3'>
                <Checkbox checked disabled>User</Checkbox>
                <Checkbox
                    checked={checkedList.includes('Branch Name')}
                    onChange={(e) => onChange('Branch Name', e.target.checked)}
                    >
                    Branch Name
                </Checkbox>       
                <Checkbox
                    checked={checkedList.includes('Branch Reference')}
                    onChange={(e) => onChange('Branch Reference', e.target.checked)}
                    >
                    Branch Reference
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('user')}
                    onChange={(e) => onChange('user', e.target.checked)}
                    >
                    User
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Opened At')}
                    onChange={(e) => onChange('Opened At', e.target.checked)}
                    >
                    Opened At
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Opening Amount')}
                    onChange={(e) => onChange('Opening Amount', e.target.checked)}
                    >
                    Opening Amount
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Net Cash Payment')}
                    onChange={(e) => onChange('Net Cash Payment', e.target.checked)}
                    >
                    Net Cash Payment
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Total Pay-ins')}
                    onChange={(e) => onChange('Total Pay-ins', e.target.checked)}
                    >
                    Total Pay-ins
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Total Pay-outs')}
                    onChange={(e) => onChange('Total Pay-outs', e.target.checked)}
                    >
                    Total Pay-outs
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Total Cash Drops')}
                    onChange={(e) => onChange('Total Cash Drops', e.target.checked)}
                    >
                    Total Cash Drops
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Closing Amount')}
                    onChange={(e) => onChange('Closing Amount', e.target.checked)}
                    >
                    Closing Amount
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Expected Amount')}
                    onChange={(e) => onChange('Expected Amount', e.target.checked)}
                    >
                    Expected Amount
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Variance')}
                    onChange={(e) => onChange('Variance', e.target.checked)}
                    >
                    Variance
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Manual Drawer Opens')}
                    onChange={(e) => onChange('Manual Drawer Opens', e.target.checked)}
                    >
                    Manual Drawer Opens
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Closed At')}
                    onChange={(e) => onChange('Closed At', e.target.checked)}
                    >
                    Closed At
                </Checkbox>
            </Space>
        </Card>
    );

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16} align={'middle'}>
                            <Col span={24}>
                                <Space style={{justifyContent:"end"}} className='w-100'>
                                    <Button className='btncancel pad-filter' onClick={()=>setFilterModal(true)}>
                                        <Space>
                                            <Image src='/assets/icons/filter-outline.png' preview={false} width={20} />
                                            <Text className='text-black'>Filters</Text>
                                        </Space>
                                    </Button>
                                    <Tooltip title="Column">
                                        <Dropdown
                                            overlay={dropdownContent}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter brand-bg border0'>
                                                <Image src='/assets/icons/plus-cr.png' preview={false} width={20} />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={tillData} 
                        scroll={{x: 2300}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export { TillsTable }
