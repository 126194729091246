import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography } from 'antd'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const FilterModifier = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Filter</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row>
                    <Col span={24}>
                        <MyInput 
                            label='Modifier Name'
                            name='name'
                            size='large'
                            required
                            message='Please enter modifier name'
                            value={form.getFieldValue('name') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label='Reference'
                            name='reference'
                            size='large'
                            required
                            message='Please enter reference'
                            value={form.getFieldValue('reference') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MySelect
                            label= 'Delete'
                            name="delete" 
                            size={'large'}
                            value={form.getFieldValue("delete") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Yes'
                                },
                                {
                                    key: 2,
                                    name: 'No'
                                },
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <MyDatepicker 
                            datePicker
                            label='Updated After'
                            size='large'
                            name='updateAfter'
                            required
                            message='Please enter update after'
                            value={form.getFieldValue('updateAfter') || ''}
                            className='border-gray fs-13'
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {FilterModifier}