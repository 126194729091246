import React, { useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Image, Row, Space, Tabs, Typography } from 'antd'
import { AddModifierOption, AlertModal, AssignTax, ModifierOptionActiveTable, ModifierOptionAllTable, ModifierOptionDeleteTable, ModifierOptionFilter, ModifierOptionImport, ModifierOptionInactiveTable, ModuleTopHeading } from '../../components'
import { DownOutlined } from '@ant-design/icons'

const { Text } = Typography
const ModifierOptionPage = ({deletedata, setDeleteData}) => {

    const [order, setOrder] = useState(1)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ assigntax, setAssignTax ] = useState(false)
    const [ removetax, setRemoveTax ] = useState(false)
    const [ filtermodal, setFilterModal ] = useState(false)
    const [ importmodal, setImportModal ] = useState(false)
    const [ switchtext, setSwitchText ] = useState(false)    
    const [ modifieroptionvisible, setModifierOptionVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(false)

    const items = [
        { label:    
            <Button className='fs-13 border0 bg-transparent' 
                onClick={()=>setImportModal(true)}>Import Modifiers</Button>, 
                key: 0 
        },
        { label: <Button className='fs-13 border0 bg-transparent'>Export Modifiers</Button>, key: 1 },
    ];


    const onClick = ({ key }) => {
        // key = parseInt(key) + 1;
        setOrder(key);
        // filter(key);
    };

    const itemstab = [
        {
            key: '1',
            label: 'All',
            children: <ModifierOptionAllTable {...{ deleteitem, setDeleteItem, switchtext, setSwitchText, assigntax, setAssignTax, setRemoveTax, setEditItem }} />,
        },
        {
            key: '2',
            label: 'Active',
            children: <ModifierOptionActiveTable {...{ deleteitem, setDeleteItem, switchtext, setSwitchText, assigntax, setAssignTax, setRemoveTax, setEditItem }} />,
        },
        {
            key: '3',
            label: 'Inactive',
            children: <ModifierOptionInactiveTable {...{ deleteitem, setDeleteItem, switchtext, setSwitchText, setEditItem ,setAssignTax, setRemoveTax }} />,
        },
        {
            key: '4',
            label: 'Deleted',
            children: <ModifierOptionDeleteTable {...{deletedata, setDeleteData, setEditItem}} />,
        },
    ];
  return (
    <div>
        
            <Row gutter={[24, 24]} align={'middle'}>
                <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
                    <ModuleTopHeading name='Modifier Options' onClick={()=>{setModifierOptionVisible(true)}} />
                </Col>
                <Col lg={{span:12}} md={{span: 10}} sm={{span: 24}} xs={{span: 24}}>
                    <Space size={10} className='w-100 right'>
                        <Button className='btncancel pad-filter' onClick={()=> setFilterModal(true)}>
                            <Space>
                                <Image src='/assets/icons/filter-outline.png' preview={false} width={18} />
                                <Text className='text-black'>Filter</Text>
                            </Space>
                        </Button>
                        <Dropdown
                            menu={{ items, onClick }}
                            trigger={['click']}
                            className='margin-top'
                        >
                            <Button className='btncancel pad-filter'
                                icon={<img src='/assets/icons/export-filter.png' width={20}/>}
                            >
                                <Flex justify='space-between' gap={30}>
                                    Export / Import
                                    <DownOutlined />    
                                </Flex>  
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Card className='shadow-d radius-12 card-cs'>
                        <Tabs defaultActiveKey="1" items={itemstab}  />
                    </Card>
                </Col>
            </Row>

        <AddModifierOption 
            visible={modifieroptionvisible}
            edititem={edititem}
            setDeleteItem={setDeleteItem}
            onClose={()=>{setModifierOptionVisible(false);setEditItem(null)}}
        />

        <AlertModal 
            visible={deleteitem}
            deleteitem={deleteitem}
            switchtext={switchtext}
            onClose={()=>{setDeleteItem(false);setSwitchText(null)}}
        />
        <AssignTax 
            visible={assigntax}
            removetax={removetax}
            onClose={()=>{setAssignTax(false);setRemoveTax(false)}}
        />

        <ModifierOptionFilter 
            visible={filtermodal}
            onClose={()=>setFilterModal(false)}
        />

        <ModifierOptionImport 
            visible={importmodal}
            onClose={()=>setImportModal(false)}
        />
    </div>
  )
}

export {ModifierOptionPage}