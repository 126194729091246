import React, { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Col, Dropdown, Form, Image, Row, Space, Table, Tooltip, Typography } from 'antd'
import { taxReportData } from '../../../../Data';

const { Text } = Typography
const TaxesReportTable = ({setFilterModal,order}) => {
    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState([]);

    const options = [
        'Amount', 
        'Net Sale', 
    ];
    const [checkAll, setCheckAll] = useState(false);
  
    const onChange = (item, checked) => {
      const updatedList = checked
        ? [...checkedList, item]
        : checkedList.filter((value) => value !== item);
  
      setCheckedList(updatedList);
      setCheckAll(updatedList.length === options.length);
    };
  
    const onCheckAllChange = (e) => {
      setCheckedList(e.target.checked ? options : []);
      setCheckAll(e.target.checked);
    };
    const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

    const columns = [
        {
            title: <Space>
                <Text className='fw-500'>Tax</Text>
                <Tooltip title='Names of applied taxes'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'tax',
        },
        { title: 'Branch', dataIndex: 'branch', hidden: order != 1 },
        {
            title: <Space>
                <Text className='fw-500'>Amount</Text>
                <Tooltip title='The amount tax in orders'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'amount',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Net Sales</Text>
                    <Tooltip title='The net sales without tax'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'netsale',
        },       
    ].filter(column => !column.hidden)
 
   
    const dropdownContent = (
        <Card className='text-black radius-8 shadow-c card-cs'>
            <div>
                <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate}>Select All </Checkbox>
            </div>
            <Space direction='vertical' size={10} className='pt-3'>
                <Checkbox checked disabled>Tax</Checkbox>
                <Checkbox
                    checked={checkedList.includes('Amount')}
                    onChange={(e) => onChange('Amount', e.target.checked)}
                    >
                    Amount
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Net Sale')}
                    onChange={(e) => onChange('Net Sale', e.target.checked)}
                    >
                    Net Sale
                </Checkbox>
            </Space>
        </Card>
    );

   
    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16} align={'middle'}>
                            <Col span={24}>
                                <Space style={{justifyContent:"end"}} className='w-100'>
                                    <Button className='btncancel pad-filter' onClick={()=>setFilterModal(true)}>
                                        <Space>
                                            <Image src='/assets/icons/filter-outline.png' preview={false} width={20} />
                                            <Text className='text-black'>Filters</Text>
                                        </Space>
                                    </Button>
                                    <Tooltip title="Column">
                                        <Dropdown
                                            overlay={dropdownContent}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter brand-bg border0'>
                                                <Image src='/assets/icons/plus-cr.png' preview={false} width={20} />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={taxReportData} 
                        scroll={{x: 1200}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export { TaxesReportTable }
