import React, { useState } from 'react'
import { Breadcrumb, Button, Card, Col, Flex, Image, Row, Space, Table, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { userbranchData } from '../../../../Data';
import { UserWithinBranchTable } from '../UserWithinBranchTable';
import { AssignDeviceTable } from '../AssignDeviceTable';
import { InActivePaymentMethodTable } from '../InActivePaymentMethodTable';
import { DeleteBranchCard } from '../DeleteBranchCard';
import { AddBranchuser, InactivePaymentBranch, UserBranchCopy } from '../UserBranchesModals';
import { AlertModal } from '../../../Modals';

const { Text,Title } = Typography
const SingleViewBranch = () => {
    let { branchId } = useParams()
    const navigate = useNavigate()
    const [ visible, setvisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ paymentmodal, setPaymentModal ] = useState(false)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ copymodal, setCopyModal ] = useState(false)
    const branch = userbranchData.find((mod)=>mod.key == branchId)    

    const columns = [
        {
            title: 'Branch',
            dataIndex: 'branch',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            branch: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Branch Name</Text>
                <Text>{branch?.branchName}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Name Localized</Text>
                <Text>B1</Text>
            </Space>,
        },
        {
            key: '2',
            branch: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Reference</Text>
                <Text>{branch?.reference}</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Tax Group</Text>
                <Text>20%</Text>
            </Space>,
        },
        {
            key: '3',
            branch: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Opening From</Text>
                <Text>09:00 am</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Opening To</Text>
                <Text>10:00 pm</Text>
            </Space>,
        },
    ];

    
  

    return (
        <div>            
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Breadcrumb className='mb-3'>
                        <Breadcrumb.Item>Setup Page</Breadcrumb.Item>
                        <Breadcrumb.Item className='text-black' onClick={() => navigate("/branches")}>
                            Branch
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className='fw-500'>{branch?.branchName}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col span={24}>
                    <Flex align='center' gap={10} justify='space-between' wrap> 
                        <Space>
                            <Button className='border0 p-0 bg-transparent' onClick={()=>navigate('/branches')}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Title level={5} className='m-0'>{branch.branchName}</Title>
                        </Space>
                        <Space>
                            <Button className='btncancel px-3 bg-light-gray' onClick={()=>{setCopyModal(true)}}>
                                <Space>
                                    <Image src='/assets/icons/copy.png' preview={false} width={20} />
                                    <Text className='text-black fs-13'>Copy Settings</Text>
                                </Space>
                            </Button>
                            <Button className='btncancel px-3 bg-light-gray' onClick={()=>{setvisible(true);setEditItem('hello')}}>
                                <Space>
                                    <Image src='/assets/icons/edit.png' preview={false} width={18} />
                                    <Text className='text-black fs-13'>Edit  Branch</Text>
                                </Space>
                            </Button>
                        </Space>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Card className='shadow-d radius-12 card-cs'>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 600}}
                            className='table-dark pagination'
                            showSorterTooltip={false}
                            rowHoverable={false}
                            showHeader={false}
                            pagination={false}
                        />
                    </Card>
                </Col>
                <Col span={24}>
                    <UserWithinBranchTable />
                </Col>
                <Col span={24}>
                    <AssignDeviceTable />
                </Col>
                <Col span={24}>
                    <InActivePaymentMethodTable {...{paymentmodal,setPaymentModal}} />
                </Col>
                <Col span={24}>
                    <DeleteBranchCard setDeleteItem={setDeleteItem} />
                </Col>
            </Row>
            <AddBranchuser 
                visible={visible}
                edititem={edititem}
                onClose={()=>{setvisible(false);setEditItem(null)}}
            />
            <InactivePaymentBranch 
                visible={paymentmodal}
                onClose={()=>setPaymentModal(false)}
            />
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                onClose={()=>setDeleteItem(false)}
            />
            <UserBranchCopy 
                visible={copymodal}
                onClose={()=>setCopyModal(false)}
            />
        </div>
    )
}

export { SingleViewBranch }
