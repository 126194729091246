import React, { useState } from 'react'
import { Card, Table, Typography } from 'antd'

const { Title } = Typography
const UserWithinBranchTable = () => {
    const columns = [
        {
            title: 'User Name',
            dataIndex: 'username',
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
        },
        {
            title: 'Employee Number',
            dataIndex: 'empnumber',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneno',
        },
    ];
    const data = [
        {
            key: '1',
            username: 'Ziyad Abdullah',
            email:'ZA@gmail.com',
            empnumber:'12345',
            phoneno:'+966 567 432 567'
        },
    ];

    return (
        <div>
            <div className='mb-3'>
                <Title level={5} className='m-0'>User</Title>
            </div>
            <Card className='shadow-d radius-12 card-cs'>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    showSorterTooltip={false}
                    rowHoverable={true}
                    pagination={false}
                />
            </Card>
        </div>
    )
}

export { UserWithinBranchTable }
