import { Button, Card, Col, Divider, Form, Image, Row, Space, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { MyInput, MySelect } from '../../../Forms'
const {Title} = Typography

const GeneralDetails = () => {

  const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
  return (
        <div>
            <div className='mb-3'>
                <Title level={5} className='m-0'>General</Title>
            </div>
            <Card className='shadow-d radius-12 card-cs py-2 px-2'>
              <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
              >
                  <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label={<Space>
                                Business Name
                                <Tooltip title=''>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                              </Space>}
                              name='name'
                              size='large'
                              required
                              value={form.getFieldValue('name') || ''}
                          />
                      </Col>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label="Account Number"
                              name='account'
                              size='large'
                              type="number"
                              required
                              value={form.getFieldValue('accountnumber') || ''}
                              disabled
                          />
                      </Col>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label='Business Category'
                              name='category'
                              size='large'
                              required
                              value={form.getFieldValue('category') || ''}
                              disabled
                          />
                      </Col>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label={<Space>
                                Tax Number
                                <Tooltip title='Your business tax registration number'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                              </Space>}
                              name='taxNo'
                              size='large'
                              type="number"
                              required
                              value={form.getFieldValue('taxNo') || ''}
                           
                          />
                      </Col>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label={<Space>
                                Commercial Registration Number
                                <Tooltip title='Your business commercial registration number'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                              </Space>}
                              name='regNo'
                              size='large'
                              required
                              value={form.getFieldValue('regNo') || ''}
                          />
                      </Col>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MySelect
                              label="Country"
                              name="country" 
                              size={'large'}
                              mode='multiple'
                              required
                              message='Please choose country'
                              value={form.getFieldValue("country") || ''}
                              options={[
                                  {
                                      key: 1,
                                      name: 'Saudia Arabia'
                                  },
                                  {
                                      key: 2,
                                      name: 'English'
                                  },
                              ]}
                          />
                      </Col>
              
                      
                      <Divider />
                      <Space style={{justifyContent:"end"}} className="w-100">
                      <Button
                          onClick={()=>{}}
                          className='btncancel'
                      >
                          Reset
                      </Button>
                      <Button 
                          block
                          type="primary"
                          className='btnsave text-white brand-bg'
                          loading={loading}
                          onClick={()=>{
                              // DeleteProductApi();  
                          }}
                          
                      >
                          Save
                      </Button>
                  </Space>
                  </Row>
              </Form>

              </Card>
    </div>
  )
}

export {GeneralDetails}