import { Button, Card, Space, Table, Typography } from 'antd'
import React from 'react'

const {Title, Text} = Typography

const InvoicesTable = ()=> {

  const columns = [
    {
        title: 'Invoice #',
        dataIndex: 'invoice',
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },
    {
        title: 'Issued On',
        dataIndex: 'issued',
    },
    {
        title: 'Due Date',
        dataIndex: 'dueDate',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
            title: 'Action',
            key: 'action',
            align:'center',
            render: (_, row) => (
                <Space>
                    <Button className='border0 radius-8 text-brand bg-light-blue'>Pay</Button>
                    <Button className='border0 radius-8 text-brand bg-light-blue'>View</Button>
                </Space>
            ),
        },
  ];
  const data = [
      {
          key: '1',
          invoice: 'INV-99956',
          status:'Paid',
          issued:'2024-09-10',
          dueDate:'2025-09-09',
          amount: "SAR 1345.76",
      },
  ];
  
  return (
    <div>
            <div className='mb-3'>
                <Title level={5} className='m-0'>Invoices</Title>
            </div>
            <Card className='shadow-d radius-12 card-cs'>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    showSorterTooltip={false}
                    rowHoverable={true}
                    pagination={false}
                />
            </Card>
        </div>
  )
}

export {InvoicesTable}
