import React, { useState } from 'react';
import { Card, Form, Flex, Button, Space, Typography, Image } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { ModuleTopHeading } from '../../PageComponents';

const { Text, Title } = Typography

const NetPaymentLineChart = () => {
  const [form] = Form.useForm();

  const chartData = {
    series: [
      {
        name: 'Net Payment Analysis today',
        data: [0, 13, 17, 10, 15, 16, 25,14,17],
      },
      {
        name: 'Net Payment Analysis Yesterday',
        data: [0, 25, 5, 20, 24, 22, 18,25,22],
      },
    ],
    options: {
      chart: {
        type: 'line',
        toolbar:{
          show: false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
        dashArray:[0,2]
      },
      xaxis: {
        categories: [
          '12 am',
          '03 am',
          '06 am',
          '09 am',
          '12 pm',
          '03 pm',
          '06 pm',
          '09 pm',
          '11 pm',
        ],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      colors: ['#2BE3B7','#F56C89'],
      legend: {
        show: false,
      },
    },
  };

  const data = [
    {
      key:'blue',
      date:'11 April - 2024',
      amount:'SAR 10,000',
    },
    {
      key:'pink',
      date:'10 April - 2024',
      amount:'SAR 5000',
    },
  ]

  return (
    <div>
      <Card className='shadow-d radius-12 card-cs'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <Flex vertical>
            <ModuleTopHeading name='Net Payments Analysis' />
            <Title level={5} className='m-0 fw-500'>SAR 110</Title>
          </Flex>
          <Form form={form} layout="vertical">
            <Flex justify='end' gap={10}>
              <Button className='btncancel fs-13 pad-filter'>
                View Report    
              </Button>
            </Flex>
          </Form>
        </Flex>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={200}
        />
        {/* {
          data?.map((data,index)=>
            <Flex justify='space-between' align='center' className='mb-1' key={index}>
              <Space>
                <div className={`lagend-class ${data.key === 'blue' ? 'bg-blue' : 'bg-pink'}`}></div>
                <Text className='fs-13'>{data?.date}</Text>
              </Space>
              <Text className='fs-13'>{data?.amount}</Text>
            </Flex>
          )
        } */}
      </Card>
    </div>
  );
};

export { NetPaymentLineChart };
