import React, { useState } from 'react'
import { Card, Col, Flex, Row, Typography } from 'antd'
import { paymentmethodData } from '../../../../Data'

const { Text, Title } = Typography
const InactivePaymentDetails = ({setAddPaymentMethod,setEditItem}) => {

    let Inactivedata = paymentmethodData.filter((e)=> e.status === 'Inactive')
    
  return (
    <div>
        <Row gutter={[24, 24]} align={'middle'}>
            {Inactivedata.map((data, index) => (
              <Col lg={{span:6}} md={{span:12}} sm={{span:24}} xs={{span: 24}} key={index}>
                <Card
                  onClick={() =>{setAddPaymentMethod(true);setEditItem(data.title)}}
                  className="cursor shadow-d radius-12 card-cs"
                  vertical justify="center" gap={5}
                >
                    <Title level={5} className='m-0'>{data?.title}</Title>
                    <Flex justify='space-between' align='center
                    '>
                        <Text className="fs-14 fw-400">
                            {data.method}
                        </Text>
                        <Text className={`fs-12 badge-cs ${data?.status === 'Active' ? 'text-active bg-light-green' : 'text-inactive bg-light-red'}`}>
                            {
                                data?.status
                            }
                        </Text>
                    </Flex>
                </Card>
              </Col>
            ))}
        </Row>
    </div>
  )
}

export {InactivePaymentDetails}