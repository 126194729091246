export const paymentReportsData = [
    {
        paymentmethod:'Cash',
        // hide by default
        branch: 'Branch 1',
        branchreference: 'B01',
        cashier: 'Ali',
        hoursofday: '3',
        day: '2024-10-01',
        week: '2024-09-30  -  2024-10-06',
        month: '2024-10',
        quarter: '2024-04',
        year: '2024',
        // end
        amount:13884,
        count:1277,
        returnamount:35,
        returncount:4,
        netamount:13848,
    },
    {
        paymentmethod:'Cash',
        // hide by default
        branch: 'Branch 1',
        branchreference: 'B01',
        cashier: 'Ali',
        hoursofday: '3',
        day: '2024-10-01',
        week: '2024-09-30  -  2024-10-06',
        month: '2024-10',
        quarter: '2024-04',
        year: '2024',
        // end
        amount:1384,
        count:127,
        returnamount:5,
        returncount:4,
        netamount:1348,
    },
]