import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography, Col, Row, Image } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { DownOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput, MySelect, MyselectFix } from '../../Forms';

const { Title } = Typography

const PaymentReportBarChart = () => {
  const [form] = Form.useForm();
  const [ order, setOrder ] = useState(0)
  const [ ordermetric, setOrderMetric ] = useState(0)

const chartData = {
  series: [
    {
      name: '11 April - 2024',
      data: [ 200, 370 ],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius:5,
        columnWidth: '50%',
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    xaxis: {
      categories: [
        'Ali',
        'Ahmed',
      ],
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 400,
      tickAmount: 5,
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    fill: {
      opacity: .2,
    },
    grid: {
      show: false,
    },
    colors: ['#0086FF'],
    legend:{
      markers:{
        show: false
      }
    }   
  },
};

const items = [
    { label: <a href="#" className='fs-13'>By Payment Method</a>, key: 0 },
    { label: <a href="#" className='fs-13'>By Payment Type</a>, key: 1 },
];

const metric = [
    { label: <a href="#" className='fs-13'>Amount</a>, key: 0 },
    { label: <a href="#" className='fs-13'>Net Amount</a>, key: 1 },
    { label: <a href="#" className='fs-13'>Return Amount</a>, key: 2 },
    { label: <a href="#" className='fs-13'>Count</a>, key: 3 },
    { label: <a href="#" className='fs-13'>Return Count</a>, key: 4 },
];

const onClick = ({ key }) => {
    setOrder(parseInt(key));
  };

  const onMetricClick = ({ key }) => {
    setOrderMetric(parseInt(key));
  };


return (
    <div>
        <Card className='shadow-d radius-12 card-cs'>
            <Flex justify='space-between' align='center' gap={10} wrap>
              <ModuleTopHeading name='By Payment Method' />
              <Flex justify='end' align='center' wrap gap={10}>
                  <Button className='btncancel pad-filter'>
                      <Image src='/assets/icons/download.png' preview={false} width={20} />
                  </Button>
                  <Dropdown
                      menu={{ items, onClick }}
                      trigger={['click']}
                      className='margin-top'
                  >
                      <Button className='btncancel pad-filter text-black'>
                          <Flex justify='space-between' gap={30}>
                              Type: {order === 0 ? 'By Payment Method' : 'By Payment Type'}
                              <DownOutlined />    
                          </Flex>  
                      </Button>
                  </Dropdown>
                  <Dropdown
                      menu={{ items: metric, onClick: onMetricClick }}
                      trigger={['click']}
                      className='margin-top'
                  >
                      <Button className='btncancel pad-filter text-black'>
                          <Flex justify='space-between' gap={30}>
                              Metric:  {
                                  ordermetric === 0 ? 'Amount' :
                                  ordermetric === 1 ? 'Count' :
                                  ordermetric === 2 ? 'Net Amount' :
                                  ordermetric === 3 ? 'Return Amount' :
                                  'Return Count'
                                  }
                              <DownOutlined />    
                          </Flex>  
                      </Button>
                  </Dropdown>
              </Flex>
            </Flex>
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={200}
                />
        </Card>
    </div>
  );
};

export { PaymentReportBarChart };
