import React, { useState } from 'react'
import { CalendarDropBorder, ModuleTopHeading, SalesReportBranchFilter, SalesReportByBranchTable, SalesReportByCashierTable, SalesReportByCategoryTable, SalesReportByModifierTable, SalesReportByOrderTypeTable, SalesReportByProductTable, SalesReportCashierFilter, SalesReportCategoryFilter, SalesReportModifierFilter, SalesReportOrderTypeFilter, SalesReportProductFilter, SearchInput } from '../../components'
import { Button, Card, Checkbox, Col, Dropdown, Flex, Form, Row, Space, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const { Text } = Typography
const SalesReport = () => {

  const [ branchfiltermodal, setBranchFilterModal ] = useState(false)
  const [ cashierfiltermodal, setCashierFilterModal ] = useState(false)
  const [ categoryfiltermodal, setCategoryFilterModal ] = useState(false)
  const [ modifierfiltermodal, setModifierFilterModal ] = useState(false)
  const [ orderfiltermodal, setOrderFilterModal ] = useState(false)
  const [ productfiltermodal, setProductFilterModal ] = useState(false)

  const [order, setOrder] = useState('none');
  const [ dropdownTable ,setDropdownTable ] = useState("Branch") 

  const [form] = Form.useForm();
  const items = [
    { label: <a href="#" className='fs-13'>To CSV</a>, key: 0 },
    { label: <a href="#" className='fs-13'>To Excel</a>, key: 1 },
  ];

  const orderTable = [
    { label: <Link href="#" className='fs-13'>Branch</Link>, key: "Branch" },
    { label: <Link href="#" className='fs-13'>Cashier</Link>, key: "Cashier" },
    { label: <Link href="#" className='fs-13'>Category</Link>, key: "Category" },
    { label: <Link href="#" className='fs-13'>Modifier Option</Link>, key: "Modifier Option" },
    { label: <Link href="#" className='fs-13'>Order Type</Link>, key: "Order Type" },
    { label: <Link href="#" className='fs-13'>Product</Link>, key: "Product" },
  ]

// Dropdown options for the second dropdown based on the selected category
const getDropdownItems = () => {
    const groupbynone = [
        { label: <Link href="#" className='fs-13'>None</Link>, key: 'none' },
        { label: <Link href="#" className='fs-13'>Branch</Link>, key: 'branch' },
        { label: <Link href="#" className='fs-13'>Category</Link>, key: "category" },
        { label: <Link href="#" className='fs-13'>Modifier Option</Link>, key: "modifierOption" },
        { label: <Link href="#" className='fs-13'>Order Type</Link>, key: "orderType" },
        { label: <Link href="#" className='fs-13'>Product</Link>, key: "product" },
        { label: <Link href="#" className='fs-13'>Cashier</Link>, key: "cashier" },
        { label: <Link href="#" className='fs-13'>Hour of Day</Link>, key: "hourOfDay" },
        { label: <Link href="#" className='fs-13'>Day</Link>, key: "day" },
        { label: <Link href="#" className='fs-13'>Week</Link>, key: "week" },
        { label: <Link href="#" className='fs-13'>Month</Link>, key: "month" },
        { label: <Link href="#" className='fs-13'>Quarter</Link>, key: "quarter" },
        { label: <Link href="#" className='fs-13'>Year</Link>, key: "year" },
      ]

    if(dropdownTable == "Branch"){
      return groupbynone.filter(item => item.key !== 'branch'); 
    }
    else if (dropdownTable == "Cashier") {
      return groupbynone.filter(item => item.key !== 'cashier');
    } 
    else if (dropdownTable == "Category") {
      return groupbynone.filter(item =>  
        item.key !== 'modifierOption' && 
        item.key !== 'product' &&
        item.key !== "category"
      );
    } 
    else if (dropdownTable == "Modifier Option") {
      return groupbynone.filter(item => 
        item.key !== 'branch' && 
        item.key !== 'modifierOption' && 
        item.key !== 'cashier' &&
        item.key !== "orderType"
      );
    } 
    else  if (dropdownTable == "Order Type"){
      return groupbynone.filter(item => item.key !== 'orderType');
    }
    else  if (dropdownTable == "Product"){
      return groupbynone.filter(item => item.key !== 'modifierOption' && 
        item.key !== 'product');
    }
    
    return groupbynone; // Default to showing all items if nothing is selected
  };

  

  const renderTable = () => {
    switch (dropdownTable) {
      case "Branch":
        return <SalesReportByBranchTable {...{ branchfiltermodal, setBranchFilterModal, order }} />;
      case "Cashier":
        return <SalesReportByCashierTable {...{cashierfiltermodal, setCashierFilterModal, order }} />;
      case 'Category':
        return <SalesReportByCategoryTable {...{ categoryfiltermodal, setCategoryFilterModal, order }} />;
      case 'Modifier Option':
        return <SalesReportByModifierTable {...{ modifierfiltermodal, setModifierFilterModal, order }} />;
      case 'Order Type':
        return <SalesReportByOrderTypeTable {...{ orderfiltermodal, setOrderFilterModal, order }} />;
      case 'Product':
        return <SalesReportByProductTable {...{ productfiltermodal, setProductFilterModal, order }} />;
    }
  };

  const onClick = ( {key} ) => {
    //   key = parseInt(key) + 1;
      setOrder(key);
  };

  const handleMenuClick =(e)=>{
    setDropdownTable(e.key)
    setOrder('none')
  } 

  const dropdownContent = (
      <Card className='radius shadow-c card-cs'>
          <Form form={form} layout="vertical">
              <SearchInput 
                  name='search'
                  value={form.getFieldValue('search') || ''}
                  placeholder='Search...'
              />
          </Form>
          <Space direction='vertical' size={10} className='pt-3'>
              <Text>All Branches</Text>
              <Checkbox>Branch 1 (B01)</Checkbox>
          </Space>
      </Card>
    );

  return (
    <div>
        <Row gutter={[24,24]}>
          <Col lg={{span:8}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
            <Dropdown
                menu={{ items:orderTable, onClick: handleMenuClick }}
                trigger={['click']}
                className='margin-top'
            >
                <Button className='border0 bg-transparent p-0'
                >
                    <ModuleTopHeading name={`Sales By ${dropdownTable}` }/>
                    <DownOutlined />
                </Button>
            </Dropdown>
          </Col>
          <Col lg={{span:16}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
            <Space size={10} className='w-100 right mbl-space' wrap>
                <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                        icon={<img src='/assets/icons/export-filter.png' width={18}/>}
                    >
                        <Flex justify='space-between'  className='w-100' gap={30}>
                            Export
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <Dropdown
                    menu={{ items:getDropdownItems(),onClick }}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                    >
                        <Flex justify='space-between'  className='w-100' gap={30}>
                            Group By {order}
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <Dropdown
                    overlay={dropdownContent}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                        icon={<img src='/assets/icons/branch-c.png' width={18}/>}
                    >
                        <Flex justify='space-between'  className='w-100' gap={30}>
                            Branches 
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <CalendarDropBorder />
            </Space>
          </Col>
          
           
          <Col span={24}>
            {renderTable()}
          </Col>
        </Row>

      <SalesReportBranchFilter
        visible={branchfiltermodal}
        onClose={()=>setBranchFilterModal(false)}
      />
      <SalesReportCashierFilter
        visible={cashierfiltermodal}
        onClose={()=>setCashierFilterModal(false)}
      />
      <SalesReportCategoryFilter
        visible={categoryfiltermodal}
        onClose={()=>setCategoryFilterModal(false)}
      />
      <SalesReportModifierFilter
        visible={modifierfiltermodal}
        onClose={()=>setModifierFilterModal(false)}
      />
      <SalesReportOrderTypeFilter
        visible={orderfiltermodal}
        onClose={()=>setOrderFilterModal(false)}
      />
      <SalesReportProductFilter
        visible={productfiltermodal}
        onClose={()=>setProductFilterModal(false)}
      />
    </div>
  )
}

export {SalesReport}