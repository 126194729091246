import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Tooltip, Image } from 'antd'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const UserPasswordChange = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Save
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Change Password</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                    New Password
                                    <Tooltip title='New password'>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                            </Space>}
                            name='password'
                            size='large'
                            type='password'
                            required
                            message={()=>{}}
                            value={form.getFieldValue("password") || ''} 
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                            validator={({ getFieldValue }) => ({
                                validator: (_, value) => {
                                    const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                    if (!reg.test(value)) {
                                        return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            })}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            label={<Space>
                                Confirm New Password
                                <Tooltip title='Confirm new password'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            name='confirmPassword'
                            type='password'
                            size='large'
                            placeholder='Enter your confirm password'
                            dependencies={['password']}
                            required
                            message='Please enter confirm password'
                            value={form.getFieldValue("confirmPassword") || ''} 
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The password that you entered do not match!'));
                                    },
                                }),
                            ]}
                            validator={({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The password that you entered do not match!'));
                                },
                            })}
                        />
                    </Col>
                    
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {UserPasswordChange}