import React, { useState } from 'react'
import { Button, Dropdown, Space, Table, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { modifieroptionallData } from '../../../../Data'
import { DownOutlined } from '@ant-design/icons'

const { Text } = Typography

const ModifierOptionActiveTable = ({ setDeleteItem, setSwitchText, setAssignTax, setRemoveTax, setEditItem }) => {

    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);

    const activedata = modifieroptionallData.filter((activedata)=> activedata.status === 'Active')

    const columns = [
        {
            title: 'Modifier Option Name',
            dataIndex: 'optionname',
            sorter: (a,b) => a.optionname - b.optionname,
        },
        {
            title: 'Modifier Name',
            dataIndex: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
        },
        {
            title: 'Tax Group',
            dataIndex: 'taxgroup',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 170,
            render: (status) => (
                    <Space align="center">
                            <Text className={
                                status === 'Active' ? 
                                'text-active bg-light-green fs-12 badge-cs':
                                'text-inactive bg-light-red fs-12 badge-cs'
                            }>
                                {status}
                            </Text>
                    </Space>  
            )
        },
        // {
        //     title: 'Action',
        //     fixed: 'right',
        //     align:'center',
        //     className:'action',
        //     width:130,
        //     render: (_, row) => (
        //         <Space>
        //             <Dropdown
        //                 menu={{ items: [
        //                     { label: <Link onClick={()=>{ setDeleteItem(true);setSwitchText(true) }} className='fs-13'>Inactive</Link>, key: 0 },
        //                     { label: <Link onClick={()=>{ setAssignTax(true) }} className='fs-13'>Assign Tax Group</Link>, key: 1 },
        //                     { label: <Link onClick={()=>{ setAssignTax(true);setRemoveTax(true) }} className='fs-13'>Remove Tax Group</Link>, key: 2 },
        //                     { label: <Link onClick={()=>{ setDeleteItem({name: row?.name}) }} className='fs-13'>Delete</Link>, key: 3 }
        //                 ] }}
        //                 trigger={['click']}
        //                 className='margin-top border-circle'
        //             >
        //                 <img src='/assets/icons/3dots.png' width={18} />
        //             </Dropdown>
        //         </Space>
        //     ),
        // },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
    };
   
  return (
    <div>
        <Table 
            size='large'
            columns={columns} 
            dataSource={activedata} 
            scroll={{x: 1000}}
            className='table-dark pagination'
            showSorterTooltip={false}
            rowHoverable={true}
            onRow={(record) => ({
                onClick: (event) => {
                    const isActionColumn = event.target.closest('.action');
                    if (!isActionColumn && record.key) {
                        navigate(`/modifieroption/viewmodifieroption/${record.key}`);
                        setEditItem(record?.id)
                    }
                },
            })}
            rowSelection={rowSelection}
            title={ selectedRows.length > 0 ? (record) => (
                <div>{selectedRows.length} Selected  
                    <Dropdown
                        menu={{ items: [
                            { label: <Link className='fs-13 action-column' onClick={()=>{ setAssignTax(true)}}>Assign Tax Group</Link>, key: 0 },
                            { label: <Link className='fs-13 action-column' onClick={()=>{ setAssignTax(true);setRemoveTax(true) }}>Remove Tax Group</Link>, key: 1 },
                        ] }}
                        trigger={['click',]}
                        className='margin-top'
                    >
                        <Button className='btncancel px-2 py-1 bg-light-gray mx-2'>
                            <Space>
                                <Text className='text-black fs-13'>Actions</Text>
                                <DownOutlined /> 
                            </Space>
                        </Button>
                    </Dropdown>
                </div> 
            ) : null}
            pagination={{
                hideOnSinglePage: true,
                total: 12,
                // pageSize: pagination?.pageSize,
                // defaultPageSize: pagination?.pageSize,
                // current: pagination?.pageNo,
                // size: "default",
                // pageSizeOptions: ['10', '20', '50', '100'],
                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
            }}
        />
    </div>
  )
}

export {ModifierOptionActiveTable}