import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Divider } from 'antd'
// import { apiCalls } from '../../../shared/Apis';
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const CategoryFilter = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    // const DeleteProductApi = async() => {
    //     setLoading(true)
    //     try{
    //         const result = await apiCalls.vendorApi.DeleteProductApi(deletevendor?.id)
    //         setLoading(false)
    //         onClose();
    //         getVendorApplications();
    //     } catch (error){
    //         console.error("Error deleting data",error)
    //     }
    // }
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Filter</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MyInput 
                            label='Category Name'
                            size='large'
                            name='name'
                            required
                            message='Please enter category name'
                            value={form.getFieldValue('name') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            type='number'
                            label='Category Reference'
                            size='large'
                            name='refno'
                            required
                            message='Please enter category reference'
                            value={form.getFieldValue('refno') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyDatepicker 
                            datePicker
                            label='Updated After'
                            size='large'
                            name='updateAfter'
                            required
                            message='Please enter update after'
                            value={form.getFieldValue('updateAfter') || ''}
                            className='border-gray fs-13'
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {CategoryFilter}