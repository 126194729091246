import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography } from 'antd'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const InactivePaymentBranch = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Inactive Payment Method</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MySelect
                            label= 'Payment Method'
                            name="paymentmethod" 
                            size={'large'}
                            mode='multiple'
                            required
                            message='Please choose payment method'
                            value={form.getFieldValue("paymentmethod") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: '30%'
                                },
                                {
                                    key: 2,
                                    name: '50%'
                                },
                            ]}
                        />
                    </Col>   
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {InactivePaymentBranch}