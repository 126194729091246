import React, { useState } from 'react'
import { CalendarDropBorder, FilterOrderModal, ModuleTopHeading, OrderTable, SearchInput } from '../../components'
import { Button, Card, Checkbox, Col, Dropdown, Flex, Form, Row, Space, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'

const { Text } = Typography
const OrderPage = () => {

  const [ filtermodal, setFilterModal ] = useState(false)
  const [order, setOrder] = useState(1);
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#" className='fs-13'>Orders - Raw Data</a>, key: 0 },
    { label: <a href="#" className='fs-13'>Orders Items - Raw Data</a>, key: 1 },
    { label: <a href="#" className='fs-13'>Orders Payment - Raw Data</a>, key: 2 },
    { label: <a href="#" className='fs-13'>Orders</a>, key: 3 },
    { label: <a href="#" className='fs-13'>Orders Items</a>, key: 4 },
    { label: <a href="#" className='fs-13'>Orders Payment </a>, key: 5 },
  ];

  const onClick = ({ key }) => {
      // key = parseInt(key) + 1;
      setOrder(key);
      // filter(key);
  };

  const dropdownContent = (
      <Card className='radius shadow-c card-cs'>
          <Form form={form} layout="vertical">
              <SearchInput 
                  name='search'
                  value={form.getFieldValue('search') || ''}
                  placeholder='Search...'
              />
          </Form>
          <Space direction='vertical' size={10} className='pt-3'>
              <Text>All Branches</Text>
              <Checkbox>Branch 1 (B01)</Checkbox>
          </Space>
      </Card>
    );

  return (
    <div>
        <Row gutter={[24,24]}>
          <Col lg={{span:6}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
            <ModuleTopHeading name='Order'/>
          </Col>
          <Col lg={{span:18}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
            <Space size={10} className='w-100 right mbl-space' wrap>
                <Dropdown
                    overlay={dropdownContent}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                        icon={<img src='/assets/icons/branch-c.png' width={18}/>}
                    >
                        <Flex justify='space-between' className='w-100' gap={30}>
                            Branches 
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <CalendarDropBorder />
                <Dropdown
                    menu={{ items, onClick }}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                        icon={<img src='/assets/icons/export-filter.png' width={18}/>}
                    >
                        <Flex justify='space-between' className='w-100' gap={30}>
                            Export
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
            </Space>
          </Col>
          <Col span={24}>
            <Card className='shadow-d radius-12 card-cs'>
              <OrderTable {...{filtermodal, setFilterModal}} />
            </Card>
          </Col>
        </Row>

      <FilterOrderModal 
        visible={filtermodal}
        onClose={()=>setFilterModal(false)}
      />
    </div>
  )
}

export {OrderPage}