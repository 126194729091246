import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography } from 'antd'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const UserFilter = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    
  return (
    <div>
        <Modal 
            width={700} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Filter</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label='User Name'
                            name='name'
                            size='large'
                            required
                            message='Please enter user name'
                            value={form.getFieldValue('name') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label='Employee Number'
                            name='empNo'
                            size='large'
                            required
                            message='Please enter employee number'
                            value={form.getFieldValue('empNo') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label='Email Address'
                            name='email'
                            size='large'
                            required
                            message='Please enter email address'
                            value={form.getFieldValue('email') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label='Phone Number'
                            name='phoneNo'
                            size='large'
                            required
                            message='Please enter phone number'
                            value={form.getFieldValue('phoneNo') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Branch Name'
                            name="branch" 
                            size={'large'}
                            mode='multiple'
                            required
                            message='Please enter branch'
                            value={form.getFieldValue("branch") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Branch 1 (B01)'
                                },
                                {
                                    key: 2,
                                    name: 'Branch 2 (B02)'
                                },
                            ]}
                        />
                    </Col>                    
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Role Name'
                            name="rolename" 
                            mode='multiple'
                            size={'large'}
                            required
                            message='Please enter role name'
                            value={form.getFieldValue("rolename") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Admin'
                                },
                                {
                                    key: 2,
                                    name: 'Data Entry'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Has Branches'
                            name="hasBranch" 
                            size={'large'}
                            required
                            message='Please choose yes/no'
                            value={form.getFieldValue("hasBranch") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Yes'
                                },
                                {
                                    key: 2,
                                    name: 'No'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Has Roles'
                            name="hasRole" 
                            size={'large'}
                            required
                            message='Please choose yes/no'
                            value={form.getFieldValue("hasRole") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Yes'
                                },
                                {
                                    key: 2,
                                    name: 'No'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Has Console Access'
                            name="console" 
                            size={'large'}
                            required
                            message='Please choose yes/no'
                            value={form.getFieldValue("console") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Yes'
                                },
                                {
                                    key: 2,
                                    name: 'No'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Has Cashier Access'
                            name="cashier" 
                            size={'large'}
                            required
                            message='Please choose yes/no'
                            value={form.getFieldValue("cashier") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Yes'
                                },
                                {
                                    key: 2,
                                    name: 'No'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Deleted'
                            name="deleted" 
                            size={'large'}
                            required
                            message='Please choose deleted'
                            value={form.getFieldValue("deleted") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Yes'
                                },
                                {
                                    key: 2,
                                    name: 'No'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyDatepicker 
                            datePicker
                            label='Updated After'
                            size='large'
                            name='updateAfter'
                            required
                            message='Please enter update after'
                            value={form.getFieldValue('updateAfter') || ''}
                            className='border-gray fs-13'
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {UserFilter}