import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Image, Tooltip, Upload, message } from 'antd'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';
import { cityName } from '../../../../shared/lookups/lookups';

const { Dragger } = Upload;
const { Text } = Typography

const AddBranchuser = ({visible,onClose,edititem}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [ randomvalue, setRandomValue ] = useState(null)
    const [form] = Form.useForm()
    
    const GenerateValue = () => {
        setRandomValue(
            Math.floor(Math.random()*90000) + 10000
        )
    }

    const props = {
        name: 'image',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };
  return (
    <div>
        <Modal 
            width={edititem ? 700 : 600} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                // <Flex justify='space-between'>
                //     {
                //         edititem && 
                //         <Button  
                //             type="primary"
                //             className='btnsave text-white bg-red'
                            
                //         >
                //             Delete User
                //         </Button>
                //     }
                    
                // </Flex>
                <Space style={{justifyContent: "center"}} className="w-100">
                        <Button
                            onClick={onClose}
                            className='btncancel'
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            className='btnsave text-white brand-bg'
                            loading={loading}
                            onClick={()=>{
                                // DeleteProductApi();  
                            }}
                            
                        >
                            {
                                edititem? 'Update' : 'Save'
                            }
                        </Button>
                    </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{edititem? 'Edit Branch' : 'Add Branch'}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Branch Name
                                <Tooltip title='The name of the branch'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='branchname'
                            required
                            message='Please enter branch name'
                            value={form.getFieldValue('branchname') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Name Localized
                                <Tooltip title='Enter the localized name in the second language of your choice'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name="localized" 
                            required
                            message='Please enter localized'
                            value={form.getFieldValue("localized") || ''}
                        />
                    </Col>
                    <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Space className='w-100 space-cs'>
                            <MyInput 
                                label={<Space>
                                    Reference
                                    <Tooltip title='Unique ID number or code for the branch'>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                </Space>}
                                size='large'
                                type='number'
                                value={randomvalue}
                                className='w-100'
                            />
                            <Button 
                                className='pad-x fs-13 border-gray text-black h-40 margintop-5' 
                                onClick={GenerateValue}
                            >
                                Generate
                            </Button>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <MySelect
                            label= {<Space>
                                Tax Group
                                <Tooltip title='The group of taxes applied on this branch'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            name="taxgroup" 
                            size={'large'}
                            mode='multiple'
                            required
                            message='Please enter tax group'
                            value={form.getFieldValue("taxgroup") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: '30%'
                                },
                                {
                                    key: 2,
                                    name: '50%'
                                },
                            ]}
                        />
                    </Col> 
                    {
                        edititem &&
                        <>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Branch Tax Registration Name
                                        <Tooltip title='The tax registration name of the branch'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="branchtaxreg" 
                                    required
                                    message='Please enter branch tax registration name'
                                    value={form.getFieldValue("branchtaxreg") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Branch Tax Number
                                        <Tooltip title='The tax registration number of the branch'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="branchtax" 
                                    required
                                    message='Please enter branch tax number'
                                    value={form.getFieldValue("branchtax") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyDatepicker
                                    datePicker 
                                    label={<Space>
                                        Opening From
                                        <Tooltip title='The opening time of the business day of the branch'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="openfrom" 
                                    required
                                    message='Please enter opening from'
                                    value={form.getFieldValue("openfrom") || ''}
                                    className='border-gray'
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyDatepicker
                                    datePicker 
                                    label={<Space>
                                        Opening To
                                        <Tooltip title='The closing time of the business day of the branch'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="opento" 
                                    required
                                    message='Please enter opening to'
                                    value={form.getFieldValue("opento") || ''}
                                    className='border-gray'
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Branch Phone Number
                                        <Tooltip title='The phone number of the branch, can be printed in the receipt.'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="branchphoneNo" 
                                    required
                                    message='Please enter branch phone number'
                                    value={form.getFieldValue("branchphoneNo") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Branch Address
                                        <Tooltip title='The address of the branch'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="branchAddress" 
                                    required
                                    message='Please enter branch address'
                                    value={form.getFieldValue("branchAddress") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Street Name
                                        <Tooltip title='Location of the main entrance of the building'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="streetname" 
                                    required
                                    message='Please enter street name'
                                    value={form.getFieldValue("streetname") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Building Number
                                        <Tooltip title='4 distinctive number representing a building.'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="buildNo" 
                                    required
                                    message='Please enter building number'
                                    value={form.getFieldValue("buildNo") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Additional Number
                                        <Tooltip title='4 distinctive number representing a building, beneficial when their is no street or district name'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="additionalno" 
                                    required
                                    message='Please enter additional number'
                                    value={form.getFieldValue("additionalno") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MySelect 
                                    label={<Space>
                                        City
                                        <Tooltip title='The city where branch is located'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="city" 
                                    required
                                    message='Please enter city'
                                    value={form.getFieldValue("city") || ''}
                                    options={cityName}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        District
                                        <Tooltip title='The district in which branch is located'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="district" 
                                    required
                                    message='Please enter district'
                                    value={form.getFieldValue("district") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Postal Code
                                        <Tooltip title='The postal code of your location'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="postalcode" 
                                    required
                                    message='Please enter postal code'
                                    value={form.getFieldValue("postalcode") || ''}
                                />
                            </Col>
                            <Col span={24}>
                                <MyInput 
                                    label={<Space>
                                        Commercial Registration Number
                                        <Tooltip title='The branch commercial registration number'>
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="commercialregno" 
                                    required
                                    message='Please enter commercial registration number'
                                    value={form.getFieldValue("commercialregno") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Latitude
                                        <Tooltip title="The latitude angle's of the branch location. For example: 24.76543">
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="latitude" 
                                    required
                                    message='Please enter latitude'
                                    value={form.getFieldValue("latitude") || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Longitude
                                        <Tooltip title="The longitude angle's of the branch location. For example: 24.765432">
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name="longitude" 
                                    required
                                    message='Please enter longitude'
                                    value={form.getFieldValue("longitude") || ''}
                                />
                            </Col>
                            <Col span={24}>
                                <Space>
                                    Display Image
                                    <Tooltip title="Select image to be displayed in the background of your display app.">
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='image' 
                                >
                                    <div className='width-drag'>
                                        <Dragger {...props} className="dragcs">
                                            <p className="ant-upload-drag-icon mb-0">
                                                <Image src='/assets/icons/plus.png' width={'30px'} preview={false} />
                                            </p>
                                            <Typography.Text className="fs-14 fw-500">Upload Image</Typography.Text>
                                        </Dragger>
                                    </div>
                                    {/* {
                                        editproperty &&
                                        <Space direction='vertical' size={10}>
                                            {
                                                geteditdata?.medical_report?.map((data,index)=>
                                                    <div className='viewDownload space-between-align' key={index}>
                                                        <Typography.Text className='gray-text fs-12'>
                                                            {
                                                                data?.name
                                                            }
                                                        </Typography.Text>
                                                        <ActionButton
                                                            title='Delete'
                                                            icon={<DeleteOutlined className='danger-color' />}
                                                            onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                            type='ghost'
                                                        />
                                                    </div>
                                                )
                                            }
                                        </Space>
                                        
                                    } */}
                                </Form.Item>
                            </Col>
                        </>
                    }
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddBranchuser}