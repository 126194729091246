import React, { createContext, useContext, useMemo, useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Form, Image, Row, Space, Switch, Table, Typography } from 'antd'
import { Link } from 'react-router-dom';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DownOutlined } from '@ant-design/icons';

const { Text } = Typography

const DrageRow = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      cursor: 'move',
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };

const CategoryTable = ({ setDeleteItem,setFilterVisible, setVisible,setEditItem,  }) => {

    const [form] = Form.useForm();
    const [selectedRows, setSelectedRows] = useState([]);
    const [dataSource, setDataSource] = React.useState([
        {
            key:'1',
            id:1,
            img: <img src='/assets/images/drink.png' width={33} />,
            name: 'Drinks',
            reference:'1',
            product:'Products (5)',
            created:'October 08, 12:03 pm',
        },
        {
            key:'2',
            id:2,
            img: <img src='/assets/images/seafood.png' width={33} />,
            name: 'Sea Food',
            reference:'3',
            product:'Products (2)',
            created:'October 08, 11:03 pm',
        },
        {
            key:'3',
            id:3,
            img: <img src='/assets/images/bakeryitem.png' width={33} />,
            name: 'Bakery Items',
            reference:'2',
            product:'Products (10)',
            created:'October 08, 01:03 pm',
        },
    ]);
    
    const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 1,
          },
        }),
    );
    const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
        setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
        });
    }
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: 'img',
            width: 150,
        },
        {
            title: 'Category Name',
            dataIndex: 'name',
            sorter: (a,b) => a.name - b.name,
        },
        {
            title: 'Category Reference',
            dataIndex: 'reference',
            sorter: (a,b) => a.reference - b.reference,
        },
        {
            title: 'Products',
            dataIndex: 'product',
            render: (product) => <Link>{product}</Link>
        },
        {
            title: 'Created',
            dataIndex: 'created',
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     fixed: 'right',
        //     align:'center',
        //     width:130,
        //     render: (_, row) => (
        //         <Space>
        //             <Dropdown
        //                 menu={{ items: [
        //                     { label: <Link className='fs-13' onClick={() => { setVisible(true);setEditItem(row)}}>Edit</Link>, key: 0 },
        //                     { label: <Link className='fs-13' onClick={() => {setDeleteItem({name:row?.name})}}>Delete</Link>, key: 2 }
        //                 ] }}
        //                 trigger={['click']}
        //                 className='margin-top border-circle cursor'
        //                 placement='bottom'
        //             >
        //                 <img src='/assets/icons/3dots.png' width={18} />
        //             </Dropdown>
        //         </Space>
        //     ),
        // },
    ];
    
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
    };

    return (
        <div>
            <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                    // rowKey array
                    items={dataSource.map((i) => i.key)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={dataSource} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        components={{
                            body: {
                                row: DrageRow,
                            },
                        }}
                        onRow={(record) => {
                            return {
                                onClick:() => { setVisible(true);setEditItem(record?.name)} 
                            }
                          }}
                        rowSelection={rowSelection}
                        title={ selectedRows.length > 0 ? (record) => (
                            <div>{selectedRows.length} Selected  
                                <Dropdown
                                    menu={{ items: [
                                        { label: <Link className='fs-13 action-column' 
                                            onClick={() => {
                                                const selectedRowKeys = selectedRows?.map(row => row?.name)
                                                setDeleteItem({name: ' ' + selectedRowKeys + ' '})
                                            }}>
                                                Delete</Link>, key: 0 
                                            },
                                    ] }}
                                    trigger={['click',]}
                                    className='margin-top'
                                >
                                    <Button className='btncancel px-2 py-1 bg-light-gray mx-2'>
                                        <Space>
                                            <Text className='text-black fs-13'>Actions</Text>
                                            <DownOutlined /> 
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </div> 
                        ) : null}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </SortableContext>
            </DndContext>
        </div>
    )
}

export { CategoryTable }
