export const userData = [
    {
        key: 1,
        username:'Salim Al Tajir',
        email:'Salim @gmail.com',
        console:'Active',
        cashier:'Active',
        role:'Admin',
    },
    {
        key: 2,
        username:'Mohammed Darwish',
        email:'M.Darwish@hotmail.com',
        console:'Inactive',
        cashier:'Active',
        role:'Account Owner',
    },
    {
        key: 3,
        username:'Fahd Bakir',
        email:'F01@gmail.com',
        console:'Active',
        cashier:'Inactive',
        role:'Data Entry',
    },

    {
        key: 4,
        username:'Shahid Ahmad',
        email:'Shahid.A@gmail.com',
        console:'Active',
        cashier:'Active',
        role:'Data Entry',
        id:'delete'
    },
]