import {
  Button,
  Card,
  Col,
  Flex,
  Image,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useState } from "react";
import { ActiveInBranchProduct, ProductActionModal } from "../ProductModals";
const { Text, Title } = Typography;

const options = [
  { id: 1, name: "size" },
  { id: 2, name: "Option 2" },
  { id: 3, name: "Option 3" },
];

const paymentColumns = [
  {
    title: "Branch",
    dataIndex: "branch",
  },
  {
    title: "Reference",
    dataIndex: "ref",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => (
      <Space align="center">
        <Text
          className={
            status === "Active"
              ? "text-active bg-light-green fs-12 badge-cs"
              : "text-inactive bg-light-red fs-12 badge-cs"
          }
        >
          {status}
        </Text>
      </Space>
    ),
  },
  {
    title: "Action",
    key: "action",
    width: 200,
    render: (_, record) => (
      <Button
        className="text-active bg-light-green fs-12 badge-cs border0"
        onClick={() => {}}
      >
        Change to Action
      </Button>
    ),
  },
];
const paymentData = [
  {
    key: "1",
    branch: "Milk",
    ref: "Options(1)",
    status: "active",
  },
];
const ProductInBranch = () => {
  const [selectBranch, setSelectBranch] = useState(false);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Flex justify="space-between" wrap className="w-100" gap={10} align="center">
            <Title level={5} className="m-0">
              Inactivate in Branches
            </Title>
            <Space>
              <Button
                className="btncancel px-2 py-1"
                onClick={() => {
                  setSelectBranch(true);
                }}
              >
                <Space>
                  <Image
                    src="/assets/icons/book-filter.png"
                    preview={false}
                    width={18}
                  />
                  <Text className="text-gray">Selected Branches</Text>
                </Space>
              </Button>
            </Space>
          </Flex>
        </Col>
        <Col span={24}>
          <Card className="shadow-d radius-12 card-cs mb-3">
            <Table
              size="large"
              columns={paymentColumns}
              dataSource={paymentData}
              className="table-dark pagination"
              showSorterTooltip={false}
              rowHoverable={true}
              pagination={false}
            />
          </Card>
          <ActiveInBranchProduct
            visible={selectBranch}
            inactiveBranch={true}
            onClose={() => setSelectBranch(false)}
          />
        </Col>
      </Row>
    </div>
  );
};

export { ProductInBranch };
