import React, { useState } from 'react'
import { Button, Card, Col, Divider, Flex, Image, Row, Space, Tabs, Typography } from 'antd'
import { ModuleTopHeading, AllPaymentMethodDetails, ActivePaymentDetails, InactivePaymentDetails, DeletedPaymentDetails, AddEditPaymentMethod, AlertModal, PaymentMethodSorter } from '../../components'


const { Text } = Typography
const PaymentMethodPage = () => {

    const [ deleteitem, setDeleteItem ] = useState(false)   
    const [ addpaymentmethod, setAddPaymentMethod ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ sortermodal, setSorterModal ] = useState(false)
    const [ restoremodal, setRestoreModal ] = useState(false)

    const itemstab = [
        {
            key: '1',
            label: 'All',
            children: <AllPaymentMethodDetails {...{setAddPaymentMethod,setEditItem}} />,
        },
        {
            key: '2',
            label: 'Active',
            children: <ActivePaymentDetails {...{setAddPaymentMethod,setEditItem}} /> ,
        },
        {
            key: '3',
            label: 'Inactive',
            children: <InactivePaymentDetails {...{setAddPaymentMethod,setEditItem}} /> ,
        },
        {
            key: '4',
            label: 'Deleted',
            children: <DeletedPaymentDetails {...{setAddPaymentMethod, setRestoreModal, setEditItem}} /> ,
        },
    ];
  return (
    <div>
        <Row gutter={[24, 24]} align={'middle'}>
            <Col span={24}>
                <Space  style={{justifyContent:'space-between'}} className='w-100' wrap>
                    <ModuleTopHeading name='Payment Methods' onClick={()=>{setAddPaymentMethod(true)}} />
                    <Button className='btncancel pad-filter bg-light-gray' onClick={()=> {setSorterModal(true)}}>
                        <Space>
                            <Image src='/assets/icons/sort.png' preview={false} width={18} />
                            <Text className='text-dark'>Sort Payment Methods</Text>
                        </Space>
                    </Button>
                </Space>
            </Col>
            <Col span={24}>
                <Card className='shadow-d radius-12 card-cs h-100'>
                    <Tabs defaultActiveKey="1" items={itemstab}  />
                </Card>
            </Col>
        </Row>

        <AddEditPaymentMethod 
            visible={addpaymentmethod}
            edititem={edititem}
            setDeleteItem={setDeleteItem}
            restoremodal={restoremodal}
            onClose={()=>{setAddPaymentMethod(false);setEditItem(null);setRestoreModal(false)}}
        />
        <AlertModal 
            visible={deleteitem}
            deleteitem={deleteitem}
            onClose={()=>setDeleteItem(false)}
        />

        <PaymentMethodSorter 
            visible={sortermodal}
            onClose={()=>setSorterModal(false)}
        />
    </div>
  )
}

export {PaymentMethodPage}