export const userbranchData = [
    {
        key: 1,
        branchName:'Branch 1',
        reference:'B01',
        taxgroup:'-',
        created:'October 08, 10:01 pm',
    },
    {
        key: 2,
        branchName:'Branch 2',
        reference:'B02',
        taxgroup:'-',
        created:'October 07, 10:01 pm',
    },
    {
        key: 3,
        branchName:'Branch 3',
        reference:'B03',
        taxgroup:'-',
        created:'October 06, 10:01 pm',
    },
]