import React, { useState } from 'react'
import { Button, Card, Flex, Form, Space, Table, Typography } from 'antd'
const { Text, Title } = Typography
const InActivePaymentMethodTable = ({setPaymentModal}) => {
    const [form] = Form.useForm();
    const [ showhide, setShowHide ] = useState(false)
    const [ addrole, setAddRole ] = useState(false)
    const [ editrole, setEditRole ] = useState(null)

    const columns = [
        {
            title: 'Payment Method',
            dataIndex: 'paymentmethod',
            width: 200,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 200,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 200,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 200,
        },
    ];
    const data = [
        {
            key: '1',
            paymentmethod: 'مدى',
            type:'Card',
            status: <Typography.Text className='text-inactive bg-light-red fs-12 badge-cs'>
                        Inactive
                    </Typography.Text>,
            action: <Typography.Text className='text-active radius-8 py-2 bg-light-green fs-12 badge-cs'>
                        Change to Active
                    </Typography.Text>,
        },
    ];

    return (
        <div>
            
                <Flex vertical gap={20}>
                    <Space style={{justifyContent:'space-between'}} className='w-100' wrap>
                        <Title level={5} className='m-0'>Inactive Payment Methods</Title>
                        <Button 
                            type="primary"
                            className='btncancel px-3 bg-light-gray'
                            icon={<img src='/assets/icons/payment-card.png' width={18} />}
                            onClick={()=>setPaymentModal(true)}
                        >
                            <Text className='text-black fs-13'>Select Payment Method</Text>
                        </Button>
                    </Space>
                    <Card className='shadow-c radius-12 border0 cardlg mb-3'>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            showSorterTooltip={false}
                            rowHoverable={true}
                            onRow={(record) => ({
                                onClick: () => {
                                    setAddRole(true);
                                    setEditRole(record)
                                },
                            })}
                            pagination={false}
                        />
                    </Card>
                </Flex>
        </div>
    )
}

export { InActivePaymentMethodTable }
