import React, { useState } from 'react'
import { Breadcrumb, Button, Card, Col, Flex, Image, Row, Space, Switch, Table, Typography } from 'antd'
import { ActionButton } from '../../../PageComponents';
import InactiveBranch from '../ModifierOptionModals/InactiveBranch';

const { Text, Title } = Typography
const InactiveBranchTable = ({setBranchModal,setEditBranch}) => {

    
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
    };
    const columns = [
        {
            title: 'Branch',
            dataIndex: 'branch',
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => (
                    <Space align="center">
                            <Text className={
                                status === 'Active' ? 
                                'text-active bg-light-green fs-12 badge-cs':
                                'text-inactive bg-light-red fs-12 badge-cs'
                            }>
                                {status}
                            </Text>
                    </Space>  
            ) 
          },
          {
            title: 'Action',
            key: 'action',
            width:200,
            render: (_, record) => (
              <Button
                className='text-active bg-light-green fs-12 badge-cs border0'
                onClick={() => {}}
              >
                Change to Action
              </Button>
            ),
          },  
        // {
        //     title: 'Action',
        //     dataIndex: 'Action',
        //     render: (_, row) => (
        //         <Space>
        //             <Switch size='small' defaultChecked onChange={onChange} />
        //             <ActionButton
        //                 title='Edit'
        //                 shape="circle"
        //                 icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
        //                 onClick={() => {setBranchModal(true);setEditBranch(row)}}
        //                 className=' bg-transparent'
        //             />
        //         </Space>
        //     ),
        // },
    ];
    const data = [
        {
            key: '1',
            branch: 'Branch 1',
            reference: 'B01',
            status: 'Active',
        },
    ];

    return (
        <div>
            <div>
                <Flex align='center' justify='space-between' wrap gap={10}> 
                    <Title level={5} className='m-0'>Inactivate in Branches</Title>
                    <Button className='btncancel px-3' onClick={()=>{setBranchModal(true)}}>
                        <Space>
                            <Image src='/assets/icons/branch-c.png' preview={false} width={18} />
                            <Text className='text-black fs-13'>Select Branches</Text>
                        </Space>
                    </Button>
                </Flex>
            </div>
            <Card className='shadow-d radius-12 card-cs'>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    showSorterTooltip={false}
                    rowHoverable={true}
                    pagination={false}
                />
            </Card>
                
                

        </div>
    )
}

export default InactiveBranchTable
