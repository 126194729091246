import { useState } from "react"
import { Form, Image, Button, Space, Row, Col, Typography, Flex, Checkbox } from "antd"
import {  MyInput } from '../../components'
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"
import "./index.css"
import { Link } from "react-router-dom"

const {Title, Text}= Typography

const Login = () => {

    // const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    // const { loading } = useSelector(state => state?.login)
    // const [forgotPassword, setForgotPassword]= useState(false)

    // const login = () => {
    //     if(forgotPassword)
    //         appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
    //     else
    //         appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))

    // }

    return (
        <Flex className="login-form-cover" justify="center" align="center">
            <div className="login-form">
                <div className="center login-form-left-side">
                    <Image 
                        src="/assets/images/white-logo.png" 
                        preview={false} 
                        alt="tag-dev-logo"
                    />
                    <div>
                        <Text strong className="py-3 text-white">
                            Version 0.0 Beta Release
                        </Text>
                    </div>
                </div>
                <div 
                    style={{alignItems: 'center', display:'flex'}}
                    className="login-form-right-side px-3 py-4"
                >
                    <Space 
                        direction='vertical' 
                        className='justify-center w-100'
                        size={10}
                    >
                        {/* <Image 
                            src="/assets/images/logo-cr.png" 
                            width={'70px'} 
                            preview={false} 
                            alt="tag-dev-logo"
                        /> */}
                        <Space direction="vertical">
                            <Title level={3} className="my-0">
                                Welcome to TagDev System!
                            </Title>
                            <Text className="text-gray">
                                Enter the credentials to login to the system.
                            </Text>
                        </Space>
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            style={{width: '100%'}}
                            // onFinish={login}
                            requiredMark={false}
                        >
                            <Row>
                                <Col span={24}>
                                    <MyInput
                                        autoFocus
                                        name="accountNo"
                                        type='number'
                                        label="Account Number"
                                        required
                                        size={'large'}
                                        message='Enter account number'
                                        placeholder='Enter account number'
                                        value={form.getFieldValue("accountNo") || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <MyInput
                                        autoFocus
                                        name="email"
                                        label="Email Address"
                                        required
                                        size={'large'}
                                        message='Enter email address'
                                        placeholder='Enter email address'
                                        value={form.getFieldValue("email") || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <MyInput
                                        name="password"
                                        type='password'
                                        label="Password"
                                        required
                                        size={'large'}
                                        message='Enter password'
                                        placeholder='Enter password'
                                        value={form.getFieldValue("password") || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Flex justify="space-between" className="mb-3">
                                        <Checkbox>Checkbox</Checkbox>
                                        <Link to={'/forgotpass'} className="fs-13 text-gray">
                                            Forget Password?
                                        </Link>
                                    </Flex>
                                </Col>
                                <Col span={24} className="center">
                                    <Button 
                                        htmlType="submit" 
                                        size="large"
                                        block
                                        // loading={loading}
                                        className="fs-14 brand-bg text-white"
                                        >
                                        Signin
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    <Flex justify="center" gap={3} className="py-1">
                                        <Text className="fs-13 text-gray">Don't have an account?</Text>  
                                        <Link to={''} className="fs-13 text-brand">
                                            Try TagDev for free (14 days free trial)
                                        </Link>
                                    </Flex>
                                </Col>
                            </Row>
                        </Form>
                    </Space>
                </div>
            </div>
        </Flex>
    )
}
export {Login}