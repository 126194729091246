import React, { useState } from 'react'
import { CalendarDropBorder, ModuleTopHeading, PaymentMethodFilterModal, PaymentReportBarChart, PaymentReportTable, SearchInput } from '../../components'
import { Button, Card, Checkbox, Col, Dropdown, Flex, Form, Row, Space, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs';

const { Text } = Typography
const PaymentReport = () => {

  const [ filtermodal, setFilterModal ] = useState(false)
  const [ order, setOrder ] = useState(0);
  const [ showbtn, setShowBtn ] = useState(false)
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#" className='fs-13'>To CSV</a>, key: 0 },
    { label: <a href="#" className='fs-13'>To Excel</a>, key: 1 },
  ];

  const groupbynone = [
    { label: <Link href="#" className='fs-13'>None</Link>, key: 0 },
    { label: <Link href="#" className='fs-13'>Branch</Link>, key: 1 },
    { label: <Link href="#" className='fs-13'>Cashier</Link>, key: 2 },
    { label: <Link href="#" className='fs-13'>Hour of Day</Link>, key: 3 },
    { label: <Link href="#" className='fs-13'>Day</Link>, key: 4 },
    { label: <Link href="#" className='fs-13'>Week</Link>, key: 5 },
    { label: <Link href="#" className='fs-13'>Month</Link>, key: 6 },
    { label: <Link href="#" className='fs-13'>Quarter</Link>, key: 7 },
    { label: <Link href="#" className='fs-13'>Year</Link>, key: 8 },
  ]

  const onClick = ({ key }) => {
    //   key = parseInt(key) + 1;
      setOrder(key);
  };

  const presets = [
    {
        label: 'Today',
        value: dayjs().add(0, 'd'),
    },
    {
        label: 'Yesterday',
        value: dayjs().add(-1, 'd'),
    },
    {
        label: 'This Week',
        value: dayjs().add(7, 'd'),
    },
    {
        label: 'Last Week',
        value: dayjs().add(-7, 'd'),
    },
    {
        label: 'This Month',
        value: dayjs().add(1, 'month'),
    },
    {
        label: 'Last Month',
        value: dayjs().add(-1, 'month'),
    },
  ]

  const dropdownContent = (
      <Card className='radius shadow-c card-cs'>
          <Form form={form} layout="vertical">
              <SearchInput 
                  name='search'
                  value={form.getFieldValue('search') || ''}
                  placeholder='Search...'
              />
          </Form>
          <Space direction='vertical' size={10} className='pt-3'>
              <Text>All Branches</Text>
              <Checkbox>Branch 1 (B01)</Checkbox>
          </Space>
      </Card>
    );

  return (
    <div>
        <Row gutter={[24,24]}>
          <Col lg={{span:8}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                <ModuleTopHeading name='Payments'/>
          </Col>
          <Col lg={{span:16}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
            <Space size={10} className='w-100 right mbl-space' wrap>
                <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                        icon={<img src='/assets/icons/export-filter.png' width={18}/>}
                    >
                        <Flex justify='space-between' className='w-100' gap={30}>
                            Export
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <Dropdown
                    menu={{ items:groupbynone,onClick }}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                    >
                        <Flex justify='space-between' className='w-100' gap={30}>
                            Group By {
                                order == '0' ? 'None' :
                                order == '1' ? 'Branch' :
                                order == '2' ? 'Cashier' :
                                order == '3' ? 'Hour of Day' :
                                order == '4' ? 'Day' :
                                order == '5' ? 'Week' :
                                order == '6' ? 'Month' :
                                order == '7' ? 'Quarter' :
                                order == '8' ? 'Year' : null
                            }
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <Dropdown
                    overlay={dropdownContent}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black mbl-btn'
                        icon={<img src='/assets/icons/branch-c.png' width={18}/>}
                    >
                        <Flex justify='space-between' className='w-100' gap={30}>
                            Branches 
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
                <CalendarDropBorder presets={presets} />
            </Space>
          </Col>
          {
            order == 0 &&
            <Col span={24}>
            <Flex justify='end'> 
                <Button className='btnsave brand-bg text-white pad-filter'
                    icon={<img src='/assets/icons/graph-ic.png' width={18} />}  
                    onClick={()=>setShowBtn(!showbtn)}  
                >
                    { !showbtn ? 'Show' : 'Hide' } Graph
                </Button>
            </Flex>
          </Col>
          }
          {
            order == 0 && showbtn &&
            <Col span={24}>
                <PaymentReportBarChart />
            </Col>
          }
          <Col span={24}>
            <Card className='shadow-d radius-12 card-cs'>
                <PaymentReportTable {...{filtermodal, setFilterModal, order}} />
            </Card>
          </Col>
        </Row>

      <PaymentMethodFilterModal 
        visible={filtermodal}
        onClose={()=>setFilterModal(false)}
      />
    </div>
  )
}

export {PaymentReport}