import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Image, Tooltip } from 'antd'
import { MyInput } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const AddModifier = ({visible,onClose,edititem,setDeleteItem}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    const deleteHandle = () => {
        setDeleteItem({name: edititem})
        onClose()
    }

  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space className={edititem ? "space-between-align" : "center" }>
                    <Space>
                    {edititem && (<Button  
                            block
                            type="primary"
                            className='btnsave text-white bg-red px-2'
                            loading={loading}
                            onClick={deleteHandle}
                            
                        >
                            Delete Modifier
                        </Button>)}
                    </Space>
                  <Space className='space-between-align'>
      
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        { edititem ? "Update" : "Save" }
                    </Button>
                  </Space>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{edititem? 'Edit Modifier' : 'Add Modifier'}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Modifier Name
                                <Tooltip title='The name of the modifier'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='name'
                            required
                            message='Please enter name'
                            value={form.getFieldValue('name') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Name Localized
                                <Tooltip title='Enter the localized name in 2nd language of your own choice'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='localization'
                            required
                            message='Please enter name localization'
                            value={form.getFieldValue('localization') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Reference
                                <Tooltip title='Number or Letters help to reach modifier.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='ref'
                            required
                            message='Please enter reference'
                            value={form.getFieldValue('ref') || ''}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddModifier}