import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Table, Typography } from 'antd';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { devicesData } from '../../../../Data';

const { Text } = Typography
const DevicesCashierTable = ({setForceSync})=> {

  const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const cashierdata = devicesData.filter((cash)=>cash.type === 'Cashier')

    const columns = [
      {
          title: 'Device Name',
          dataIndex: 'devicename',
          sorter: (a,b) => a.devicename - b.devicename,
      },
      {
          title: 'Reference',
          dataIndex: 'ref',
      },
      {
        title: 'Branch',
        dataIndex: 'branch',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width:180,
        render: (status) => (
          <Space align="center">
                  <Text className={
                      status === 'New' ? 
                      'text-active bg-light-green fs-12 badge-cs':
                      'text-inactive bg-light-red fs-12 badge-cs'
                  }>
                      {status}
                  </Text>
          </Space>  
          )
      },
  ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
      };

  return (
    <div>
        
        <Table 
            size='large'
            columns={columns} 
            dataSource={cashierdata} 
            className='table-dark pagination'
            showSorterTooltip={false}
            rowSelection={rowSelection}
            rowHoverable={true}
            // showHeader={selectedRows.length === 0}
            onRow={(record) => ({
                onClick: (event) => {
                    const isActionColumn = event.target.closest('.action-column');
                    if (!isActionColumn && record.key) {
                      navigate(`/devices/singleview/${record.key}`);
                    }
                },
            })}
            title={ selectedRows.length > 0 ? (record) => (
              <div>{selectedRows.length} Selected  
                  <Dropdown
                      menu={{ items: [
                          { label: <Link onClick={()=>{setForceSync(true) }} className='fs-13 action-column'>Force Sync Data</Link>, key: 0 },
                      ] }}
                      trigger={['click',]}
                      className='margin-top'
                  >
                      <Button className='btncancel px-2 py-1 bg-light-gray mx-2'>
                          <Space>
                              <Text className='text-black fs-13'>Actions</Text>
                              <DownOutlined /> 
                          </Space>
                      </Button>
                  </Dropdown>
              </div> 
          ) : null}
            pagination={{
                hideOnSinglePage: true,
                total: 12,
                // pageSize: pagination?.pageSize,
                // defaultPageSize: pagination?.pageSize,
                // current: pagination?.pageNo,
                // size: "default",
                // pageSizeOptions: ['10', '20', '50', '100'],
                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
            }}
        />
    </div>
  )
}

export {DevicesCashierTable}