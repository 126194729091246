import React, { useState } from 'react'
import { Button, Dropdown,Space, Table } from 'antd'
import { Link } from 'react-router-dom'

const RolePermissionTable = ()=> {
  const [selectedRows, setSelectedRows] = useState([]);
  
  const columns = [
      {
          title: 'Role Name',
          dataIndex: 'role',
          sorter: (a,b) => a.role - b.role,
      },
      {
            title: 'Action',
            key: 'action',
            align:'center',
            render: (_, row) => (
                <Space>
                    <Dropdown
                        menu={{ items: [
                            { label: <Link className='fs-13' onClick={() => {}}>Edit</Link>, key: 0 },
                            { label: <Link className='fs-13' onClick={() => {}}>Delete</Link>, key: 2 }
                        ] }}
                        trigger={['click']}
                        className='margin-top border-circle cursor'
                        placement='bottom'
                    >
                        <img src='/assets/icons/3dots.png' width={18} />
                    </Dropdown>
                </Space>
            ),
        },
  ];
  const data =[
    {
      key:1,
      role:"Admin"
    },
    {
      key:2,
      role:"Data Entry"
    },
    {
      key:3,
      role:"Accountant"
    },
  ]
      
  const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows);
      },
    };
  return (
    <div>
      <Table 
        size='large'
        columns={columns} 
        dataSource={data}
        className='table-dark pagination'
        showSorterTooltip={false}
        rowSelection={rowSelection}
        rowHoverable={true}
        pagination={{
            hideOnSinglePage: true,
            total: 12,
            // pageSize: pagination?.pageSize,
            // defaultPageSize: pagination?.pageSize,
            // current: pagination?.pageNo,
            // size: "default",
            // pageSizeOptions: ['10', '20', '50', '100'],
            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
        }}
      />
    </div>
  )
}

export  {RolePermissionTable}