import { Button, Image, Modal, Space, Typography } from 'antd'
import React from 'react'

const {Title, Text} =Typography

const ForceSyncData = ({visible, onClose}) => {
    return (
      <div>
          <Modal width={500} className='shadow-c radius-12'  open={visible} onOk={onClose} onCancel={onClose} centered footer={null}>
              <Space className='w-100 py- text-center' align='center' size={15} direction='vertical'>
                  <Image src='/assets/icons/alert-red.png' width={50} preview={false} 
                  />
                  <Title level={5} className='my-3'>Alert</Title>
                  <Text className='text-center'>
                  {
                      "You are about to force sync this device. This action will prompt users of the device to sync data first before proceeding. Are you sure you want to proceed?"
                  }
                  </Text>
                  <Space style={{justifyContent:"center",marginTop:'15px'}} className="w-100">
                      <Button
                          onClick={onClose}
                          className='btncancel border-gray'
                      >
                          Cancel
                      </Button>
                      <Button  
                          block
                          type="primary"
                          className='btnsave text-white bg-red' 
                          // loading={loading}
                          onClick={
                              // DeleteProductApi();
                              onClose
                          }
                          
                      >
                          Confirm 
                      </Button>
                  </Space>
              </Space>
          </Modal>
      </div>
    )
  
}

export {ForceSyncData}