export const orderData = [
    {
        key: '1',
        refNo:'2798',
        callNo: '540',
        branch:'Branch 1',
        cashier:'Nadeem Abbas',
        total:'453',
        paymentmethod:'Cash',
        status:'Paid',
        businessdate:'12/02/2024',
        time:'01:03 11/02/2024',
    },
    {
        key: '2',
        refNo:'8811',
        callNo: '583',
        branch:'Branch 2',
        cashier:'Fahd Bakir',
        total:'738',
        paymentmethod:'Card',
        status:'Refund',
        businessdate:'21/02/2024',
        time:'04:03 21/02/2024'
    },
    {
        key: '3',
        refNo:'5626',
        callNo: '738',
        branch:'Branch 3',
        cashier:'Abbas Ali',
        total:'196',
        paymentmethod:'Cash',
        status:'Partial Refund',
        businessdate:'30/02/2024',
        time:'10:20 30/02/2024'
    },
    {
        key: '4',
        refNo:'5536',
        callNo: '326',
        branch:'Branch 5',
        cashier:'Zaman Ali',
        total:'1044',
        paymentmethod:'Custom',
        status:'Hold',
        businessdate:'24/04/2024',
        time:'12:03 25/04/2024'
    },
];