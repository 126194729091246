import { Card, Col, Row, Space, Table, Typography } from 'antd'
import React from 'react'
const { Text, Title } = Typography
const OrderProductTable = ({order}) => {

  const productColumns = [
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        
    },
    {
        title: 'Product',
        dataIndex: 'product',
        
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitprice',
      
    },
    {
      title: 'Notes',
      dataIndex: 'note',
    },
    {
      title: 'Total Price',
      dataIndex: 'totalprice',
      render: (text) => (
        order.status === "Refund" ? 
          <Space>
            <Text className='text-inactive bg-light-red fs-12 badge-cs'>Refund</Text> 
            <Text delete>{text}</Text>
          </Space> : <Text>{text}</Text>
      ),
    },
];
const productData = [
    {
        key: '1',
        quantity: 1,
        product:`قهوة مقطر `,
        unitprice: "SAR 03.54",
        note: '-',
        totalprice: 'SAR 13.54'
    },
    {
      key: '2',
      quantity: 1,
      product: "كوارتر سويت",
      unitprice: "SAR 03.54",
      note: '-',
      totalprice: 'SAR 13.54'
    },
];

  return (
      <div>
        <div className='mb-3'>
          <Title level={5} className='m-0'>Products</Title>
        </div>
        <Card className='shadow-d radius-12 card-cs'>
          <Table
              size='large'
              columns={productColumns} 
              dataSource={productData} 
              scroll={{x: 1000}}
              className='table-dark pagination'
              showSorterTooltip={false}
              rowHoverable={true}
              pagination={false}
          />
        </Card>
      </div>
    )

}

export {OrderProductTable}