import React, { useState } from 'react'
import { Modal, Space, Button, Form, Flex, Typography, Tooltip, Image, Divider } from 'antd'
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const RestoreTax = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    const data = [
        {
            title:'Tax Name',
            content:'Tax 1',
            info:'The name of the tax',
        },
        {
            title:'Name Localized',
            content:'-',
            info:'Enter the localized name in the second language of your own choice',
        },
        {
            title:'Rate (%)',
            content:'10',
            info:'Enter the tax percentage value',
        },
        {
            title:'Applies On',
            content:'Pick Up , Dine In'
        },
    ]
    
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent: 'center' }} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Restore
                    </Button>
                </Space>
        }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Restore Tax</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <div className='content-modal'>
                {
                    data?.map((restore,index)=>
                        <>
                        <Flex vertical key={index}>
                            <Space>
                                <Text className='text-gray'>{restore?.title}</Text>
                                {
                                    restore?.info &&
                                    <Tooltip title={restore?.info}>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                }
                            </Space>
                            <Text>
                                {
                                    restore?.content
                                }
                            </Text>
                        </Flex>
                        {
                            index < data?.length - 1 &&
                            <Divider className='my-3'/>
                        }
                        </>
                    )
                }
            </div>
        </Modal>
    </div>
  )
}

export {RestoreTax}