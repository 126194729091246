import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Image, Row, Space, Table, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { ActionButton } from '../../../PageComponents';
import { Addrole } from '../UserModals';
import { AlertModal } from '../../../Modals';

const { Text, Title } = Typography
const UserRoleTable = () => {
    const [form] = Form.useForm();
    const [ showhide, setShowHide ] = useState(false)
    const [ addrole, setAddRole ] = useState(false)
    const [ editrole, setEditRole ] = useState(null)

    const columns = [
        {
            title: 'Roles',
            dataIndex: 'role',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            role: 'Admin',
        },
    ];

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg mb-3'>
                <Flex vertical gap={20} wrap>
                    <Space style={{justifyContent:'space-between'}} className='w-100'>
                        <Title level={5} className='m-0'>Roles</Title>
                        <Button 
                            type="primary"
                            className='btncancel px-3 bg-light-gray'
                            icon={<PlusOutlined className='text-black' />}
                            onClick={()=>{setAddRole(true);setShowHide(true)}}
                        >
                            <Text className='text-black fs-13'>Add Roles</Text>
                        </Button>
                    </Space>
                    {
                        !showhide?(
                            <Text className='text-gray fs-13 text-center'>
                                Assign a role to this user to control which features they can access.
                            </Text>
                        ) : (
                            <Table 
                                size='large'
                                columns={columns} 
                                dataSource={data} 
                                scroll={{x: 1000}}
                                className='table-dark pagination'
                                showSorterTooltip={false}
                                rowHoverable={true}
                                onRow={(record) => ({
                                    onClick: () => {
                                        setAddRole(true);
                                        setEditRole(record)
                                    },
                                })}
                                pagination={false}
                            />
                        )

                    }
                </Flex>
            </Card>
            <Addrole 
                visible={addrole}
                editrole={editrole}
                onClose={()=>{setAddRole(false);setEditRole(null)}}
            />
        </div>
    )
}

export { UserRoleTable }
