import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Image, Tooltip, Input, Checkbox } from 'antd'
import { MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const AddUsers = ({visible,onClose,edititem}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [ randomvalue, setRandomValue ] = useState(null)
    const [form] = Form.useForm()
    
    const GenerateValue = () => {
        setRandomValue(
            Math.floor(Math.random()*90000) + 10000
        )
    }
  return (
    <div>
        <Modal 
            width={600} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Flex justify='space-between'>
                    {
                        edititem && 
                        <Button  
                            type="primary"
                            className='btnsave text-white bg-red'
                            
                        >
                            Delete User
                        </Button>
                    }
                    <Space style={{justifyContent: edititem ? "end" : "center"}} className="w-100">
                        <Button
                            onClick={onClose}
                            className='btncancel'
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            className='btnsave text-white brand-bg'
                            loading={loading}
                            onClick={()=>{
                                // DeleteProductApi();  
                            }}
                            
                        >
                            {
                                edititem? 'Update' : 'Save'
                            }
                        </Button>
                    </Space>
                </Flex>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{edititem? 'Edit User' : 'Add User'}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                User Name
                                <Tooltip title='The name of the user'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='username'
                            required
                            message='Please enter user name'
                            value={form.getFieldValue('username') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect 
                            label={<Space>
                                Language
                                <Tooltip title='The user preferred interface language'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name="language" 
                            required
                            message='Please choose language'
                            value={form.getFieldValue("language") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Arabic'
                                },
                                {
                                    key: 2,
                                    name: 'English'
                                },
                            ]}
                        />
                    </Col>
                    {
                        edititem && 
                        <>
                            <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Employee Number
                                        <Tooltip title="Enter employee number">
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name='empno'
                                    type='number'
                                    value={form.getFieldValue('empno') || ''}
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <MyInput 
                                    label={<Space>
                                        Phone Number
                                        <Tooltip title="Enter phone number">
                                            <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                        </Tooltip>
                                    </Space>}
                                    size='large'
                                    name='phoneNo'
                                    type='number'
                                    value={form.getFieldValue('phoneNo') || ''}
                                />
                            </Col>
                        </>
                    }
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Email Address
                                <Tooltip title="The user email to login to the console. It's not needed if the user will only use Cashier App.">
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='email'
                            type='number'
                            value={form.getFieldValue('email') || ''}
                        />
                    </Col>
                    {
                        !edititem &&
                        <Col span={24}>
                            <MyInput 
                                label={<Space>
                                    Password
                                    <Tooltip title='Th password to login to the console'>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                </Space>}
                                size='large'
                                name='password'
                                type='password'
                                value={form.getFieldValue("password") || ''}
                            />
                        </Col>
                    }
                    <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Space className='w-100 space-cs'>
                            <MyInput 
                                label={<Space>
                                    Login Pin
                                    <Tooltip title='This should be a unique 5 digit PIN the user will use to login to the Cashier App. Generate a random PIN for better security.'>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                </Space>}
                                size='large'
                                type='number'
                                value={randomvalue}
                                className='w-100'
                            />
                            <Button 
                                className='pad-x fs-13 border-gray text-black h-40 margintop-5' 
                                onClick={GenerateValue}
                            >
                                Generate
                            </Button>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Checkbox>
                            <Space>
                                Display Localized Name
                                <Tooltip title='Show the Console and Cashier App in localized language.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>
                        </Checkbox>   
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddUsers}