import React, { useState } from 'react'
import {  Card, Col, Row } from 'antd'
import {  AddRolePermission, ModuleTopHeading, RolePermissionTable } from '../../components'

const RolePermissionPage = ()=> {
  const [addRolePermission, setAddRolePermission ] = useState(false)
  
  return (
    <div>
      <Row gutter={[24, 24]} align={'middle'}>
          <Col span={24}>
              <ModuleTopHeading name='Roles' onClick={()=>{setAddRolePermission(true)}} />
          </Col>
          <Col span={24}>
              <Card className='shadow-d radius-12 card-cs'>
                <RolePermissionTable />  
              </Card>
          </Col>
      </Row>
      <AddRolePermission
      visible={addRolePermission}
      // removertext={removertext}
      onClose={()=> { setAddRolePermission(false)}}
      />
    </div>
  )
}

export {RolePermissionPage}



