import React, { useState } from 'react'
import { Breadcrumb, Button, Card, Col, Dropdown, Flex, Form, Image, Row, Space, Tabs, Typography } from 'antd'
import { AlertModal, ModuleTopHeading, FilterProduct, ProductImport, ProductImportModifier, AddEditProduct, AllProductTable, ActiveProductTable, InactiveProductTable, DeletedProductTable, ActiveInBranchProduct, TaxGroupProduct } from '../../components'
import { DownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'


const { Text } = Typography
const ProductsPage = ({ showbread, contentmodifier }) => {

    const [order, setOrder] = useState(1)

    const [ switchtext, setSwitchText ] = useState(false)
    const [ status , setStatus ] = useState(false)

    const [ visible, setVisible ] = useState( false )
    const [ filterProduct, setFilterProduct ] = useState( false )
    const [ importModal , setImportModal ] = useState(false)
    const [ importModifierModal , setImportModifierModal ] = useState(false)
    
    const [ activeBranch, setActiveBranch ] = useState(false)
    const [ inactiveBranch, setInactiveBranch ] = useState(false)
    const [ taxGroup, setTaxGroup ] = useState(false)
    const [ removeTax, setRemoveTax ] = useState(false)
    const [ deletedProduct, setDeletedProduct ] = useState(false)


    const navigate = useNavigate();
    const items = [
        { label: <a href="#" className='fs-13' onClick={()=>setImportModal(true)}>Import Products</a>, key: 0 },
        { label: <a href="#" className='fs-13' onClick={()=>setImportModifierModal(true)}>Import Product Modifiers</a>, key: 1 },
        { label: <a href="#" className='fs-13'>Export Products</a>, key: 2 },
        { label: <a href="#" className='fs-13'>Export Product Modifiers</a>, key: 3 },
    ];

    const onClick = ({ key }) => {
        // key = parseInt(key) + 1;
        setOrder(key);
        // filter(key);
    };

    
    const productstab = [
        {
            key: '1',
            label: 'All',
            children: <AllProductTable {...{ 
             setStatus, setSwitchText, setActiveBranch ,setInactiveBranch , setTaxGroup , setRemoveTax , setDeletedProduct, deletedProduct
            }} />,
        },
        {
            key: '2',
            label: 'Active',
            children: <ActiveProductTable {...{ 
             setStatus, setSwitchText, setActiveBranch ,setInactiveBranch , setTaxGroup , setRemoveTax , setDeletedProduct
            }} />,
        },
        {
            key: '3',
            label: 'Inactive',
            children: <InactiveProductTable {...{ 
             setStatus, setSwitchText, setActiveBranch ,setInactiveBranch , setTaxGroup , setRemoveTax , setDeletedProduct
            }} />,
        },
        {
            key: '4',
            label: 'Deleted',
            children: <DeletedProductTable {...{ 
             setStatus, setSwitchText, setActiveBranch ,setInactiveBranch , setTaxGroup , setRemoveTax , setDeletedProduct
            }} />,
        },
    ];

  return (
    <div>
        <Row gutter={[16,16]}>
            {
                showbread === true ?
                <Col span={24}>
                    <Breadcrumb
                        routes={[
                        {
                            breadcrumbName: 'Menu',
                        },
                        {
                            onClick: () => navigate('/modifier'),
                            breadcrumbName: `Modifiers - ${contentmodifier}`,
                        },
                        {
                            breadcrumbName: 'Linked Products',
                        },
                        ]}
                    />
                </Col>
                :<></>
            }
            <Col span={24} >
                
                    <Row gutter={[24, 24]} align={'middle'}>
                        <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
                            <ModuleTopHeading name='Products' onClick={()=>{setVisible(true)}} />
                        </Col>
                        <Col lg={{span:12}} md={{span: 10}} sm={{span: 24}} xs={{span: 24}}>
                            <Space size={10} className='w-100 right' wrap>
                                <Button className='btncancel pad-filter' onClick={()=> setFilterProduct(true)}>
                                    <Space>
                                        <Image src='/assets/icons/filter-outline.png' preview={false} width={18} />
                                        <Text className='text-black'>Filters</Text>
                                    </Space>
                                </Button>
                                <Dropdown
                                    menu={{ items, onClick }}
                                    trigger={['click']}
                                    className='margin-top'
                                >
                                    <Button className='btncancel pad-filter'
                                        icon={<img src='/assets/icons/export-filter.png' width={20}/>}
                                    >
                                        <Flex justify='space-between' gap={30}>
                                            Export / Import
                                            <DownOutlined />    
                                        </Flex>  
                                    </Button>
                                </Dropdown>
                            </Space>
                        </Col>
                        <Col span={24}>
                            <Card className='shadow-d radius-12 card-cs'>
                                <Tabs defaultActiveKey="1" items={productstab}  />
                            </Card>
                        </Col>
                    </Row>
            </Col>
        </Row>
        <AddEditProduct
            visible={visible}
            onClose={()=>{setVisible(false)}}
        />
        <FilterProduct
            visible={filterProduct}
            onClose={()=>{setFilterProduct(false)}}
        />

        <ProductImport 
            visible={importModal}
            onClose={()=>{setImportModal(false)}}
        />

        <ProductImportModifier
            visible={importModifierModal}
            onClose={()=>{setImportModifierModal(false)}}
        />

        {/* Product Table Action Button Modals  */}
        {/* Active product */}
        <AlertModal 
            visible={status}
            switchtext={switchtext}
            onClose={()=>{setStatus(false); setSwitchText(null)}}
        />

        
       <AlertModal 
            visible={deletedProduct}
            switchtext={switchtext}
            deleteitem={deletedProduct}
            onClose={()=>{setDeletedProduct(false);setSwitchText(null)}}
        />
        <ActiveInBranchProduct 
            visible={activeBranch}
            inactiveBranch={inactiveBranch}
            onClose={()=>{setActiveBranch(false); setInactiveBranch(false)}}
        />
        <TaxGroupProduct 
            visible={taxGroup}
            removeTaxGroup={removeTax}
            onClose={()=>{setTaxGroup(false); setRemoveTax(false)}}
         />

    </div>
  )
}

export {ProductsPage}