export const menuengineeringData = [
    {
        product:'سينابون',
        sales:'SAR 222',
        quantity:'24',
        totalcost:'SAR 0',
        itemprofit:'SAR 9.25',
        totalprofit:'SAR 222',
        profitpercentage:'0.33 %',
        popularity:'0.275 %',
        profitcategory:'High',
        popularitycategory:'Low',
        class:'Challenge',
    },
    {
        product:'كرسبي',
        sales:'SAR 222',
        quantity:'24',
        totalcost:'SAR 0',
        itemprofit:'SAR 9.25',
        totalprofit:'SAR 222',
        profitpercentage:'0.33 %',
        popularity:'0.275 %',
        profitcategory:'High',
        popularitycategory:'High',
        class:'Dog',
    },
    {
        product:'قهوة اليوم',
        sales:'SAR 222',
        quantity:'24',
        totalcost:'SAR 0',
        itemprofit:'SAR 9.25',
        totalprofit:'SAR 222',
        profitpercentage:'0.33 %',
        popularity:'0.275 %',
        profitcategory:'Low',
        popularitycategory:'High',
        class:'Workhorse',
    }
]