import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Image, Upload, message, Tooltip } from 'antd'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography
const { Dragger } = Upload;

const AddEditCategory = ({visible,onClose,edititem,setDeleteItem}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    

    const props = {
        name: 'image',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };


      const handleDelete = () => {
        setDeleteItem({name: edititem})
        onClose()
      }

  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space className={edititem ? "space-between-align" : "center" }>
                    <Space>
                    {edititem && (<Button  
                            block
                            type="primary"
                            className='btnsave text-white bg-red px-2'
                            loading={loading}
                            onClick={handleDelete}
                            
                        >
                            Delete Category
                        </Button>)}
                    </Space>
                  <Space className='space-between-align'>
      
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        { edititem ? "Update" : "Save" }
                    </Button>
                  </Space>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{edititem? 'Edit Category' : 'Add Category'}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Form.Item name='image' >
                            <div className='width-drag'>
                                <Dragger {...props} className="dragcs">
                                    <p className="ant-upload-drag-icon mb-0">
                                        <Image src='/assets/icons/plus.png' width={'41.6px'} preview={false} />
                                    </p>
                                    <Typography.Text className="fs-14 fw-500">Upload Image</Typography.Text>
                                </Dragger>
                            </div>
                            {/* {
                                editproperty &&
                                <Space direction='vertical' size={10}>
                                    {
                                        geteditdata?.medical_report?.map((data,index)=>
                                            <div className='viewDownload space-between-align' key={index}>
                                                <Typography.Text className='gray-text fs-12'>
                                                    {
                                                        data?.name
                                                    }
                                                </Typography.Text>
                                                <ActionButton
                                                    title='Delete'
                                                    icon={<DeleteOutlined className='danger-color' />}
                                                    onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                    type='ghost'
                                                />
                                            </div>
                                        )
                                    }
                                </Space>
                                
                            } */}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Category Name
                                <Tooltip title='Enter category name'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='name'
                            required
                            message='Please enter name'
                            value={form.getFieldValue('name') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Name Localization
                                <Tooltip title='Enter the localization name in the second language of your choice.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='localization'
                            required
                            message='Please enter name localization'
                            value={form.getFieldValue('localization') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Category Reference
                                <Tooltip title='Unique code for the category. The reference may only contain letters, numbers, dashes and underscores.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='ref'
                            required
                            message='Please enter reference'
                            value={form.getFieldValue('ref') || ''}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddEditCategory}