import { CloseOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Flex, Form, Image, Modal, Row, Space, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { MyInput, MySelect } from '../../../Forms'

const {Text} = Typography
const EditDeviceSettings = ({visible , onClose}) => {
  const [ loading, setLoading ] = useState(false)
  const [form] = Form.useForm()

  return (
    <div>
        <Modal 
            width={650} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
              <Space style={{justifyContent:"center"}} className="w-100">
              <Button
                  onClick={onClose}
                  className='btncancel'
              >
                  Cancel
              </Button>
              <Button  
                  block
                  type="primary"
                  className='btnsave text-white brand-bg'
                  loading={loading}
                  onClick={()=>{
                      // DeleteProductApi();  
                  }}
                  
              >
                  Save
              </Button>
          </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{"Edit Device Settings"}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Call Number Start
                                <Tooltip title='The number of the first order in a business day'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='callNoStart'
                            type='number'
                            value={form.getFieldValue('callNoStart') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Call Number Reset
                                <Tooltip title='The number in which the ordering numbering sequence will reset to Call Number Start when reached.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='callNoReset'
                            type='number'
                            value={form.getFieldValue('callNoReset') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label={<Space>
                              Default Order Type for New Orders
                              <Tooltip title='Choose a default order type when the cashier makes new orders. Cashier can still change the order type from the iPad'>
                                  <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                              </Tooltip>
                          </Space>}
                            name="defaultOrder" 
                            size={'large'}
                            mode='multiple'
                            required
                            message='Please choose Default Order'
                            value={form.getFieldValue("defaultOrder") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Dine in'
                                },
                                {
                                    key: 2,
                                    name: 'Pickup'
                                },
                                {
                                  key: 3,
                                  name: 'Drive thru'
                                },
                            ]}
                        />
                    </Col> 
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label={<Space>
                              Disable Order Types 
                              <Tooltip title='Choose the order types to disable in this cashier'>
                                  <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                              </Tooltip>
                          </Space>}
                            name="disableOrder"
                            size={'large'}
                            mode='multiple'
                            required
                            message='Please choose type'
                            value={form.getFieldValue("disableOrder") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Dine in'
                                },
                                {
                                    key: 2,
                                    name: 'Pickup'
                                },
                                {
                                  key: 3,
                                  name: 'Drive thru'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Send End of Day Report To Email
                                <Tooltip title="Enter 1 email to receive EOD report">
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='enddayreportemail'
                            type='email'
                            placeholder="abc@gmail.com"
                            value={form.getFieldValue('enddayreportemail') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Send Shift Report To Email
                                <Tooltip title="Enter 1 email to receive shift report">
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='shiftreportemail'
                            type='email'
                            placeholder="abc@gmail.com"
                            value={form.getFieldValue('shiftreportemail') || ''}
                        />
                    </Col>
                    <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label={<Space>
                                Send Till Report To Email
                                <Tooltip title="Enter 1 report to receive Till report">
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='tillreportemail'
                            type='email'
                            placeholder="abc@gmail.com"
                            value={form.getFieldValue('tillreportemail') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <Checkbox>
                            <Space>
                                Auto pair with other devices
                                <Tooltip title='Allow the cashier to pair automatically with other devices in the same network in the same branch.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>
                        </Checkbox>   
                    </Col>
                    <Col span={24}>
                        <Checkbox>
                            <Space>
                            Enable barcode scanners
                                <Tooltip title='Allow the cashier to use a barcode reader.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>
                        </Checkbox>   
                    </Col>
                    <Col span={24}>
                        <Checkbox>
                            <Space>
                            Auto send ahead order to kitchen
                                <Tooltip title='Choose to send ahead order automatically to the kitchen before a special amount of time.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>
                        </Checkbox>   
                    </Col>
                    <Col span={24}>
                        <Checkbox>
                            <Space>
                            Disable automatic receipt printing
                                <Tooltip title='Enable this setting to stop printing receipts automatically  after adding order payments or closing orders.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>
                        </Checkbox>   
                    </Col>
                    <Col span={24}>
                        <Checkbox>
                            <Space>
                            Print drawer operations
                                <Tooltip title='Enable this setting to automatically print a receipt after adding the drawer operations.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>
                        </Checkbox>   
                    </Col>
                    
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {EditDeviceSettings}