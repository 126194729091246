import React, { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Col, Dropdown, Form, Image, Row, Space, Table, Tooltip, Typography } from 'antd'
import { paymentReportsData } from '../../../../Data';
import { ModuleTopHeading } from '../../../PageComponents';

const { Text } = Typography
const PaymentReportTable = ({setFilterModal,order}) => {
    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState([]);

    const options = [
        'Amount', 
        'Count', 
        'Return Amount', 
        'Return Count', 
        'Net Amount',
    ];
    const [checkAll, setCheckAll] = useState(false);
  
    const onChange = (item, checked) => {
      const updatedList = checked
        ? [...checkedList, item]
        : checkedList.filter((value) => value !== item);
  
      setCheckedList(updatedList);
      setCheckAll(updatedList.length === options.length);
    };
  
    const onCheckAllChange = (e) => {
      setCheckedList(e.target.checked ? options : []);
      setCheckAll(e.target.checked);
    };
    const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

    const columns = [
        {
            title: <Space>
                <Text className='fw-500'>Payment Method</Text>
                <Tooltip title='The name of the payment method'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'paymentmethod',
        },
        { title: 'Branch Name', dataIndex: 'branch', hidden: order != 1 },
        { title: 'Branch Reference', dataIndex: 'branchreference', hidden: order != 1 },
        { title: 'Cashier', dataIndex: 'cashier', hidden: order != 2 },
        { title: 'Hours of Day', dataIndex: 'hoursofday', hidden: order != 3 },
        { title: 'Day', dataIndex: 'day', hidden: order != 4 },
        { title: 'Week', dataIndex: 'week', hidden: order != 5 },
        { title: 'Month', dataIndex: 'month', hidden: order != 6 },
        { title: 'Quarter', dataIndex: 'quarter', hidden: order != 7 },
        { title: 'Year', dataIndex: 'year', hidden: order != 8 },
        {
            title: <Space>
                <Text className='fw-500'>Amount</Text>
                <Tooltip title='The total amount includes returns'>
                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                </Tooltip>
            </Space>,
            dataIndex: 'amount',
            render: (returnamount) => <Text>SAR {returnamount}</Text>
        },
        {
            title: <Space>
                    <Text className='fw-500'>Count</Text>
                    <Tooltip title='The number of times a payment method is used'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'count',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Return Amount</Text>
                    <Tooltip title='The total return amount'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'returnamount',
            render: (returnamount) => <Text>SAR {returnamount}</Text>
        },
        {
            title: <Space>
                    <Text className='fw-500'>Return Count</Text>
                    <Tooltip title='The number of times a payment method is used in returns'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'returncount',
        },
        {
            title: <Space>
                    <Text className='fw-500'>Net Amount</Text>
                    <Tooltip title='The total amount not including returns'>
                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                    </Tooltip>
                </Space>,
            dataIndex: 'netamount',
            render: (netamount) => <Text>SAR {netamount}</Text>
        },       
    ].filter(column => !column.hidden)
 
   
    const dropdownContent = (
        <Card className='text-black radius-8 shadow-c card-cs'>
            <div>
                <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate}>Select All </Checkbox>
            </div>
            <Space direction='vertical' size={10} className='pt-3'>
                <Checkbox checked disabled>Payment Method</Checkbox>
                <Checkbox
                    checked={checkedList.includes('Amount')}
                    onChange={(e) => onChange('Amount', e.target.checked)}
                    >
                    Amount
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Count')}
                    onChange={(e) => onChange('Count', e.target.checked)}
                    >
                    Count
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Return Amount')}
                    onChange={(e) => onChange('Return Amount', e.target.checked)}
                    >
                    Return Amount
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Return Count')}
                    onChange={(e) => onChange('Return Count', e.target.checked)}
                    >
                    Return Count
                </Checkbox>
                <Checkbox
                    checked={checkedList.includes('Net Amount')}
                    onChange={(e) => onChange('Net Amount', e.target.checked)}
                    >
                    Net Amount
                </Checkbox>
            </Space>
        </Card>
    );

   
    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16} align={'middle'}>
                            <Col span={24}>
                                <Space style={{justifyContent:"end"}} className='w-100'>
                                    <Button className='btncancel pad-filter' onClick={()=>setFilterModal(true)}>
                                        <Space>
                                            <Image src='/assets/icons/filter-outline.png' preview={false} width={20} />
                                            <Text className='text-black'>Filters</Text>
                                        </Space>
                                    </Button>
                                    <Tooltip title="Column">
                                        <Dropdown
                                            overlay={dropdownContent}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter brand-bg border0'>
                                                <Image src='/assets/icons/plus-cr.png' preview={false} width={20} />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={paymentReportsData} 
                        scroll={{x: 1200}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                        summary={(pageData) => {
                            let totalamount = 0;
                            let totalcount = 0;
                            let totalreturnamount = 0;
                            let totalreturncount = 0;
                            let totalnetamount = 0;
                    
                            pageData.forEach(({ 
                                amount, count, returnamount,  
                                returncount,netamount, 
                            }) => {
                              totalamount += amount;
                              totalcount += count;
                              totalreturnamount += returnamount;
                              totalreturncount += returncount;
                              totalnetamount += netamount;
                            });
                            
                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell><Text className='fw-500'>Total</Text></Table.Summary.Cell>
                                    {order == 1 && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == 1 && <Table.Summary.Cell></Table.Summary.Cell>} 
                                    {order == 2 && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == 3 && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == 4 && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == 5 && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == 6 && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == 7 && <Table.Summary.Cell></Table.Summary.Cell>}
                                    {order == 8 && <Table.Summary.Cell></Table.Summary.Cell>}
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalamount}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalcount}</Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalreturnamount}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>{totalreturncount}</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell><Text className='fw-500'>SAR {totalnetamount}</Text></Table.Summary.Cell>
                                </Table.Summary.Row>
                            );
                          }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export { PaymentReportTable }
