import { Button, Col, Image, Row, Space, Tabs, Typography } from 'antd';
import React from 'react'
import { AccountBusinessDetails, AccountLicensesInvoices, AccountMyProfile, ModuleTopHeading } from '../../components';

const {Text} = Typography

const AccountPage = ()=> {
  const accounttab = [
    {
        key: '1',
        label: 'My Profile',
        children: <AccountMyProfile />,
    },
    {
        key: '2',
        label: 'Business Details',
        children: <AccountBusinessDetails />,
    },
    {
        key: '3',
        label: 'Licenses & Invoices',
        children: <AccountLicensesInvoices />,
    },
];
  return (
  <div>
      <Row gutter={[24, 24]} align={'middle'}>
          <Col span={24}>
              <Space className='w-100'>
                  <ModuleTopHeading name='زايد الصيعري' />
                  <Text className='text-active bg-light-green fs-12 fw-500 badge-cs'>
                      Account Owner
                  </Text>
              </Space>
          </Col>
          <Col span={24}>
              <Tabs defaultActiveKey="1" items={accounttab}  />   
          </Col>
      </Row>
  </div>
  )

}

export {AccountPage}