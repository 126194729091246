import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Image, Tooltip, Checkbox } from 'antd'
import { MyInput, MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const AddEditPaymentMethod = ({visible,onClose,edititem,setDeleteItem, restoremodal}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
    const deleteHandle = () => {
        setDeleteItem({name:edititem})
        onClose()
    }
    

  return (
    <div>
        <Modal 
            width={600} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Flex justify='space-between'>
                    {
                        edititem && 
                        <Button  
                            type="primary"
                            className='btnsave text-white bg-red'
                            onClick={deleteHandle}
                        >
                            Delete Payment Method
                        </Button>
                    }
                    <Space style={{justifyContent: edititem ? 'end' : 'center'}} className="w-100">
                        <Button
                            onClick={onClose}
                            className='btncancel'
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            className='btnsave text-white brand-bg'
                            loading={loading}
                            onClick={()=>{
                                // DeleteProductApi();  
                            }}
                            
                        >
                            {
                                edititem? 'Update' :  restoremodal ? 'Restore' : 'Save'
                            }
                        </Button>
                    </Space>
                </Flex>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>{edititem? 'Edit Branch' : restoremodal ? 'Restore Payment Method' : 'Add Payment Method'}</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Payment Method Name <span className='text-inactive'>*</span>
                                <Tooltip title='The payment method name'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name='paymentmethodName'
                            required
                            message='Please enter payment method name'
                            value={form.getFieldValue('paymentmethodName') || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Name Localized
                                <Tooltip title='Enter the localized name in the second language of your own choice'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name="localized" 
                            value={form.getFieldValue("localized") || ''}
                        />
                    </Col>
                    {
                        (!restoremodal && !edititem) ? (
                            <Col span={24}>
                            <MySelect
                                label={
                                <Space>
                                    Type
                                    <Tooltip title="Cash, Card (debit or credit) & Custom (Cash & Credit Card)">
                                    <Image src="/assets/icons/cir-i-gray.png" width={16} preview={false} />
                                    </Tooltip>
                                </Space>
                                }
                                name="type"
                                size="large"
                                value={form.getFieldValue("type") || ''}
                                options={[
                                { key: 1, name: 'Cash' },
                                { key: 2, name: 'Card' },
                                { key: 3, name: 'Custom' }
                                ]}
                            />
                            </Col>
                        ) : null
                    }
                    <Col span={24}>
                        <MyInput 
                            label={<Space>
                                Code
                                <Tooltip title='The reference number or code to uniquely identify the payment method. The code may only contains letters and numbers.'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>}
                            size='large'
                            name="code" 
                            value={form.getFieldValue("code") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <Checkbox>
                            <Space>
                                Auto open cash drawer
                                <Tooltip title='If checked, the cash drawer will open automatically when this payment method is used in a sales transaction'>
                                    <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                </Tooltip>
                            </Space>
                        </Checkbox>   
                    </Col>
                    {
                       restoremodal || edititem ?
                        <Col span={24}>
                            <Checkbox>
                                <Space>
                                    Active
                                    <Tooltip title='If checked, the payment method will be available for the users to pay orders with.'>
                                        <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                                    </Tooltip>
                                </Space>
                            </Checkbox>   
                        </Col>
                        : <></>
                    }
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {AddEditPaymentMethod}