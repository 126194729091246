import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Flex, Form, Image, Modal, Radio, Row, Space, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { MyInput, MySelect } from '../../../Forms';

const {Text} = Typography
const UpdateProductModifier = ({visible, onClose}) => {

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  return (
    <Modal
        title={null} 
        className='shadow-c modal-cs'  
        open={visible} 
        onOk={onClose} 
        onCancel={onClose} 
        closeIcon={false}
        centered 
        
        footer={
          <Space className='space-between-align' >
            <Button  
                  block
                  type="primary"
                  className='btnsave text-white bg-red px-3'
                  loading={loading}
                  onClick={()=>{
                      // DeleteProductApi();  
                  }}
                  
              >
                  Delete Options
            </Button>
            <Space className='space-between-align'>

              <Button
                  onClick={onClose}
                  className='btncancel'
              >
                  Cancel
              </Button>
              <Button  
                  block
                  type="primary"
                  className='btnsave text-white brand-bg'
                  loading={loading}
                  onClick={()=>{
                      // DeleteProductApi();  
                  }}
                  
              >
                  Update
              </Button>
            </Space>
          </Space>
      }
    >
        <Flex align='center' justify='space-between' className='header-modal'>
            <Text strong>{'Update Modifier : Size'}</Text> {/* Dynamic title here */}
            <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                <CloseOutlined  className='text-gray'/>
            </Button>
        </Flex>
        <Form
              form={form}
              layout="vertical"
              initialValues={true}
              // onFinish={onFinish}
              requiredMark={false}
              className='content-modal'
          >
              <Row gutter={16}>
                  <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                      <MyInput 
                          type='number'
                          label='Minimum Options'
                          size='large'
                          name='miniOption'
                          required
                          message='Please enter Minimum Option'
                          value={form.getFieldValue('miniOption') || ''}
                          tooltip="The value represent the lower number of allowed options for this modifier set."
                      />
                  </Col>
                  <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                      <MyInput 
                          type='number'
                          label='Maximum Options'
                          size='large'
                          name='maxOption'
                          required
                          message='Please enter Maximum Option'
                          value={form.getFieldValue('maxOption') || ''}
                          tooltip="The value represent the highest number of allowed options for this modifier set."
                      />
                  </Col>
                  
                  
                  <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                      <MySelect
                          label= 'Default Options'
                          tooltip="This is to automatically select options once the product is selected"
                          name="defaultOption" 
                          showSearch
                          mode="multiple"
                          size={'large'}
                          value={form.getFieldValue("defaultOption") || ''}
                          options={[
                              {
                                  key: 1,
                                  name: 'size'
                              },
                              {
                                  key: 2,
                                  name: 'Branch 02'
                              },
                          ]}
                      />
                  </Col>
                  <Col lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                      <MySelect
                          label= 'Excluded Options'
                          tooltip="This is for choosing the options within the modifier set that should not be shown for this product."
                          name="excludedOption" 
                          showSearch
                          size={'large'}
                          value={form.getFieldValue("excludedOption") || ''}
                          mode="multiple"
                          options={[
                              {
                                  key: 1,
                                  name: 'Cold'
                              },
                              {
                                  key: 2,
                                  name: 'Branch 02'
                              },
                          ]}
                      />
                  </Col>
              </Row>
        </Form>
    </Modal>
);;
}

export {UpdateProductModifier};

