export const modifieroptionallData = [
    {
        key: 1,
        optionname:'Small',
        name:'Size',
        price:'SAR 0',
        taxgroup:'-',
        status:'Active',
    },
    {
        key: 2,
        optionname:'Mozzarella cheese',
        name:'Cheese',
        price:'SAR 5',
        taxgroup:'50%',
        status:'Inactive',
    },
    {
        key: 3,
        optionname:'Coffee',
        name:'Coffee',
        price:'SAR 10',
        taxgroup:'50%',
        status:'Active',
    },

    {
        key: 4,
        optionname:'Small',
        name:'Size',
        price:'SAR 0',
        taxgroup:'-',
        status:'Inactive',
        id:'delete'
    },
]