import { Card, Col, Row, Space, Table, Typography } from "antd";
import React from "react";
const { Text, Title } = Typography;
const OrderPaymentTable = ({ order }) => {
  const paymentColumns = [
    {
      title: "Payment Method",
      dataIndex: "payment",
    },
    {
      title: "Final Price",
      dataIndex: "finalPrice",
    },
    {
      title: "Time Stamp",
      dataIndex: "timeStamp",
    },
    
  ];
  const paymentData = [
    {
      key: "1",
      payment: order?.paymentmethod,
      finalPrice: order?.total,
      timeStamp: order?.time,
      
    },
  ];

  return (
    <div>
      <div>
        <Title level={5} className='m-0'>Payments</Title>
      </div>
      <Card className="shadow-d radius-12 card-cs">
        <Table
          size="large"
          columns={paymentColumns}
          dataSource={paymentData}
          className="table-dark pagination"
          showSorterTooltip={false}
          rowHoverable={true}
          pagination={false}
          scroll={{x: 700}}
        />
      </Card>   
    </div>
  );
};

export { OrderPaymentTable };
