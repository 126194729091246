import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography, Tooltip, Image } from 'antd'
import { MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography


const ModifierPorducts = ({visible, onClose, copyModifier })=>{
  const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()

  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        {copyModifier ? "Apply" : "Save"}
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>
                    {copyModifier ? 'Copy Modifiers' : "Add Modifiers" }
                </Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
               <Row>
                    <Col span={24}>
                        <MySelect
                            label={<Space>
                              {copyModifier ? "Product to copy modifiers to" : "Modifiers"}
                              <Tooltip title={copyModifier ?  "To copy modifiers to other products": "To add modifiers options in this product"}>
                                  <Image src='/assets/icons/cir-i-gray.png' width={16} preview={false} />
                              </Tooltip>
                          </Space>}
                            name="modifier" 
                            mode='multiple'
                            size={'large'}
                            value={form.getFieldValue("modifier") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: "size"
                                },
                                {
                                    key: 2,
                                    name: 'length'
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {ModifierPorducts}