import React from 'react'
import { Button, Card, Col, Flex, Image, Radio, Row, Space, Switch, Typography } from 'antd'
import { CalendarDropBorder } from '../../CalendarDropBorder'


const { Text } = Typography
const TabsComponent = ({setFilterModal}) => {
  return (
    <div className='mb-3'>
        <Row gutter={[24, 24]} align={'middle'}>
            <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
                <Flex gap={10} wrap align='center'>
                    <Radio.Group defaultValue="day" buttonStyle="solid">
                        <Radio.Button value="day" className='fs-13'>Day</Radio.Button>
                        <Radio.Button value="week" className='fs-13'>Week</Radio.Button>
                        <Radio.Button value="month" className='fs-13'>Month</Radio.Button>
                    </Radio.Group>
                    <CalendarDropBorder/>
                </Flex>
            </Col>
            <Col lg={{span:12}} md={{span: 10}} sm={{span: 24}} xs={{span: 24}}>
                <Space size={10} className='w-100 right'>
                    <Flex align='center' gap={5}>
                        <Switch size="small" />
                        <Text className='fs-13 fw-400'>Compare</Text>
                    </Flex>
                    <Button className='pad border-gray' onClick={()=>setFilterModal(true)}>
                        <Image src='/assets/icons/filter.png' preview={false} width={20} />
                    </Button>
                </Space>
            </Col>
        </Row>
    </div>
  )
}

export {TabsComponent}