import React, { useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Image, Row, Space, Tabs, Typography } from 'antd'
import { AddModifier, AddProductMul, AlertModal, FilterModifier, ModifierImport, ModifierTableAll, ModifierTableDelete, ModuleTopHeading } from '../../components'
import { DownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { Text } = Typography
const ModifierPage = ({setShowBread,setContentModifier}) => {

    const navigate = useNavigate()
    const [order, setOrder] = useState(1)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(false)
    const [ addproduct, setAddProduct ] = useState(false)
    const [ filtermodal, setFilterModal ] = useState(false)
    const [ importmodal, setImportModal ] = useState(false)

    const items = [
        { label: <Button className='fs-13 border0 bg-transparent' onClick={()=>setImportModal(true)}>Import Modifiers</Button>, key: 0 },
        { label: <Button className='fs-13 border0 bg-transparent'>Export Modifiers</Button>, key: 1 },
    ];

    const onClick = ({ key }) => {
        // key = parseInt(key) + 1;
        setOrder(key);
        // filter(key);
    };

    const itemstab = [
        {
          key: '1',
          label: 'All',
          children: <ModifierTableAll {...{ addproduct, setAddProduct, edititem, setEditItem, visible, setVisible, setShowBread, setDeleteItem, setContentModifier}} />,
        },
        {
          key: '2',
          label: 'Delete',
          children: <ModifierTableDelete {...{ addproduct, setAddProduct, edititem, setEditItem, visible, setVisible, setShowBread, setDeleteItem, setContentModifier}} />,
        },
      ];

   
  return (
    <div>

            <Row gutter={[24, 24]} align={'middle'}>
                <Col lg={{span:6}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <ModuleTopHeading name='Modifiers' onClick={()=>{setVisible(true)}} />
                </Col>
                <Col lg={{span:18}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <Space size={10} className='w-100 right mbl-space' wrap>
                        <Button className='btncancel pad-filter w-100' onClick={()=> setFilterModal(true)}>
                            <Flex justify='space-between' className='w-100' gap={10}>
                                <Image src='/assets/icons/filter-outline.png' preview={false} width={18} />
                                <Text className='text-black'>Filter</Text>
                            </Flex>
                        </Button>
                        <Button className='btncancel pad-filter w-100' onClick={()=>navigate('/modifiersorter')}>
                            <Flex justify='space-between' className='w-100' gap={10}>
                                <Image src='/assets/icons/sort.png' preview={false} width={18} />
                                <Text className='text-black'>Sort Modifiers</Text>
                            </Flex>
                        </Button>
                        <Dropdown
                            menu={{ items, onClick }}
                            trigger={['click']}
                            className='margin-top'
                        >
                            <Button className='btncancel pad-filter w-100'
                                icon={<img src='/assets/icons/export-filter.png' width={20}/>}
                            >
                                <Flex justify='space-between' className='w-100' gap={30}>
                                    <Text className='text-black'>Export / Import</Text>
                                    <DownOutlined />    
                                </Flex>  
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Card className='shadow-d radius-12 border0 card-cs'>
                        <Tabs defaultActiveKey="1" items={itemstab}  />
                    </Card>
                </Col>
            </Row>
        <AddModifier
            visible={visible}
            edititem={edititem}
            setDeleteItem={setDeleteItem}
            onClose={()=>{setVisible(false);setEditItem(null)}}
        />
        <AlertModal 
            visible={deleteitem}
            deleteitem={deleteitem}
            onClose={()=>{setDeleteItem(false)}}
        />
        <AddProductMul 
            visible={addproduct}
            onClose={()=>setAddProduct(false)}
        />
        <FilterModifier
            visible={filtermodal}
            onClose={()=>setFilterModal(false)}
        />
        <ModifierImport 
            visible={importmodal}
            onClose={()=>setImportModal(false)}
        />
    </div>
  )
}

export {ModifierPage}