import React, { useState } from 'react'
import { Alert, Button, Card, Flex, Form, Image, Space, Table, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { ActionButton } from '../../../PageComponents';
import { Addrole, AddUserBranch } from '../UserModals';
import { AlertModal } from '../../../Modals';

const { Text, Title } = Typography
const UserBranchTable = () => {
    const [form] = Form.useForm();
    const [ showhide, setShowHide ] = useState(false)
    const [ addbranch, setAddBranch ] = useState(false)
    const [ editbranch, setEditBranch ] = useState(null)

    const columns = [
        {
            title: 'Branches',
            dataIndex: 'branch',
            width: 150,
        },
        {
            title:'Branch Reference',
            dataIndex: 'reference',
            width: 130,
        },
    ];
    const data = [
        {
            key: '1',
            branch: 'Branch 1',
            reference:'B01'
        },
    ];

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg mb-3'>
                <Flex vertical gap={20} wrap>
                    <Space style={{justifyContent:'space-between'}} className='w-100'>
                        <Title level={5} className='m-0'>Roles</Title>
                        <Button 
                            type="primary"
                            className='btncancel px-3 bg-light-gray'
                            icon={<PlusOutlined className='text-black' />}
                            onClick={()=>{setAddBranch(true);setShowHide(true)}}
                        >
                            <Text className='text-black fs-13'>Add Branches</Text>
                        </Button>
                    </Space>
                    {
                        !showhide?(
                            <Text className='text-gray fs-13 text-center'>
                                Select the branches this user can access with the authorities assigned in their role.
                            </Text>
                        ) : (
                            <Table 
                                size='large'
                                columns={columns} 
                                dataSource={data} 
                                scroll={{x: 1000}}
                                className='table-dark pagination'
                                showSorterTooltip={false}
                                rowHoverable={true}
                                onRow={(record) => ({
                                    onClick: () => {
                                        setAddBranch(true);
                                        setEditBranch(record)
                                    },
                                })}
                                pagination={false}
                            />
                        )

                    }
                </Flex>
            </Card>

            <AddUserBranch 
                visible={addbranch}
                editbranch={editbranch}
                onClose={()=>{setAddBranch(false);setEditBranch(null)}}
            />
        </div>
    )
}

export { UserBranchTable }
