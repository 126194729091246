
import { Avatar, Badge, Button, Card, Dropdown, Flex, Image, Space, Tooltip } from 'antd'
import React, { useState } from 'react'
import { SwitchAccount } from './SwitchAccount';
import { useNavigate } from 'react-router-dom';

const UserDropdown = ()=> {
  const [ switchAccount , setSwitchAccount] = useState(false)
  const navigate = useNavigate()

  const dropdownContent = (
    <Card className='text-black radius-12 shadow-d card-cs p-0'>
      <Space direction='vertical'>
        <Button onClick={()=>setSwitchAccount(true)} className='btncancel border0'>Cafe for serving happiness</Button>
        <Button onClick={()=>navigate('/account')} className='btncancel border0'>My Account</Button>
        <Button className='radius-8 border0 w-100 brand-bg text-white'>Logout</Button>
      </Space>
    </Card>
);
  return (
    <div>
      <Dropdown
          overlay={dropdownContent}
          trigger={['click']}
          className='margin-top'
      >
          <Button shape='circle' size='large'>
              <Image src='/assets/icons/user.png' preview={false} width={18} />
          </Button>
      </Dropdown>
      <SwitchAccount 
          visible={switchAccount}
          onClose={()=>{setSwitchAccount(false)}}
        />
    </div>
  )
}

export {UserDropdown}