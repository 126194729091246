import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Form, Modal, Row, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { MyDatepicker, MyInput, MySelect } from '../../../Forms'
const { Text } = Typography

const FilterProduct = ({visible, onClose})=> {

  const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()

  return (
    <div>
        <Modal
            width={700} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center",marginTop:'15px'}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Filter</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label='Product Name'
                            size='large'
                            name='productName'
                            required
                            message='Please enter Product Name'
                            value={form.getFieldValue('productName') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            label='SKU'
                            size='large'
                            name='sku'
                            required
                            message='Please enter SKU'
                            value={form.getFieldValue('sku ') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyInput 
                            type='number'
                            label='Product Barcode'
                            size='large'
                            name='productBarcode'
                            required
                            message='Please enter Product Barcode'
                            value={form.getFieldValue('productBarcode') || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Categories'
                            name="categories" 
                            showSearch
                            size={'large'}
                            value={form.getFieldValue("categories") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'SeaFood'
                                },
                                {
                                    key: 2,
                                    name: 'Italian Food'
                                },
                                {
                                  key: 3,
                                  name: 'Drink'
                              },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Modifiers'
                            name="modifiers" 
                            size={'large'}
                            value={form.getFieldValue("modifiers") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Size'
                                },
                                {
                                    key: 2,
                                    name: 'Pickup Order'
                                },
                                {
                                    key: 3,
                                    name: 'Drive-thru Order'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Tax Groups'
                            name="taxGroups" 
                            showSearch
                            size={'large'}
                            value={form.getFieldValue("taxGroups") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: "50%"
                                },
                                {
                                  key: 1,
                                  name: "70%"
                              },
                              {
                                key: 1,
                                name: "100%"
                            },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MySelect
                            label= 'Status'
                            name="status" 
                            size={'large'}
                            value={form.getFieldValue("status") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Active'
                                },
                                {
                                    key: 2,
                                    name: 'Cancelled'
                                },
                                {
                                    key: 3,
                                    name: 'Completed'
                                },
                                {
                                    key: 4,
                                    name: 'Draft'
                                },
                                {
                                    key: 5,
                                    name: 'Returned'
                                },
                                {
                                    key: 6,
                                    name: 'Pending'
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    
                        <MyDatepicker 
                            datePicker
                            label='Updated After'
                            size='large'
                            name='updateAfter'
                            required
                            message='Please enter update after'
                            value={form.getFieldValue('updateAfter') || ''}
                            className='border-gray fs-13'
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export { FilterProduct }