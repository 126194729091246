import React, { useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Image, Row, Space, Typography } from 'antd'
import { AlertModal, ModuleTopHeading, CategoryTable, CategoryFilter, CategoryImport, AddEditCategory } from '../../components'
import { DownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { Text } = Typography

const CategoryPage = () => {

    const navigate = useNavigate()
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ switchtext, setSwitchText ] = useState(false)
    const [ importmodal, setImportModal ] = useState(false)
    const [ filtervisible, setFilterVisible ] = useState(false)
    const [order, setOrder] = useState(1)
    
    const items = [
        { label: <Button className='fs-13 border0 bg-transparent' onClick={()=>setImportModal(true)}>Import Categories</Button>, key: 0 },
        { label: <Button className='fs-13 border0 bg-transparent'>Export Categories</Button>, key: 1 },
    ];
    const onClick = ({ key }) => {
        // key = parseInt(key) + 1;
        setOrder(key);
        // filter(key);
    };
  return (
    <div>
        
            <Row gutter={[24, 24]} align={'middle'}>
                <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
                    <ModuleTopHeading name='Categories' onClick={()=>{setVisible(true)}} />
                </Col>
                <Col lg={{span:12}} md={{span: 10}} sm={{span: 24}} xs={{span: 24}}>
                    <Space size={10} className='w-100 right mbl-space' wrap>
                        <Button className='btncancel pad-filter mbl-btn' onClick={()=>setFilterVisible(true)}>
                            <Flex justify='space-between' gap={10} className='w-100'>
                                <Image src='/assets/icons/filter-outline.png' preview={false} width={18} />
                                <Text className='text-black'>Filters</Text>
                            </Flex>
                        </Button>
                        <Button className='btncancel pad-filter mbl-btn' onClick={()=>navigate('/sortercategory')}>
                            <Flex justify='space-between' gap={10} className='w-100'>
                                <Image src='/assets/icons/sort.png' preview={false} width={18} />
                                <Text className='text-black'>Sort Categories</Text>
                            </Flex>
                        </Button>
                        <Dropdown
                            menu={{ items, onClick }}
                            trigger={['click']}
                            className='margin-top'
                        >
                            <Button className='btncancel pad-filter mbl-btn'
                                icon={<img src='/assets/icons/export-filter.png' width={20}/>}
                            >
                                <Flex justify='space-between' className='w-100' gap={30}>
                                    <Text  className='text-black'>Export / Import</Text>
                                    <DownOutlined />    
                                </Flex>  
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Card className='shadow-d radius-12 card-cs'>
                        <CategoryTable {...{ filtervisible, setFilterVisible, visible, setVisible, edititem, setEditItem, switchtext, setSwitchText, deleteitem, setDeleteItem }} />
                    </Card>
                </Col>
            </Row>
        <AlertModal 
            visible={deleteitem}
            deleteitem={deleteitem}
            onClose={()=>{setDeleteItem(false)}}
        />
        <CategoryFilter 
            visible={ filtervisible }
            onClose={()=>setFilterVisible(false)}
        />
        <CategoryImport 
            visible={importmodal}
            onClose={()=>setImportModal(false)}
        />
        <AddEditCategory
            visible={visible}
            edititem={edititem}
            setDeleteItem={setDeleteItem}
            onClose={()=>{setVisible(false);setEditItem(null)}}
        />
        
    </div>
  )
}

export {CategoryPage}