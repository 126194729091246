import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Flex, Typography } from 'antd'
import { MySelect } from '../../../Forms';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography

const MenuEngineeringModal = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white brand-bg'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                            form.submit()
                        }}
                        
                    >
                        Apply
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Filter</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MySelect
                            label= 'Branches'
                            name="branch" 
                            showSearch
                            mode='multiple'
                            size={'large'}
                            value={form.getFieldValue("branch") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Branch 01 (B01)'
                                },
                                {
                                    key: 2,
                                    name: 'Branch 02'
                                },
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <MySelect
                            label= 'Categories'
                            name="category" 
                            showSearch
                            mode='multiple'
                            size={'large'}
                            value={form.getFieldValue("category") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Drinks'
                                },
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <MySelect
                            label= 'Include Modifiers'
                            name="inMod" 
                            required
                            message='Please choose yes or no'
                            size={'large'}
                            value={form.getFieldValue("inMod") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Yes'
                                },
                                {
                                    key: 2,
                                    name: 'No'
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {MenuEngineeringModal}