import { Button, Col, Dropdown, Form, Row, Space, Table, Typography } from 'antd';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { productTableData } from '../../../../Data';
import { DownOutlined } from '@ant-design/icons';

const { Text } = Typography
const AllProductTable= ({setStatus, setSwitchText, deletedProduct, setActiveBranch ,setInactiveBranch , setTaxGroup , setRemoveTax , setDeletedProduct})=> {
  const [form] = Form.useForm();
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
  const columns = [
    {
        title: 'Image',
        dataIndex: 'img',
        width: 150,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a,b) => a.name - b.name,
        width: 150,
    },
    {
        title: 'SKU',
        dataIndex: 'sku',
        width: 170,
    },
    {
        title: 'Category',
        dataIndex: 'category',
        width: 150,
    },
    {
        title: 'Price',
        dataIndex: 'price',
        width: 140,
    },
    {
        title: 'Tax Group',
        dataIndex: 'taxgroup',
        width: 140,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        width: 150,
        render: (status) => (
                <Space align="center">
                        <Text className={
                            status === 'Active' ? 
                            'text-active bg-light-green fs-12 badge-cs':
                            'text-inactive bg-light-red fs-12 badge-cs'
                        }>
                            {status}
                        </Text>
                </Space>  
        )
    },
    // {
    //     title: 'Action',
    //     fixed: 'right',
    //     align:'center',
    //     className:'action',
    //     width:130,
    //     render: (_, row) => (
    //         <Space>
    //             <Switch size='small' defaultChecked onChange={onChange} />
    //             <ActionButton
    //                 title='Edit'
    //                 shape="circle"
    //                 icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
    //                 onClick={() => {}}
    //                 className=' bg-transparent'
    //             />
    //             <ActionButton
    //                 title='Delete'
    //                 shape="circle"
    //                 icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
    //                 onClick={() => {setDeleteItem({name:row?.name})}}
    //                 className=' bg-transparent'
    //             />
    //         </Space>
    //     ),
    // },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  },
};


  return (
    <div>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                <Table 
                        size='large'
                        columns={columns} 
                        dataSource={productTableData} 
                        rowSelection={{...rowSelection}}
                        className='table-dark pagination'
                        showSorterTooltip={false}
                        rowHoverable={true}
                        onRow={(record) => ({
                            onClick: (event) => {
                                const isActionColumn = event.target.closest('.action');
                                if (!isActionColumn && record.id) {
                                    navigate(`/products/viewproducts/${record.id}`);
                                }
                            },
                        })}
                        title={() => selectedRows.length > 0 ? (
                            <div>
                                {selectedRows.length} Selected  
                                <Dropdown
                                    menu={{
                                        items: [
                                            { label: <Link onClick={() => { setStatus(true); setSwitchText(true); }} className='fs-13 action-column'>Active</Link>, key: 0 },
                                            { label: <Link onClick={() => { setStatus(true); setSwitchText(true); }} className='fs-13 action-column'>Inactive</Link>, key: 1 },
                                            { label: <Link onClick={() => { setActiveBranch(true); }} className='fs-13 action-column'>Activate in Branches</Link>, key: 2 },
                                            { label: <Link onClick={() => { setActiveBranch(true); setInactiveBranch(true); }} className='fs-13 action-column'>Inactivate in Branches</Link>, key: 3 },
                                            { label: <Link onClick={() => { setTaxGroup(true); }} className='fs-13 action-column'>Assign Tax Group</Link>, key: 4 },
                                            { label: <Link onClick={() => { setTaxGroup(true); setRemoveTax(true); }} className='fs-13 action-column'>Remove Tax Group</Link>, key: 5 },
                                            { 
                                                label: <Link 
                                                    onClick={() => { 
                                                        const selectedRowNames = selectedRows?.map(row => row?.name);
                                                        setDeletedProduct({ name: ' ' + selectedRowNames + ' ' });
                                                    }} 
                                                    className='fs-13 action-column'
                                                >
                                                    Delete
                                                </Link>, 
                                                key: 6 
                                            }
                                        ]
                                    }}
                                    trigger={['click']}
                                    className='margin-top'
                                >
                                    <Button className='btncancel px-2 py-1 bg-light-gray mx-2'>
                                        <Space>
                                            <Text className='text-black fs-13'>Actions</Text>
                                            <DownOutlined /> 
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </div> 
                        ) : null}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </div>
  )
}

export  {AllProductTable}