import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Breadcrumb,
  Card,
  Image,
  Flex,
  Typography,
} from "antd";

import { ActionButton } from "../../../PageComponents";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SorterCategory } from "../CategoryModals";
const { Text } = Typography;

const SortCategory = () => {

  const navigate = useNavigate()
  const [ sortermodal, setSorterModal ] = useState(false) 

  const categories = [
    { name: "Sea Food", image: "/assets/images/drink.png" },
    { name: "Drinks", image: "/assets/images/seafood.png" },
    { name: "Bakery Items", image: "/assets/images/bakeryitem.png" },
  ];
  return (
    <div>
      <Flex vertical gap={10}>
        <Breadcrumb>
          <Breadcrumb.Item>Menu</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/category")}>
            Categories
          </Breadcrumb.Item>
          <Breadcrumb.Item>Sort Categories</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="radius-12 shadow-c">
          <Flex align="center" gap={6} className="mb-2">
            <ActionButton
              title="Edit"
              shape="circle"
              icon={<ArrowLeftOutlined />}
              className=" bg-transparent border0"
              onClick={() => navigate('/category')}
            />
            <Text>Sort Categories</Text>
          </Flex>
          <Row gutter={[16,16]}>
            {categories.map((category, index) => (
              <Col xl={{span: 3}} lg={{span:4}} md={{span:6}} sm={{span:6}} xs={{span: 12}}>
                <Flex
                  key={index}
                  align="center"
                  onClick={() => setSorterModal(true)}
                  className="cursor shadow-c px-3 py-3 radius-8 sorttexthover"
                  vertical justify="center" gap={5}
                >
                  <Image src={category.image} width={30} preview={false} />
                  <Typography.Text className="fs-14 fw-500 ">
                    {category.name}
                  </Typography.Text>
                </Flex>
              </Col>
            ))}
          </Row>
        </Card>
      </Flex>
      <SorterCategory
        visible={sortermodal}
        onClose={()=>setSorterModal(false)}
      />
    </div>
  );
};

export { SortCategory };
