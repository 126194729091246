import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography, Col, Row, Image } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { ModuleTopHeading } from '../../../PageComponents';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


const { Title } = Typography

function ModifierReportsGraph() {
  const [form] = Form.useForm();

const chartData = {
  series: [
    {
      name: 'Cost',
      data: [12, 19, 3 ],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius:5,
        columnWidth: '50%',
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
      colors: ['#11abe2'], // Color of the border
    },
    xaxis: {
      categories: [
        "Drink",
        "Seafood",
        "Bakery",
      ],
    },
    yaxis: {
      min: 0,
      max: 20,
      tickAmount: 2,
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    colors: ['#e7f7fc','#F56C89'],
    legend:{
      markers:{
        shape: "circle"
      }
    }   
  },
};

  const graphmetric = [
    { label: <Link href="#" className='fs-13'>Cost</Link>, key: 0 },
    { label: <Link href="#" className='fs-13'>Gross Sales</Link>, key: 1 },
    { label: <Link href="#" className='fs-13'>Net Quantity</Link>, key: 2 },
    { label: <Link href="#" className='fs-13'>Net Sales</Link>, key: 3 },
    { label: <Link href="#" className='fs-13'>Net Sales with Tax</Link>, key: 4 },
    
    { label: <Link href="#" className='fs-13'>Profit</Link>, key: 5 },
    { label: <Link href="#" className='fs-13'>Return Amount</Link>, key: 6 },
    { label: <Link href="#" className='fs-13'>Return Quantity</Link>, key: 7 },
    { label: <Link href="#" className='fs-13'>Taxes</Link>, key: 8 },
  ]

  return (
    <div>
      <Card className='shadow-d border0 radius-12'>
        <Flex justify='end' align='center' wrap>
          <Form form={form} layout="vertical">
            <Flex justify='end' gap={10}>
              <Button className='btncancel px-3'>
                <Image src='/assets/icons/download.png' preview={false} width={14} />
              </Button>
              <Dropdown
                    menu={{ items:graphmetric  }}
                    trigger={['click']}
                    className='margin-top'
                >
                    <Button className='btncancel pad-filter text-black'
                    >
                        <Flex justify='space-between' gap={30}>
                            Metric
                            <DownOutlined />    
                        </Flex>  
                    </Button>
                </Dropdown>
            </Flex>
          </Form>
        </Flex>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={220}
        />
      </Card>
    </div>
  )
}

export default ModifierReportsGraph


