export const devicesData = [
  {
      key: 1,
      devicename:'Cashier 1',
      ref:'D01',
      branch:'Branch 01',
      type:'Cashier',
      status:'Used',
  },
  {
      key: 2,
      devicename:'Cashier 2',
      ref:'D02',
      branch:'Branch 01',
      type:'Cashier',
      status:'Used',
  },
  {
      key: 3,
      devicename:'Cashier 1',
      ref:'D03',
      branch:'Branch 02',
      type:'Display',
      status:'Used',
  },

  {
      key: 4,
      devicename:'Display 2',
      ref:'D04',
      branch:'Branch 02',
      type:'Display',
      status:'Used',
  },
]