import React, { useState } from 'react'
import { Card, Col, Dropdown, Flex, Row, Typography } from 'antd'
import { ActionButton, ModuleTopHeading } from '../../../PageComponents'
import { Link } from 'react-router-dom'

const { Text, Title } = Typography
const GeneralTaxGroupcard = ({setAddTaxgroup,setEditItem,setAddBranch,setRemoveCheck,setRemoverText,setAddProduct,setAddToModifier}) => {

    const data = [
        {
            name:'Group 1',
            taxtext:[
              {
                tax:'Tax 1'
              }
            ]
        },
        {
          name:'Group 2',
          taxtext:[
            {
              tax:'Tax 1'
            },
            {
              tax:'Tax 2'
            },
            {
              tax:'Tax 3'
            },
          ]
      },
    ]

  return (
    <div>
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <ModuleTopHeading name='Tax Groups' onClick={()=>{setAddTaxgroup(true)}} />
            </Col>
            {data.map((data, index) => (
              <Col xl={{span: 6}} lg={{span:6}} md={{span:6}} sm={{span:12}} xs={{span: 24}} 
              key={index}>
                <Card
                  onClick={(event) => {
                    const actionbtn = event.target.closest('.noclick');
                    if(!actionbtn){
                      setAddTaxgroup(true);setEditItem(data?.name)
                    }
                  }}
                  className="cursor shadow-d radius-12 card-cs h-100 position-relative"
                  align='center'
                >
                  <Dropdown
                        menu={{ items: [
                            { label: <Link className='fs-13 noclick' onClick={()=>{setAddBranch(true);setRemoveCheck(true)}}>Add to branches</Link>, key: 0 },
                            { label: <Link className='fs-13 noclick' onClick={()=>{setAddBranch(true);setRemoveCheck(true);setRemoverText(true)}}>Remove from branches</Link>, key: 1 },
                            { label: <Link className='fs-13 noclick' onClick={()=>setAddProduct(true)}>Add to products</Link>, key: 2 },
                            { label: <Link className='fs-13 noclick' onClick={()=>{setAddProduct(true);setRemoverText(true)}}>Remove from products</Link>, key: 3 },
                            { label: <Link className='fs-13 noclick' onClick={()=> setAddToModifier(true)}>Add to modifier options</Link>, key: 4 },
                            { label: <Link className='fs-13 noclick' onClick={()=> {setAddToModifier(true);setRemoverText(true)}}>Remove from modifier options</Link>, key: 5 },
                        ] }}
                        trigger={['click']}
                        className='border0 actionbtn noclick' 
                    >
                        <img src='/assets/icons/3dots.png' width={22} />
                  </Dropdown>
                  <Title level={5} className='m-0'>{data?.name}</Title>
                  <Flex justify='center' wrap gap={2} align='center' className='py-1'>
                      {
                        data?.taxtext?.map((innertext,i)=>
                          <Text className='px-3 py-1 radius-8 fs-13 border-brand' key={i}>
                            {innertext?.tax}
                        </Text>
                        )
                      }
                  </Flex>
                  
                </Card>
              </Col>
            ))}
        </Row>
    </div>
  )
}

export {GeneralTaxGroupcard}