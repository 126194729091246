import { Button, Card, Col, Divider, Form, Image, Row, Space, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { MyInput, MySelect } from '../../../Forms'
const {Title} = Typography




const ContactsDetails = () => {
  const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()

  return (

        <div>
            <div className='mb-3'>
                <Title level={5} className='m-0'>Contact</Title>
            </div>
            <Card className='shadow-d radius-12 card-cs py-2 px-2'>
              <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal'
              >
                  <Row gutter={16}>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label='Business Category'
                              name='category'
                              size='large'
                              required
                              value={form.getFieldValue('category') || ''}
                              disabled
                          />
                      </Col>
                      <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                          <MyInput 
                              label="Account Number"
                              name='account'
                              size='large'
                              type="number"
                              required
                              value={form.getFieldValue('accountnumber') || ''}
                              disabled
                          />
                      </Col>
                      <Divider />
                      <Space style={{justifyContent:"end"}} className="w-100">
                      <Button
                          onClick={()=>{}}
                          className='btncancel'
                      >
                          Reset
                      </Button>
                      <Button 
                          block
                          type="primary"
                          className='btnsave text-white brand-bg'
                          loading={loading}
                          onClick={()=>{
                              // DeleteProductApi();  
                          }}
                          
                      >
                          Save
                      </Button>
                  </Space>
                  </Row>
              </Form>

              </Card>
           
    </div>
  )
}

export {ContactsDetails}



    
