import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Skeleton, Flex } from 'antd'

const { useToken } = theme;
const { Title } = Typography
const CardComponent = () => {
    const { token } = useToken();
    const [ skeletonactive, setSkeletonActive] = useState(false)

    // setTimeout(() => {
    //     setSkeletonActive(false)
    // }, 2000);

    const data = [
        {
            title:'5234',
            subtitle:'Total Orders',
            img:'totalorder.png',
        },
        {
            title:'SAR 2215',
            subtitle:'Net Sales',
            img:'netsales.png',
        },
        {
            title:'SAR 1096',
            subtitle:'Net Payments',
            img:'netpayments.png',
        },
        {
            title:'SAR 1789',
            subtitle:'Return Amount',
            img:'returnamount.png',
        },
    ]
  return (
    <div>
        <Row gutter={[24,24]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        {
                            skeletonactive?
                                <Skeleton.Button
                                    active
                                    size='large' 
                                    shape='square'
                                    block
                                    style={{width:'100%', height:'114px',borderRadius:20}}
                                />
                                :
                                <Card style={{borderColor: token.borderCardcolor,borderRadius: token.borderCardrad,width:"100%"}}
                                    className='card-cs shadow-c border0'
                                >
                                    <Row justify='space-between' align='middle'>
                                        <Col lg={6} md={8} xs={6} sm={6}>
                                            <Flex>
                                                <Image preview={false} src={'/assets/icons/'+cdata?.img} width={'45px'} />
                                            </Flex>  
                                        </Col>
                                        <Col lg={18} md={16} xs={18} sm={18}>
                                            <Space direction='vertical' size={2} className='my-2'>
                                                <Title className='my-0 fw-500' level={5}>{cdata?.subtitle}</Title>
                                                <Title level={4} className='my-0 fw-500'>
                                                    {cdata?.title}
                                                </Title>                                           
                                            </Space>
                                        </Col>
                                        
                                    </Row>
                                </Card> 
                                }  
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {CardComponent}