export const tillData = [
    {
        businessdate:'2024-10-22',
        branch:'Branch 1',
        branchreference:'B01',
        user:'Omar',
        openedat:'October 22, 12:00am',
        openingamount:'SAR 0',
        netcashpayment:'SAR 572.25',
        totalpayins:'SAR 0',
        totalpayout:'SAR 0',
        totalcashdrop:'SAR 0',
        closingamount:'SAR 0',
        expectedamount:'SAR 572.25',
        variance:-572.25,
        manualdraweropen:'0',
        shiftduration:'October 23, 12:00am',
    },
    {
        businessdate:'2024-10-22',
        branch:'Branch 1',
        branchreference:'B01',
        user:'Omar',
        openedat:'October 22, 12:00am',
        openingamount:'SAR 0',
        netcashpayment:'SAR 572.25',
        totalpayins:'SAR 0',
        totalpayout:'SAR 0',
        totalcashdrop:'SAR 0',
        closingamount:'SAR 0',
        expectedamount:'SAR 572.25',
        variance:-572.25,
        manualdraweropen:'0',
        shiftduration:'October 23, 12:00am',
    },
    {
        businessdate:'2024-10-22',
        branch:'Branch 1',
        branchreference:'B01',
        user:'Omar',
        openedat:'October 22, 12:00am',
        openingamount:'SAR 0',
        netcashpayment:'SAR 572.25',
        totalpayins:'SAR 0',
        totalpayout:'SAR 0',
        totalcashdrop:'SAR 0',
        closingamount:'SAR 0',
        expectedamount:'SAR 572.25',
        variance:-572.25,
        manualdraweropen:'0',
        shiftduration:'October 23, 12:00am',
    },
    {
        businessdate:'2024-10-22',
        branch:'Branch 1',
        branchreference:'B01',
        user:'Omar',
        openedat:'October 22, 12:00am',
        openingamount:'SAR 0',
        netcashpayment:'SAR 572.25',
        totalpayins:'SAR 0',
        totalpayout:'SAR 0',
        totalcashdrop:'SAR 0',
        closingamount:'SAR 0',
        expectedamount:'SAR 572.25',
        variance:-572.25,
        manualdraweropen:'0',
        shiftduration:'October 23, 12:00am',
    },
    {
        businessdate:'2024-10-22',
        branch:'Branch 1',
        branchreference:'B01',
        user:'Omar',
        openedat:'October 22, 12:00am',
        openingamount:'SAR 0',
        netcashpayment:'SAR 572.25',
        totalpayins:'SAR 0',
        totalpayout:'SAR 0',
        totalcashdrop:'SAR 0',
        closingamount:'SAR 0',
        expectedamount:'SAR 572.25',
        variance:572.25,
        manualdraweropen:'0',
        shiftduration:'October 23, 12:00am',
    },
]