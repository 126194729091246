export const businessDayData = [
    {
        businessdata:'2024-10-27',
        openedat:'October 27, 12:00am',
        closeat:'October 28, 12:00am',
        branch:'Branch 1',
        branchlocalized:'-',
        branchreference:'B01',
        openedby:'Omar',
        closedby:'Omar',
    },
    {
        businessdata:'2024-10-26',
        openedat:'October 26, 12:01am',
        closeat:'October 27, 12:00am',
        branch:'Branch 1',
        branchlocalized:'-',
        branchreference:'B01',
        openedby:'Omar',
        closedby:'Omar',
    },
]