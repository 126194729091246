import React, { useState } from 'react'
import { Button, Card, Flex, Form, Typography } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';
const { Text, Title } = Typography
const DeleteBranchCard = ({setDeleteItem}) => {
    const [form] = Form.useForm();

    const data = [
        {
            title:'Delete Branch Orders',
            desc:'If you delete this branch orders, all the data will be permanently deleted.',
        },
        {
            title:'Delete Branch',
            desc:'If you delete this branch, all of its data will be permanently deleted.',
        },
    ]

    return (
        <div>
            {
                data?.map((data,index)=>
                    <Flex vertical gap={20} className='mb-3' key={index}>
                        <Title level={5} className='m-0'>{data?.title}</Title>
                        <Card className='shadow-d radius-12 mb-3'>
                            <Flex justify='space-between' wrap className='w-100' gap={10}>
                                <Text className='text-gray fs-13'>
                                    {data?.desc}
                                </Text>
                                <Button 
                                    type="primary"
                                    className='btncancel px-3 bg-red border0'
                                    icon={<DeleteOutlined className='text-white' />}
                                    onClick={()=>setDeleteItem({name: 'Branch 1'})}
                                >
                                    <Text className='text-white fs-13'>Delete Orders</Text>
                                </Button>
                            </Flex>
                        </Card>
                    </Flex>
                )
            }
        </div>
    )
}

export { DeleteBranchCard }
