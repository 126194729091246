import { DeleteOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Typography } from 'antd'
import React, { useState } from 'react'
import { AlertModal } from '../../../Modals'

const { Text, Title } = Typography
const DeactivateDevices = ({device}) => {
    const  [visible , setVisible] = useState(false) 
    
  return (
    <div>
        <Flex vertical gap={20} className='mb-3' >
            <Title level={5} className='m-0'>Deactivate Device</Title>
            <Card className='shadow-d radius-12 mb-3'>
                <Flex justify='space-between' wrap gap={10}>
                    <Text className='text-gray fs-13'>
                    Make sure all active orders are uploaded. You will lose all data on the device if you deactivate it. A new device code will be generated!
                    </Text>
                    <Button 
                        type="primary"
                        className='btncancel px-3 bg-red border0'
                        onClick={()=>{ setVisible(true)}}
                    >
                        <Text className='text-white fs-13'>Deactivate Device</Text>
                    </Button>
                </Flex>
            </Card>
        </Flex>
        <AlertModal 
        visible={visible}
        onClose={()=>{setVisible(false)}} 
        deleteitem={device}/>
    </div>
  )
}

export {DeactivateDevices}