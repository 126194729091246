import { render } from '@testing-library/react';
import { Card, Table, Typography } from 'antd'
import React from 'react'

const {Title, Text} = Typography

const LicenseOverviewTable = ()=> {

  const columns = [
    {
        title: 'License #',
        dataIndex: 'license',
    },
    {
        title: 'Product Name',
        dataIndex: 'product',
    },
    {
        title: 'Subscription Start',
        dataIndex: 'subStart',
    },
    {
        title: 'Subscription End',
        dataIndex: 'subEnd',
    },
    {
      title: 'Status',
      dataIndex: "status",
      render : (status)=> <Text className='text-active bg-light-green fs-12 fw-500 badge-cs'>
      {status}
  </Text>
  },

  ];
  const data = [
      {
          key: '1',
          license: 'SUB-0358789',
          product:'F5 Cashier - Starter Package',
          subStart:'2024-09-10',
          subEnd:'2025-09-09',
          status:"Active"
      },
  ];
  return (
    <div>
            <div className='mb-3'>
                <Title level={5} className='m-0'>Licenses Overview</Title>
            </div>
            <Card className='shadow-d radius-12 card-cs'>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    showSorterTooltip={false}
                    rowHoverable={true}
                    pagination={false}
                />
            </Card>
        </div>
  )
}

export {LicenseOverviewTable}


  


