import React, { useState } from 'react'
import { Card, Col, Divider, Flex, Row, Tabs, Typography } from 'antd'
import { AddBranchuser, TaxGroupcard, AlertModal, FilterBranch, InactivePaymentBranch, ModuleTopHeading, Taxcard, AddEditTax, AddEditTaxGroup, AddUserBranch, AddtoProduct, AddtoModifierOption, GeneralTaxcard, GeneralTaxGroupcard, DeletedTaxCards, RestoreTax } from '../../components'


const { Text } = Typography
const TaxPage = () => {

    const [ deleteitem, setDeleteItem ] = useState(false)   
    const [ addtax, setAddTax ] = useState(false)
    const [ edititem, setEditItem ] = useState(false)
    const [ addtaxgroup, setAddTaxgroup ] = useState(false)
    const [ addbranch, setAddBranch ] = useState(false)
    const [ removecheck, setRemoveCheck ] = useState(false)
    const [ removertext, setRemoverText ] = useState(false)
    const [ addproduct, setAddProduct ] = useState(false)
    const [ addtomodifier, setAddToModifier ] = useState(false)
    const [ restoremodal, setRestoreModal ] = useState(false)

    const itemstab = [
        {
            key: '1',
            label: 'General',
            children: <Flex vertical>
                <GeneralTaxcard {...{setAddTax,setEditItem}} />
                <Divider className='my-5' />
                <GeneralTaxGroupcard {...{
                    setAddTaxgroup,setEditItem, setAddBranch,setRemoveCheck,setRemoverText,
                    setAddProduct, setAddToModifier
                }} />
            </Flex>,
        },
        {
            key: '2',
            label: 'Deleted',
            children: <DeletedTaxCards setRestoreModal={setRestoreModal} /> ,
        },
    ];
  return (
    <div>
        <Row gutter={[24, 24]} align={'middle'}>
            <Col lg={{span:12}} md={{span: 14}} sm={{span: 24}} xs={{span: 24}}>
                <ModuleTopHeading name='Taxes & Group Tax' />
            </Col>
            <Col span={24}>
                <Card className='shadow-d radius-12 card-cs h-100'>
                    <Tabs defaultActiveKey="1" items={itemstab}  />
                </Card>
            </Col>
        </Row>
        <AddEditTax 
            visible={addtax}
            edititem={edititem}
            setDeleteItem={setDeleteItem}
            onClose={()=>{setAddTax(false);setEditItem(null)}}
        />

        <AddEditTaxGroup 
            visible={addtaxgroup}
            edititem={edititem}
            setDeleteItem={setDeleteItem}
            onClose={()=>{setAddTaxgroup(false);setEditItem(null)}}
        />

        <AddUserBranch
            visible={addbranch}
            removecheck={removecheck}
            removertext={removertext}
            onClose={()=>{setAddBranch(false);setRemoveCheck(false);setRemoverText(false)}}
        />

        <AddtoProduct 
            visible={addproduct}
            removertext={removertext}
            onClose={()=>{setAddProduct(false);setRemoverText(false)}}
        />

        <AddtoModifierOption 
            visible={addtomodifier}
            removertext={removertext}
            onClose={()=>{setAddToModifier(false);setRemoverText(false)}}
        />

        <RestoreTax 
            visible={restoremodal}
            onClose={()=>{setRestoreModal(false)}}
        />
        
        <AlertModal 
            visible={deleteitem}
            deleteitem={deleteitem}
            onClose={()=>{setDeleteItem(false)}}
        />
    </div>
  )
}

export {TaxPage}