export const productTableData = [
    {
        id:1,
        key:'1',
        img: <img src='/assets/images/mangojuice.png' width={33} />,
        name: 'Mango Juice',
        sku:'NMC01-IOR-100007',
        category:'Drinks',
        price:'164',
        taxgroup:'-',
        status: 'Active',
    },
    {
        id:2,
        key:'2',
        img: <img src='/assets/images/fish.png' width={33} />,
        name: 'Fish',
        sku:'EOX01-OOE-100015',
        category:'Sea Food',
        price:'637',
        taxgroup:'20%',
        status: 'Inactive',
    },
    {
        id:3,
        key:'3',
        img: <img src='/assets/images/cheesecake.png' width={33} />,
        name: 'Cheese Cake',
        sku:'SSS01-BNV-100013',
        category:'Bakery Items',
        price:'637',
        taxgroup:'5%',
        status: 'Inactive',
    },
    {
        id:4,
        key:'4',
        img: <img src='/assets/images/chococake.png' width={33} />,
        name: 'Chocolate Cake',
        sku:'JPG01-MND-100001',
        category:'Bakery Items',
        price:'117',
        taxgroup:'10%',
        status: 'Active',
    },
    {
        id:'delete',
        key:'5',
        img: <img src='/assets/images/applejuice.png' width={33} />,
        name: 'Apple Juice',
        sku:'YMZ01-CVN-100011',
        category:'Drinks',
        price:'164',
        taxgroup:'12%',
        status: 'Inactive',
    },
];